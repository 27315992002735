import React from 'react'
import ComponentHeader from './ComponentHeader';
import { TranslatedLabels } from '../types/model';

interface Props {
    translatedLabels: TranslatedLabels | null
}

class UnsubscribeComponent extends React.Component<Props, {}> {


    render() {
        const { translatedLabels } = this.props;
        return (
            <>
                <div className="columns">
                    <div className="column">
                        <ComponentHeader title={translatedLabels?.unsubscribeProfileTitle || ''} />
                        {translatedLabels?.unsubscribeProfileText}
                    </div>
                </div>
            </>
        )
    }
}


export default (UnsubscribeComponent)

