import { User, UserManager } from 'oidc-client';
import ProfileApi from '../api/Identity/Profile';
import { CustomWindow } from '../types';
import { IdentityProfile } from '../types/model';

const customWindow = window as CustomWindow;

class userManager {
  public manager: UserManager;

  constructor() {
    const url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
    const settings = {
      authority: customWindow.settings.identityBaseUrl,
      client_id: 'js',
      response_type: 'code',
      scope: 'openid profile cms',
      redirect_uri: `${url}/callback`,
      silent_redirect_uri: `${url}/silent-renew.html`,
      post_logout_redirect_uri: `${url}/cms`,
      response_mode: 'query',
      automaticSilentRenew: true,
      filterProtocolClaims: true,
      loadUserInfo: true,
      monitorSession: true
    };
    this.manager = new UserManager(settings);
  }

  public getUser(): Promise<User | null> {
    return this.manager.getUser();
  }

  public getProfile(): Promise<IdentityProfile | null> {
    return new Promise<IdentityProfile | null>(async (resolve, reject) => {
      try {
        var res = await ProfileApi.get();

        

        if (!res)
          throw 'failed request';

        resolve(res);
      }
      catch (exception) {
        console.log(exception);
        reject(null);
      }
    });
  }

  public login(): Promise<void> {
    return this.manager.signinRedirect();
  }

  public renewToken(): Promise<User> {
    return this.manager.signinSilent();
  }

  public logout(): Promise<void> {
    return this.manager.signoutRedirect();
  }
};
export default new userManager();