import React from "react";
import { connect } from "react-redux";
import LoginComponent from '../components/LoginFormComponent'
import { ThunkDispatch } from "redux-thunk";
import LeftMenuBarComponent from "../components/LeftMenuBarComponent";


export default class LeftMenuBarContainer extends React.Component {
    render() {
        return (
            <LeftMenuBarComponent />
        );
    }
}

