import React, { CSSProperties } from "react";
import { connect, RootStateOrAny } from "react-redux";
import { Styles } from "react-select";
import { DropdownOption, IdentityProfile, Language } from "../types/model";
import { RootState } from "../types/state";

import LanguageSwitcher from "./InputComponents/LanguageSwitcher";

interface OwnProps {
    title: string;
    button?: JSX.Element;
    showLanguageSwitcher?: boolean
}

interface StateProps {
    languages: Language[],
    profile: IdentityProfile
}

type Props = OwnProps & StateProps

class PageHeader extends React.Component<Props> {
    render() {
        const customStyles = {
            option: (cssProps: CSSProperties) => ({
                ...cssProps,
                display: 'flex',
                alignItems: 'center'
            }),

            container: (cssProps: CSSProperties) => ({
                ...cssProps,
                width: 200,
                marginRight: '40px',
                height: 50
            }),

            indicatorSeparator: (cssProps: CSSProperties) => ({
                ...cssProps,
                width: '0px'
            }),
            control : (cssProps: CSSProperties) => ({
                ...cssProps,
                height: '100%'
            })
        } as Partial<Styles<any, any, any>>;

        var languages = this.props.languages && this.props.languages.filter(x => x.isActive).map((l: Language) => {
            return ({ value: l.id?.toString(), label: l.languageText, icon: l.locale })
        }) as DropdownOption[]

        if(this.props.profile && this.props.profile.roles && this.props.profile.roles.length>0){
            languages = languages.filter(e => this.props.profile.roles[0].languageClaims.includes(e.value) === true);
        }

        const { title, button, showLanguageSwitcher } = this.props;
        return (
            <div className="columns pageHeader">
                <div className="column pageHeaderTitle">
                    {title}
                </div>
                <div className="column is-flex is-justify-content-flex-end">
                    {showLanguageSwitcher ? <LanguageSwitcher customStyles={customStyles} options={languages} /> : <></>}
                    {button}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) : any => ({
    languages: state.languages,
    profile: state.profile
})

export default connect(mapStateToProps,null)(PageHeader) as any;