import axios, { AxiosRequestConfig, Method } from "axios";
import { CustomWindow } from "../../types";
import userManager from "../../utils/userManager";
import * as Sentry from "@sentry/react";
import fileDownload from "js-file-download";

type Header = Record<string, string>

const customWindow = window as CustomWindow;

class CMSApiBase {
    public async doHttpRequest<TResponse>(
        method: string,
        endpoint: string,
        data: {} | PromiseLike<{}> | null = null,
        isSingleItem?: boolean   
    ): Promise<TResponse> {
        const access_token = (await userManager.getUser())?.access_token
        const headers: Header = {};
        headers["Content-Type"] = "application/json"; 
        headers["X-BvdG-Correlation-ID"] = "8d083735-b31f-40ab-a425-e88c1215a4e5";
        headers["Authorization"] = `Bearer ${access_token}`;

        const baseUrl = customWindow.settings.baseUrlCMS;
        const url = `${baseUrl}/${endpoint}`;

        const config: AxiosRequestConfig = { method : (method as Method), url, headers, data };
        const response = await axios.request<TResponse>(config)
            .catch(ex => {Sentry.captureException(ex);throw ex;}) as any;

        return isSingleItem ? response.data[0] : response.data ;
    }

    public async downloadFile(endpoint: string, filename: string, callback: () => void)
    {
        const access_token = (await userManager.getUser())?.access_token
        const headers: Header = {};
        headers["Content-Type"] = "application/json"; 
        headers["X-BvdG-Correlation-ID"] = "8d083735-b31f-40ab-a425-e88c1215a4e5";
        headers["Authorization"] = `Bearer ${access_token}`;

        const baseUrl = customWindow.settings.baseUrlCMS;
        const url = `${baseUrl}/${endpoint}`;

        axios.get(url, {
            responseType: 'blob',
            headers: headers,
          }).then(res => {
            fileDownload(res.data, filename);
            callback();
          });
    }
}

export default CMSApiBase;
