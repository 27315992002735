import { Campaign, EmptyAnswer, IEntity, ProfileAnswer, Survey, SurveyQuestionAnswer } from '../../types/model';

import { Method } from 'axios';
import React from 'react';
import SurveyApiBase from './SurveyApiBase';
import * as Sentry from "@sentry/react";

const endpoint = 'questionanswer' as string;
const emptyEndpoint = 'emptyanswer' as string;
const previousQuestion = 'previousquestion' as string;

class QuestionAnswerApi extends SurveyApiBase {
    postQuestionAnswer(questionAnswer: ProfileAnswer): Promise<string> {
        return this.doHttpRequest<any>('post', `${endpoint}`, questionAnswer, false)
            .catch(ex => {Sentry.captureException(ex);throw ex;}) as any;
    }
    postEmptyAnswer(emptyAnswer: EmptyAnswer): Promise<any> {
        return this.doHttpRequest<any>('post', `${emptyEndpoint}`, emptyAnswer, false)
            .catch(ex => {Sentry.captureException(ex);throw ex;}) as any;
    }
    previousQuestion(currentQuestion: EmptyAnswer) : Promise<any> {
        return this.doHttpRequest<any>('post', `${previousQuestion}`, currentQuestion, false)
            .catch(ex => {Sentry.captureException(ex);throw ex;}) as any;
    }
}

export default new QuestionAnswerApi();