import LanguageApi from '../../api/Survey/Language';
import { AsyncAction } from "../";
import { GetCountyAction } from "../../types/actionTypes/languageType";
import * as languageTypes from '../../types/actionTypes/languageType'

export const getTranslatedWords = async (languageId : number) => {
    return await LanguageApi.getLabels(languageId);
}

export const getCountries = (): AsyncAction<GetCountyAction> => {
    return async (dispatch) => {
        const countries = await LanguageApi.getCountries();

        return dispatch({ type: languageTypes.GET_COUNTRIES, countries});
    };
}

