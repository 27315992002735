interface Props {
    content: any;
    size?: "small" | "large"
}

export const ConfirmModal = (props: Props) => {
    const { content, size } = props;

    return (
        <>
            <div className="modal is-active">
                <div className="modal-background"></div>
                    {content}
            </div>
        </>
    )
}