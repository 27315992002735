import React from "react";
import { connect } from "react-redux";
import GridviewWithFilteringComponent from '../components/GridviewWithFilteringComponent'
import { IdentityProfile, Policies, Survey } from '../types/model';
import { SurveysColumns } from '../constants/tableColumns'
import { withRouter } from 'react-router-dom';
import { getSurveys } from '../actions/CMS/surveyActions'
import NoAccessComponent from "../components/NoAccessComponent";

interface State {
    surveys: Survey[],
}

interface MatchParams {
    id: string
}

interface DispatchProps {
    i18n: any
    profile: IdentityProfile
}

// type Props = DispatchProps & RouteComponentProps<MatchParams>

class SurveysContainer extends React.Component<any, State> {
    state = {
        surveys: []
    }

    onRowClick = (row: any) => {
        var guid = row.original.guid
        this.props.history.push({
            pathname: `/CMS/Vragenlijsten/${guid}`
        });
    }

    async componentDidMount() {
        var surveys = await getSurveys();

        this.setState({
            surveys
        })
    }

    render() {
        const { surveys } = this.state;
        const { i18n } = this.props;

        const claims = this.props.profile ? this.props.profile.roles[0].claims : [];

        var isValid = claims.some((c: any) => [Policies.QuestionnaireEdit, Policies.QuestionnaireView, Policies.QuestionnaireDelete].includes(c))
        const isEditable = claims.includes(Policies.QuestionnaireEdit);

        return (
            isValid ?
                <GridviewWithFilteringComponent
                    columns={SurveysColumns(i18n)}
                    data={surveys}
                    title={i18n.surveys}
                    routeName="Vragenlijsten"
                    disableButton={!isEditable}
                    buttonName={i18n.createSurvey}
                    onRowClick={this.onRowClick}
                /> : <NoAccessComponent />
        );
    }
}

const mapStateToProps = (state: any): any => {
    return ({
        i18n: state.i18n,
        profile: state.profile
    })
}

export default withRouter(connect(mapStateToProps, null)(SurveysContainer))


