import { IdentityProfile, Policies } from "../types/model";

import { ReactComponent as CampaignIcon } from '../images/icons/campaign.svg'
import ContentDivider from "./ContentDivider";
import Logo from '../images/bvdg_logo.png';
import { ReactComponent as LogoutIcon } from '../images/icons/logout.svg'
import { NavLink } from "react-router-dom";
import Person from '../images/icons/wilfred.png';
import { ReactComponent as ProfilesIcon } from '../images/icons/profiles.svg'
import React from "react";
import { ReactComponent as SettingsIcon } from '../images/icons/settings.svg'
import { SignoutCallbackComponent } from "redux-oidc";
import { ReactComponent as SurveysIcon } from '../images/icons/surveys.svg'
import { connect } from "react-redux";
import { setLocalLanguageId } from '../actions/CMS/languageActions';
import userManager from "../utils/userManager";

interface StateProps {
    i18n: any;
    profile: IdentityProfile;
}

interface DispatchActions {
    setLocalLanguageId: (languageId: number) => void
}

type Props = StateProps & DispatchActions;

class LeftMenuBarComponent extends React.Component<Props> {
    signOut() {
        userManager.logout();
    }

    render() {
        var srcEnglish = require('../images/icons/flags/gb.png').default;
        var srcNetherlands = require('../images/icons/flags/nl.png').default;


        const { i18n, setLocalLanguageId, profile } = this.props;

        const claims = profile ? profile.roles[0].claims : [];
        var profilePicture = `data:image/png;base64, ${profile?.picture}`;

        return (
            <div className="column is-narrow" style={{ position: 'fixed' }}>
                <div className="sidebar-menu columns" style={{ flexDirection: 'column' }}>
                    <div className="sidebar-header column is-narrow">
                        <NavLink className="logo is-flex is-justify-content-center" to="/CMS">
                            <img style={{maxHeight: '150px'}} src={Logo} />
                        </NavLink>
                        <div className="is-flex is-justify-content-center" style={{ marginTop: '25px' }}>
                            <ContentDivider width={'90%'} />
                        </div>
                    </div>
                    <aside className="menu column">
                        <div className="menu-list">
                            <div className="menu-list-container">
                                {claims.some((c: any) => [Policies.QuestionnaireEdit , Policies.QuestionnaireView , Policies.QuestionnaireDelete , Policies.CampaignView , Policies.CampaignEdit , Policies.CampaignDelete].includes(c)) &&
                                    <div className="menu-item-header" style={{ textTransform: 'uppercase' }}>{i18n.surveys}</div>
                                }
                                {claims.some((c: any) => [Policies.QuestionnaireEdit, Policies.QuestionnaireView, Policies.QuestionnaireDelete].includes(c)) &&
                                    <NavLink className="navbar-item is-flex" activeClassName="is-active" to="/CMS/Vragenlijsten">
                                        <SurveysIcon /> {i18n.surveys}
                                    </NavLink>
                                }
                                {claims.some((c: any) => [Policies.CampaignView , Policies.CampaignEdit , Policies.CampaignDelete].includes(c)) &&
                                    <NavLink className="navbar-item is-flex " activeClassName="is-active" to="/CMS/Campagnes">
                                        <CampaignIcon />{i18n.campaigns}
                                    </NavLink>
                                }
                            </div>
                            {claims.some((c: any) => [Policies.ProfileView].includes(c)) &&
                                <div className="menu-list-container">
                                    <div className="menu-item-header" style={{ textTransform: 'uppercase' }}>{i18n.profiles}</div>
                                    <NavLink className="navbar-item is-flex" activeClassName="is-active" to="/CMS/Profielen">
                                        <ProfilesIcon /> {i18n.profiles}
                                    </NavLink>
                                </div>
                            }
                            {claims.some((c: any) => [Policies.SettingsEdit , Policies.SettingsView , Policies.SettingsDelete].includes(c)) &&
                                <div className="menu-list-container">
                                    <div className="menu-item-header" style={{ textTransform: 'uppercase' }}>{i18n.settings}</div>
                                    <NavLink className="navbar-item is-flex" activeClassName="is-active" to="/CMS/Instellingen">
                                        <SettingsIcon />{i18n.settings}
                                    </NavLink>

                                </div>
                            }
                        </div>
                    </aside>
                    <div className="column is-flex is-justify-content-flex-end is-flex-direction-column">
                        <div className="columns" style={{ height: '80px', display: 'flex', alignItems: 'center' }}>
                            {/* <div className="column is-4 imageConainer is-paddingless is-flex " style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <img height={40} width={40} src={profilePicture} />
                            </div> */}
                            <div className="column" style={{ textAlign: 'center' }} >
                                <div>
                                    {profile?.firstname} {profile?.surname}
                                </div>
                                <div>
                                    {profile?.roles[0]?.name ?? 'Gebruiker'}
                                </div>
                            </div>
                        </div>
                        <div className="columns" style={{ height: '80px', display: 'flex', alignItems: 'center' }}>
                            <div className="column" style={{ textAlign: 'center' }} >
                                <img
                                    src={srcNetherlands}
                                    onClick={() => setLocalLanguageId(0)}
                                    style={{ width: 30 }}
                                />
                            </div>
                            <div className="column" style={{ textAlign: 'center' }} >
                                <img
                                    src={srcEnglish}
                                    onClick={() => setLocalLanguageId(1)}
                                    style={{ width: 30 }}
                                />
                            </div>
                        </div>
                        <div className="is-flex is-justify-content-center">
                            <ContentDivider width={'90%'} />
                        </div>
                        <div className="columns" style={{ height: '80px', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.signOut()}>
                            <div className="column is-4 imageConainer is-paddingless" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <LogoutIcon />
                            </div>
                            <div className="column">
                                {this.props.i18n.logOut}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: any): DispatchActions => ({
    setLocalLanguageId: (id: number) => dispatch(setLocalLanguageId(id))
});

const mapStateToProps = (state: StateProps): StateProps => {
    return ({
        i18n: state.i18n,
        profile: state.profile,
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenuBarComponent)
