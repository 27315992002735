import React, { } from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm, change, registerField, unregisterField} from 'redux-form';
import { Campaign, ColumnNames, Country, IdentityProfile, Language, Policies, ProfileOperator, StatusType, Survey } from '../../types/model';
import ComponentHeader from '../ComponentHeader';
import GeneralComponent from '../GeneralComponent';
import PageHeader from '../PageHeader';
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import TextEditorComponent from '../InputComponents/TextEditorComponent';
import { testProfileFilters } from '../../actions/CMS/profileActions';
import { ConvertHtmlToEditorState } from '../../helpers/textAreaHelper';
import { ReactComponent as RecycleBinIcon } from '../../images/icons/recycle-bin.svg';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Gridview from '../gridview/GridviewComponent';
import { SurveysColumns } from '../../constants/tableColumns';
import { DropdownComponent } from '../InputComponents/DropdownComponent';
import { TextInputComponent } from '../InputComponents/TextInputComponent';
import AddSurveyForm from './AddSurveyForm';
import { ReactComponent as PolygonIcon } from '../../images/icons/polygon.svg'
import { enumToDropdown, enumToDropdownWithoutUnkown } from '../../helpers/commonHelper';
import DatePickerComponent from '../InputComponents/DatePickerComponent';
import required from '../../validators/required';
import moment from 'moment';
import ImageUploading from "react-images-uploading";
import { SendStatus } from '../../types/model';
import Logo from '../../images/bvdg_logo.png';


//important for getting nice style.
import './../../styling/image-upload-react.css'
import { Modal } from '../Modal';
import QuestionOrderForm from './QuestionOrderForm';


interface OwnPropsVariables {
    surveys: Survey[] | null
    campaigns: Campaign[]
    campaign: Campaign | null
    exportInProgress: boolean;
}

interface OwnPropsMethods {
    onSubmitCampaign: (values: any, images: any) => void;
    onSubmitSurveys: (values: any) => void;
    removeSurvey: (values: any, row: any) => void;
    exportCampaign: () => void;
    sortSurveys: (value: any, row: any) => void;
}

interface MatchParams {
    id: string
}

interface DispatchProps {
    updateFormValue: (field: string, value: any) => any;
    dispatch: any
}

interface State {
    editorStateEmailDescription: EditorState
    showModal: boolean
    selectedSurveyGuid: string | null,
    forceUpdate: boolean
    numberOfMatchingProfiles: number;
    images: any;
    imagesInitialized: boolean;
    showSendAt: boolean;
    selectedSurveyRow: number;
    showOrderModal: boolean;
    possibleCompareValues: Map<number, DropdownOption[]>
}

interface DropdownOption {
    value: number;
    label: string;
}

interface StateProps {
    formValues: any;
    selectedLanguageId: number;
    profile: IdentityProfile
    initialValues: any;
    i18n: any,
    languages: Language[],
    countries: Country[]
}

const initialValues = (): InitialFilterValue => {
    return ({
        columnName: 0,
        operator: 0,
        compareValue: "",
    });
};

interface InitialFilterValue {
    columnName: number,
    operator: number,
    compareValue: string,
}


type Props = OwnPropsMethods & DispatchProps & StateProps & RouteComponentProps<MatchParams> & OwnPropsVariables;

const formName = "CampaignForm"
class CampaignForm extends React.Component<Props & InjectedFormProps<{}, any>, State> {
    constructor(props: Props & InjectedFormProps<{}, any>) {
        super(props);
        this.state = {
            editorStateEmailDescription: EditorState.createEmpty(),
            showModal: false,
            forceUpdate: false,
            selectedSurveyGuid: null,
            numberOfMatchingProfiles: 0,
            images: [] as any,
            imagesInitialized: false,
            showSendAt: true,
            selectedSurveyRow: 0,
            showOrderModal: false,
            possibleCompareValues: new Map<number, DropdownOption[]>()
        }
    }

     maxFileSize = 200000;
     maxImages = 5;


    componentDidMount = () => {
        if (!this.props.campaign) {
            this.toggleSendAtDatePicker();
        }
    }

    componentDidUpdate = (prevProps: Props) => {
        if (this.props.formValues?.profileFilter?.length === 0 && this.props.campaign?.profileFilter) {
            this.props.change("profileFilter", this.props.campaign?.profileFilter.filters);
        }

        if (this.props.formValues?.sendAt?.length === 0 && this.props.campaign?.sendAt) {
            this.props.change("sendAt", this.props.campaign?.sendAt);
        }

        if (this.props.campaign !== prevProps.campaign || this.props.selectedLanguageId !== prevProps.selectedLanguageId) {
            var languageText = this.props.languages?.find(l => l.id === this.props.selectedLanguageId)?.languageTexts;

            var campaignText = this.props.campaign != null ? this.props.campaign?.campaignTexts?.find((st) => st.languageId === this.props.selectedLanguageId) : null;
            var editorStateMailDescription = ConvertHtmlToEditorState(campaignText ? campaignText.emailDescriptionText : `<div><div class="campaignLink"><a href="{{campagneurl}}">` + (languageText ? languageText.goToCampaignLinkTitle : "Go to") + `</a></div><div class="unsubscribeFooter"><a href="{{unsubscribeurl}}">` + (languageText ? languageText.unsubscribeEmailLinkTitle : "Unsubscribe") + `</a></div></div>`);

            this.setState({ editorStateEmailDescription: editorStateMailDescription })
        }
    }

    manageFields = (isAddingRows: boolean, index?: any) => {
        const { formValues } = this.props;

        var initialVal = initialValues();
        if (formValues) {
            Object.keys(initialVal).forEach(key => {
                if (isAddingRows) {
                    this.props.dispatch(registerField(formName, `profileFilter[${formValues.profileFilter.length}].${key}`, "Field"));
                    this.props.change(`profileFilter[${formValues.profileFilter.length}][${key}]`, (initialVal[key as keyof InitialFilterValue] as any));
                } else {
                    this.props.dispatch(unregisterField(formName, `profileFilter[${index}].${key}`));
                }
            })

            if (!isAddingRows) {
                formValues.profileFilter.splice(index, 1);
                this.props.change(formName, formValues);
            }
        }
    }


    onEditorStateChange = (editor: EditorState, accessor?: string) => {
        const rawContentState = convertToRaw(editor.getCurrentContent());
        const html = draftToHtml(rawContentState).replace(' target="_self"', '');

        if (accessor != null) {
            this.props.updateFormValue(accessor, html);
            this.setState({ editorStateEmailDescription: editor });
        }
    }

    addItem = () => {
        this.setState({ showModal: !this.state.showModal })
    }

    onRowClick = (row: any) => {
        this.setState({ selectedSurveyGuid: row.original.guid, showModal: !this.state.showModal })
    }

    closeSurveyModal = () => {
        this.setState({ selectedSurveyGuid: null, showModal: !this.state.showModal })
    }

    getMatchingProfileNumbers = async () => {
        var profileFilters = this.props.formValues?.profileFilter.map((e: any) => {
            if (e.compareValue instanceof moment) {
                e.compareValue = e.compareValue.format('YYYY-MM-DD hh:mm:ss');
            }
            if (typeof e.compareValue === 'number') {
                e.compareValue = e.compareValue.toString();
            }
            return e;
        })
        var filters = { filters: profileFilters }

        var numberOfMatchingProfiles = await testProfileFilters(filters);

        this.setState({ numberOfMatchingProfiles: numberOfMatchingProfiles })
    }

    updateFormValues = async (index: number, newValue: number) => {
        if (index !== undefined) {
            this.props.updateFormValue(`profileFilter[${index}].compareValue`, null);
            this.props.updateFormValue(`profileFilter[${index}].operator`, ProfileOperator.Equals);

            var dropdownOptions: DropdownOption[] = []

            if (newValue == ColumnNames.CampaignParticipation) {
                dropdownOptions = this.props.campaigns && this.props.campaigns
                    .filter(c => !c.isArchived && c.id != this.props.campaign?.id)
                    .map((c: Campaign) => {
                        return ({ value: c.id ?? 0, label: c.title })
                    });
            }

            if ([ColumnNames.MaritalStatus, ColumnNames.Education, ColumnNames.Gender, ColumnNames.Income, ColumnNames.Smoking].includes(newValue)) {
                dropdownOptions = this.getOptionsByType(newValue);
            }

            if ([ColumnNames.Country, ColumnNames.CountryOfBirth].includes(newValue)) {
                dropdownOptions = this.props.countries && this.props.countries.map((l: Country) => {
                    return ({ value: l.id, label: l.name })
                });
            }

            var { possibleCompareValues } = this.state
            possibleCompareValues.set(index, dropdownOptions);
            this.setState({ possibleCompareValues: possibleCompareValues });
            this.props.updateFormValue(`profileFilter[${index}].compareValue`, dropdownOptions[0]?.value);
        }
    }

    removeItem = (row: any, index: any) => {
        this.props.removeSurvey(row, index)
        this.setState({ forceUpdate: !this.state.forceUpdate })
    }

    sortItems = (value: any, row: any) => {
        this.props.sortSurveys(value, row);
        this.setState({ forceUpdate: !this.state.forceUpdate })
    }

    getOperatorDropdownValues = (index: number) => {
        const { i18n, formValues } = this.props;

        var profileEnums = i18n.profileOperatorEnum;
        var type = formValues.profileFilter[index].columnName;

        return Object.keys(profileEnums).map(e => {
            let key = parseInt(e);

            if ([ProfileOperator.LessThan, ProfileOperator.GreaterThan, ProfileOperator.Equals].includes(key) && ([ColumnNames.BirthDate, ColumnNames.DiabetesDuration, ColumnNames.PostalCode].includes(type)))
                return { label: profileEnums[key], value: key }

            if ([ProfileOperator.Equals].includes(key) && ([ColumnNames.MaritalStatus, ColumnNames.Education, ColumnNames.Gender, ColumnNames.Income, ColumnNames.Smoking, ColumnNames.Country, ColumnNames.CountryOfBirth, ColumnNames.CampaignParticipation].includes(type)))
                return { label: profileEnums[key], value: key }


            return
        }).filter(e => e)

    }

    onChange = (imageList: any, addUpdateIndex: any) => {
        this.setState({ images: imageList })
    };

    componentWillReceiveProps(nextProps: any) {
        if (!this.state.imagesInitialized && nextProps.campaign !== undefined && nextProps.campaign !== null && nextProps.campaign.campaignImages !== null) {
            var images = [];
            if (nextProps.campaign.campaignImages) {
                for (var i = 0; i < nextProps.campaign.campaignImages.length; i++) {
                    images.push({ data_url: nextProps.campaign.campaignImages[i].base64String, file: { lastModified: 333333, name: 'test.jpg', size: 3333, type: 'image/jpeg' }, id: nextProps.campaign.campaignImages[i].id });
                }
            }
            this.setState({ imagesInitialized: true, images: images })
        }
    }

    getOptionsByType = (index: number) => {
        const { i18n } = this.props;
        var value = null;
        switch (index) {
            case ColumnNames.MaritalStatus:
                value = i18n.maritalStatusEnum;
                break;
            case ColumnNames.Education:
                value = i18n.educationEnum;
                break;
            case ColumnNames.Gender:
                value = i18n.genderEnum;
                break;
            case ColumnNames.Income:
                value = i18n.incomeEnum;
                break;
            case ColumnNames.Smoking:
                value = i18n.smokingEnum;
                break;
        }
        return enumToDropdownWithoutUnkown(value);
    }

    showCompareValueComponent = (index: number) => {
        var content = null;
        if ([ColumnNames.DiabetesDuration].includes(this.props.formValues?.profileFilter[index].columnName)) {
            content = <Field
                component={TextInputComponent}
                type="number"
                key={index}
                required={true}
                validate={required}
                name={`profileFilter[${index}].compareValue`}
            />
        }

        if ([ColumnNames.BirthDate].includes(this.props.formValues?.profileFilter[index].columnName)) {
            content = <Field
                component={DatePickerComponent}
                type="date"
                required={true}
                key={index}
                validate={required}
                name={`profileFilter[${index}].compareValue`}
            />
        }
        if ([ColumnNames.PostalCode].includes(this.props.formValues?.profileFilter[index].columnName)) {
            content = <Field
                component={TextInputComponent}
                type="number"
                required={true}
                maxLength={7}
                key={index}
                validate={required}
                name={`profileFilter[${index}].compareValue`}
            />
        }

        if ([ColumnNames.CampaignParticipation, ColumnNames.MaritalStatus, ColumnNames.Education, ColumnNames.Gender, ColumnNames.Income, ColumnNames.Smoking, ColumnNames.Country, ColumnNames.CountryOfBirth].includes(this.props.formValues?.profileFilter[index].columnName)) {
            content = <Field
                component={DropdownComponent}
                name={`profileFilter[${index}].compareValue`}
                style={{ width: '30px' }}
                required={true}
                key={index}
                options={this.state.possibleCompareValues.get(index)}
            />

        }
        return content;
    }

    setSendAtDateVisible(state: boolean) {
        this.setState({ showSendAt: state });
    }

    toggleSendAtDatePicker() {
        this.setState({ showSendAt: !this.state.showSendAt })
        //this.props.updateFormValue("sendAt", "");
    }

    onSendNowClick() {
        this.toggleSendAtDatePicker();
        this.props.updateFormValue("sendAt", moment());
    }

    onExportClick() {
        this.props.exportCampaign();
    }

    sendStatus() {
        if (this.props.campaign) {
            switch (this.props.campaign.sendStatus) {
                case SendStatus.NotSend:
                    return this.props.i18n.sendStatusNotSend;
                case SendStatus.PartiallySent:
                    return this.props.i18n.sendStatusPartialSent;
                case SendStatus.Sent:
                    return this.props.i18n.sendStatusSent;
                default:
                    return "";
            }
        }

        return "";
    }

    isCampaignSent() {
        return this.props.campaign && this.props.campaign.sendStatus == SendStatus.Sent;
    }

    changeOrder = (row: any, index: any) => {
        this.setState({ selectedSurveyRow: row, showOrderModal: true })
    }

    closeOrderModal = () => {
        this.setState({ selectedSurveyGuid: null, showOrderModal: false })
    }

    onSaveSurveyOrder = (values: any) => {
        this.props.sortSurveys(this.state.selectedSurveyRow, values.newIndex - 1);
        this.closeOrderModal();
    }

    render() {
        const { campaign, onSubmitCampaign, handleSubmit, surveys, i18n, formValues, exportInProgress } = this.props;
        const { showModal, showOrderModal, selectedSurveyRow } = this.state;

        var showSendAt = this.state.showSendAt;

        const claims = this.props.profile ? this.props.profile.roles[0].claims : [];

        const isEditable = claims.includes(Policies.CampaignEdit);
        const isDeletable = claims.includes(Policies.CampaignDelete);

        var survey = campaign?.surveys?.find(e => e.guid == this.state.selectedSurveyGuid);

        let possibleSurveysToAdd = surveys?.filter(s => {
            if ((!campaign?.surveys?.find(cs => cs.guid === s.guid && cs.id as number >= 0)) && s.status === StatusType.ready) {
                return s
            }
        }).filter(e => e);

        let selectedSurveys = surveys?.filter(s => {
            if ((campaign?.surveys?.find(cs => cs.guid === s.guid && cs.id as number >= 0)) && s.status === StatusType.ready) {
                let propsSurvey = campaign?.surveys?.find(cs => cs.guid === s.guid)
                if (propsSurvey) {
                    s.rank = propsSurvey.rank;
                    return s;
                }
                return s;
            }
        }).filter(e => e);

        selectedSurveys = selectedSurveys?.sort((a, b) => a.rank > b.rank ? 1 : -1);

        var hasMaxImages = this.state.images ? this.state.images.length >= 5 : false;

        return (
            <>
                {showModal && <AddSurveyForm
                    survey={survey}
                    surveys={possibleSurveysToAdd}
                    onSubmitSurveys={this.props.onSubmitSurveys}
                    closeSurveyModal={this.closeSurveyModal}

                />}

                {showOrderModal && <QuestionOrderForm
                    row={selectedSurveyRow}
                    i18n={i18n}
                    closeOrderModal={this.closeOrderModal}
                    moveQuestion={this.props.sortSurveys}
                    onSave={this.onSaveSurveyOrder}
                />}


                {

                    exportInProgress && <Modal
                        content={
                            <div className="login">
                                <div className="columns is-mobile">
                                    <div className="column is-half logoContainer">
                                        <img src={Logo} />
                                    </div>
                                </div>
                                <div className="columns is-centered is-mobile">
                                    <div className="column is-centered is-4">
                                        <img src={require('../../images/loader.gif').default} />
                                    </div>
                                </div>
                                <div className="columns is-centered is-mobile">
                                    <div className="column is-centered is-4">
                                        {i18n.ExportLoading}
                                    </div>
                                </div>
                            </div>
                        }
                    />}

                <form onSubmit={handleSubmit((values: any) => onSubmitCampaign(values, this.state.images))}>
                    <div className="columns" >
                        <div className="column">
                            <PageHeader
                                title={`${i18n.campaign}`}
                                showLanguageSwitcher={true}
                                button={<button className="blueButton" style={{ display: isEditable ? 'flex' : 'none' }} type="submit">{i18n.save}</button>}
                            />
                            <div className="columns">
                                <div className="column componentWrapper">
                                    <GeneralComponent isCampaign={true} statusDropdownOptions={enumToDropdown(i18n.statusTypeEnum)} />
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column componentWrapper" style={{ marginTop: '20px', marginBottom: '20px' }}>

                                    <ImageUploading
                                        multiple
                                        maxFileSize={this.maxFileSize}
                                        value={this.state.images}
                                        onChange={this.onChange}
                                        maxNumber={this.maxImages}
                                        dataURLKey="data_url"
                                    >
                                        {({
                                            imageList,
                                            onImageUpload,
                                            onImageRemove,
                                            dragProps,
                                            isDragging,
                                            errors
                                        }) => {
                                            return (
                                                <>
                                                    <ComponentHeader title={i18n.images} textButton={isEditable ? i18n.uploadImage : null} disabled={hasMaxImages} onClickButton={onImageUpload} />
                                                    <div key={3} className="columns">
                                                        {imageList.map((image, index) => {
                                                            return (
                                                                <div className="column imageWrapper">
                                                                    <img src={image['data_url']} alt="" width="100" height="100" />
                                                                    {isDeletable &&
                                                                        <RecycleBinIcon onClick={(e: any) => { onImageRemove(index) }} height="25px" />
                                                                    }
                                                                </div>
                                                            )
                                                        })}
                                                    </div>

                                                    {errors && <div>
                                                        {errors.maxNumber && <span>Number of selected images exceed {this.maxImages}</span>}
                                                        {errors.maxFileSize && <span>Selected file size exceeds {this.maxFileSize/1000} KB</span>}
                                                    </div>}
                                                </>
                                            )
                                        }
                                        }
                                    </ImageUploading>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column componentWrapper" >
                                    <ComponentHeader title={i18n.surveys} textButton={isEditable ? i18n.addSurvey : null} onClickButton={this.addItem} />
                                    {campaign?.surveys &&
                                        <Gridview
                                            columns={SurveysColumns(i18n)}
                                            data={campaign ? selectedSurveys : []}
                                            deleteItem={isDeletable}
                                            changeOrder={this.changeOrder}
                                            onDeleteClick={(values: any, row: any) => { this.removeItem(values, row) }}
                                        />
                                    }
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column componentWrapper" style={{ marginTop: '20px', marginBottom: '20px' }}>
                                    <ComponentHeader title={i18n.content} />
                                    <div className="columns">
                                        <div className="column">
                                            <Field
                                                component={TextEditorComponent}
                                                label={i18n.campaignIntroText}
                                                readOnly={!isEditable}
                                                name="campaignIntroText"
                                                editorState={this.state.editorStateEmailDescription}
                                                onEditorStateChange={this.onEditorStateChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column componentWrapper" >
                                    <ComponentHeader title={i18n.profiles} htmlText={<span>{i18n.numberOfMatchingProfiles} : <span className="numberOfMatchingProfiles">{this.state.numberOfMatchingProfiles}</span></span>} />
                                    <div className="columns profileWrapper">
                                        <div className="column">
                                            {formValues && formValues.profileFilter.map((fv: any, index: number) => {
                                                return (
                                                    <>
                                                        <div className="columns is-gapless conditionWrapper" >
                                                            <div className="column is-flex borderBottomAndTopBlack columnNames">
                                                                <Field
                                                                    component={DropdownComponent}
                                                                    name={`profileFilter[${index}].columnName`}
                                                                    style={{ width: '30px' }}
                                                                    key={index}
                                                                    required={true}
                                                                    onChange={(newvalue: any) => this.updateFormValues(index, newvalue)}
                                                                    options={enumToDropdownWithoutUnkown(i18n.columnNamesEnum)}
                                                                />
                                                            </div>
                                                            <div className="column is-flex is-narrow borderBottomAndTopBlack" style={{ width: '13px' }}>
                                                                <PolygonIcon height={'40px'} />
                                                            </div>
                                                            <div className="column is-flex operators borderBottomAndTopBlack" style={{ borderRight: '1px solid black' }} >
                                                                <Field
                                                                    component={DropdownComponent}
                                                                    name={`profileFilter[${index}].operator`}
                                                                    style={{ width: '30px' }}
                                                                    required={true}
                                                                    key={index}
                                                                    // disabled={formValues.profileFilter[index].operator}
                                                                    options={this.getOperatorDropdownValues(index)}
                                                                // formValues.profileFilter[index].columnName
                                                                />
                                                            </div>
                                                            <div className="column is-flex is-narrow polygonWrapper borderBottomAndTopGrey" style={{ width: '13px' }}>
                                                                <PolygonIcon height={'40px'} />
                                                            </div>
                                                            <div className="column is-flex borderBottomAndTopGrey">

                                                                {this.showCompareValueComponent(index)}
                                                            </div>
                                                            {isDeletable &&
                                                                <div className="column is-flex is-narrow borderBottomAndTopGrey" style={{ width: '20px' }}>
                                                                    <RecycleBinIcon onClick={(e) => this.manageFields(false, index)} height={'40px'} />
                                                                </div>
                                                            }
                                                        </div>
                                                        {index !== formValues.profileFilter.length - 1 &&
                                                            <div className="columns" >
                                                                <div className="column" style={{ fontWeight: 'bolder' }}>
                                                                    {i18n.and}
                                                                </div>
                                                            </div>
                                                        }

                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    
                                    <div className="columns">
                                        <div className="column">
                                            <div className="columns is-gapless ">
                                                <div className="column">
                                                    {isEditable &&
                                                        <button type={"button"} onClick={() => this.manageFields(true)} className="whiteButton">{i18n.and}</button>
                                                    }
                                                </div>
                                                <div className="column is-pulled-right">
                                                    <button type={"button"} onClick={() => this.getMatchingProfileNumbers()} className="whiteButton is-pulled-right">{i18n.updateProfiles}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column componentWrapper">
                                    <ComponentHeader title={i18n.sendAt} />
                                    <div className="columns">
                                        {!(showSendAt || this.props.campaign?.sendAt) &&
                                            <div style={{ width: '400px' }}>
                                                <div className="column">
                                                    <button type={"button"} onClick={() => this.onSendNowClick()} className="whiteButton is-pulled-left">{i18n.sendNow}</button>
                                                    <button type={"button"} onClick={() => this.toggleSendAtDatePicker()} className="whiteButton is-pulled-right">{i18n.sendAt}</button>
                                                </div>
                                            </div>
                                        }
                                        {(showSendAt || this.props.campaign?.sendAt) &&
                                            <div className="column">
                                                <div id='sendAtDatePicker'>
                                                    {this.sendStatus() &&
                                                        <div>
                                                            <b>{i18n.campaignSentStatusLabel}: </b>{this.sendStatus()}
                                                        </div>
                                                    }
                                                    <Field
                                                        label={i18n.campaignSentDateLabel}
                                                        component={DatePickerComponent}
                                                        dateFormat="dd-MM-yyyy h:mm aa"
                                                        showTimeSelect={true}
                                                        readOnly={!isEditable || this.isCampaignSent()}
                                                        type="date"
                                                        validate={required}
                                                        name="sendAt"
                                                    />
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>

                            </div>
                            {this.props.campaign &&
                                <div className="columns">
                                    <div className="column componentWrapper">
                                        <ComponentHeader title={i18n.Export} />
                                        <div className="columns">
                                            <div style={{ width: '400px' }}>
                                                <div className="column">
                                                    <button type={"button"} onClick={() => this.onExportClick()} className="whiteButton">{i18n.Export}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </form>
            </>
        )
    }
}

const mapStateToProps = (state: any, props: RouteComponentProps<MatchParams> & OwnPropsVariables): StateProps => {
    var campaignText = props.campaign ? props.campaign.campaignTexts?.find((st: any) => st.languageId === state.selectedLanguageId) : null;
    var statusValue = props.campaign ? props.campaign.status : StatusType.concept;
    return ({
        initialValues: {
            id: props.campaign ? props.campaign.id : 0,
            guid: props.match.params.id,
            code: props.campaign ? props.campaign.code : "",
            title: campaignText ? campaignText?.title : "",
            characteristic: props.campaign ? props.campaign.characteristic : "",
            status: statusValue ? statusValue : StatusType.concept,
            isArchived: props.campaign ? props.campaign.isArchived : false,
            campaignIntroText: campaignText ? campaignText.emailDescriptionText : "",
            languageId: state.selectedLanguageId,
            profileFilter: props.campaign?.profileFilter?.filters ? props.campaign?.profileFilter?.filters : [],
            sendAt: props.campaign ? props.campaign.sendAt : "",
            mailchimpTemplate: campaignText ? campaignText.mailchimpTemplate : "",
            isProfileCampaign: props.campaign ? props.campaign.isProfileCampaign ? "yes" : "no" : "no"

        } as any,
        selectedLanguageId: state.selectedLanguageId,
        formValues: getFormValues(formName)(state) as any,
        languages: state.languages,
        profile: state.profile,
        i18n: state.i18n,
        countries: state.countries
    })
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    updateFormValue: (field: string, value: any) => dispatch(change(formName, field, value)),
    dispatch
});

const CampaignReduxForm = reduxForm<{}, Props>({
    form: formName,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
})(CampaignForm);


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignReduxForm));
