import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import classnames from 'classnames'

interface Props {
    placeholder?: string;
    label?: string;
    hidden?: boolean;
    readOnly?: boolean
    type?: string;
    useOwnValue?: string
    showLabelInCheckbox?: boolean;
    labelInComponent?: boolean
    iconName?: string;
    onClickIcon?: () => void;
    required?: boolean;
    isBorderError?: boolean;
    checked: boolean;
}



export class RadioButtonComponent extends React.Component<WrappedFieldProps & Props> {

    render() {
        const { input, label, type, showLabelInCheckbox, required, meta: { touched, error }, hidden, isBorderError, checked } = this.props;
        let style = {};        

        return (
            <>
                { !hidden &&
                    <>
                        <div className="field-body">
                            <div className="field" style={{ border: isBorderError && touched && error ? '1px solid red' : '', display: showLabelInCheckbox ? 'flex' : '', alignItems: showLabelInCheckbox ? 'center' : '' }}>
                                <div style={{display : showLabelInCheckbox ? 'flex' : '' }}>
                                    <input {...input} type={type} />
                                    <label style={{ marginLeft: '10px' }}>{label}</label>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </>
        );
    }
}