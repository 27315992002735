import QuestionAnswer from "../../api/Survey/QuestionAnswer";
import { EmptyAnswer, ProfileAnswer } from "../../types/model";

export const postQuestionAnswer = async (profileAnswer: ProfileAnswer) => {
    return await QuestionAnswer.postQuestionAnswer(profileAnswer);
}

export const postEmptyAnswer = async (emptyAnswer: EmptyAnswer) => {
    return await QuestionAnswer.postEmptyAnswer(emptyAnswer);
}

export const previousQuestion = async(currentQuestion: EmptyAnswer) => {
    return await QuestionAnswer.previousQuestion(currentQuestion);
}