import { DisplayType, Language, QuestionType, SurveyQuestionAnswer, TranslatedLabels } from "../../types/model";
import { Field, InjectedFormProps, SubmissionError, change, getFormValues, reduxForm } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { destroy, reset } from 'redux-form';

import ComponentHeader from "../ComponentHeader";
import { DropdownComponent } from '../InputComponents/DropdownComponent';
import { Modal } from '../Modal';
import React from "react";
import { TextInputComponent } from "../InputComponents/TextInputComponent";
import { connect } from 'react-redux';
import { isValidPostalCode } from "../../helpers/commonHelper";
import moment from "moment";

interface OwnProps {
    onSubmitAnswer: (formValues: any, surveyQuestionAnswers: SurveyQuestionAnswer[], isEmpty: boolean, countryValue?: number) => Promise<boolean>;
    content: any
    questionAnswers: SurveyQuestionAnswer[];
    translatedLabels: TranslatedLabels | null;
    countyValue?: number | undefined;
    padAnswer: boolean;
    emptyAllowed: boolean;
    questionId: number;
    answerSending: boolean;
    onPreviousQuestion: (surveyQuestionAnswers: SurveyQuestionAnswer[], isEmpty: boolean, countryValue?: number) => Promise<boolean>;
    showPrevious: boolean;
}

interface StateProps {
    formValues: any
    i18n: any,
    // initialValues: any
}

interface DispatchProps {
    resetForm: any
}

type Props = OwnProps & StateProps & DispatchProps;

const formName = "ProfileSurveyForm"
class ProfileSurveyForm extends React.Component<Props & InjectedFormProps<{}, any>, {}> {
    constructor(props: Props & InjectedFormProps<{}, any>) {
        super(props);
    }

    onSubmitProfileAnswer = async (values: any) => {
        var translatedLabels = this.props.translatedLabels;

        var baseQuestion = this.props.questionAnswers ? this.props.questionAnswers[0] : null;
        let stringError = null;
        let isEmpty = false;

        if (baseQuestion?.questionType === QuestionType.open) {
            if (baseQuestion.displayType === DisplayType.country) {
                if (!this.props.countyValue) {
                    stringError = translatedLabels?.multipleChoiceValidationText;
                    isEmpty = true;
                }
            }
            if (baseQuestion.displayType === DisplayType.date) {
                if (values.day !== undefined && values.month !== undefined && values.year !== undefined) {
                    if (values.year.toString().length < 4 || values.year > 9999 || values.year < 1900) {
                        stringError = translatedLabels?.invalidDateValidationText
                    }
                    else if (!moment(`${values.year}-${values.month}-${values.day}`,'YYYY-M-D').isValid())
                        stringError = translatedLabels?.invalidDateValidationText

                } else {
                    stringError = translatedLabels?.invalidDateValidationText
                    isEmpty = true;
                }
            } if (baseQuestion.displayType === DisplayType.email) {
                const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!values.value || !values.valueConfirmEmail) {
                    stringError = translatedLabels?.noEmailValidationText;
                    isEmpty = true;
                }
                else if (!emailRegex.test(values.value)) {
                    stringError = translatedLabels?.invalidEmailValidationText
                }
                else if (values.value !== undefined && values.valueConfirmEmail !== undefined) {
                    if (values.value !== values.valueConfirmEmail) {
                        stringError = translatedLabels?.emailAddressNotEqualValidation;
                    }
                }
            }
            if (baseQuestion.displayType === DisplayType.number) {
                if (values.value === undefined || values.value === "" || values.value === null) {
                    stringError = translatedLabels?.noNumberValidationText;
                    isEmpty = true;
                }
                else if (baseQuestion.minValue !== null && baseQuestion.minValue !== undefined) {
                    var intValue = parseInt(values.value);
                    stringError = (intValue < baseQuestion.minValue) ? translatedLabels?.numberTooLowValidationText : null
                } if (baseQuestion.maxValue !== null && baseQuestion.maxValue !== undefined && stringError === null) {
                    var intValue = parseInt(values.value)

                    stringError = (intValue > baseQuestion.maxValue) ? translatedLabels?.numberTooHighValidationText : null
                }
            }
            if (baseQuestion.displayType === DisplayType.postalCode) {
                if (!values.value) {
                    stringError = translatedLabels?.noPostalCodeValidationText;
                    isEmpty = true;
                }
                else if (baseQuestion.regexValidationFormat) {
                    if (!isValidPostalCode(values.value)) {
                        stringError = translatedLabels?.invalidPostalCodeValidationText;
                    }
                }
            }
            if (baseQuestion.displayType === DisplayType.text) {
                if (!values.value) {
                    stringError = translatedLabels?.textValidationText
                    isEmpty = true;
                } else {
                    if (baseQuestion.maxValue) {
                        stringError = (values.value.toString().length > baseQuestion.maxValue ? translatedLabels?.stringTooLongValidation : stringError)
                    }
                }
            }

        } else if (baseQuestion?.questionType === QuestionType.multipleChoice) {
            if (baseQuestion.showInDropdown) {
                if (!values.value) {
                    stringError = translatedLabels?.multipleChoiceValidationText;
                    isEmpty = true;
                }
            }
            if (!baseQuestion.hasMultipleAnswers && !baseQuestion.showInDropdown) {
                if (!values.value) {
                    stringError = translatedLabels?.multipleChoiceValidationText;
                    isEmpty = true;
                }
            } else if (!baseQuestion.showInDropdown) {
                if (!values.multipleAnswers) {
                    stringError = translatedLabels?.multipleChoiceValidationText;
                } else {
                    var selectedAnswers = Object.keys(values.multipleAnswers).map((e: any, index: number) => {
                        if (Object.values(values.multipleAnswers)[index] === true) {
                            return e;
                        }
                    }).filter(e => e);
                    if (selectedAnswers.length === 0) {
                        stringError = translatedLabels?.multipleChoiceValidationText;
                        isEmpty = true;
                    }
                }
            }
        } else if (baseQuestion?.questionType === QuestionType.slider) {
            if (values.value === undefined) {
                stringError = translatedLabels?.noNumberValidationText;
                isEmpty = true;
            }
        }

        if (stringError !== null && !(this.props.emptyAllowed && isEmpty)) {
            throw new SubmissionError({ _error: stringError })
        }

        var succeeded = await this.props.onSubmitAnswer(values, this.props.questionAnswers, isEmpty, this.props.countyValue);
        
        if(succeeded)
            this.props.resetForm()
        
    }

    
    onPreviousQuestion = async (values: any) => {
        var succeeded = await this.props.onPreviousQuestion(this.props.questionAnswers, true, this.props.countyValue);

        if(succeeded)
            this.props.resetForm();
    }

    render() {
        const { handleSubmit, error, content, translatedLabels, padAnswer, answerSending } = this.props;
        return (
            <>
                <form style={{ width: '100%' }} onSubmit={handleSubmit((values: any) => this.onSubmitProfileAnswer(values))} >
                    <div className={padAnswer ? "column answerContainer paddedAnswerContainer" : "column answerContainer"}>
                        {content}
                        {error && <strong className="errorMessage">{error}</strong>}
                    </div>
                    <div className="colums nextButtonColumn" style={{display:"flex"}}>
                        {this.props.showPrevious && 
                            <div className="column is-flex is-half is-justify-content-start">
                                <button type="button" className="blueButton" disabled={answerSending} onClick={((values: any) => this.onPreviousQuestion(values))}>{translatedLabels?.previousQuestion}</button>
                            </div>
                        }
                        <div className={this.props.showPrevious ? "column is-flex is-half is-justify-content-end" : "column is-flex is-justify-content-end"}>
                            <button type="submit" className="blueButton" disabled={answerSending}>{translatedLabels?.nextQuestion}</button>
                        </div>
                    </div>
                    
                </form>
            </>
        )
    }

}

const mapStateToProps = (state: any): StateProps => {
    return ({
        formValues: getFormValues(formName)(state) as any,
        i18n: state.i18n
    })
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    resetForm: () => dispatch(destroy(formName))
});

const ProfileSurveyReduxForm = reduxForm<{}, Props>({
    form: formName,
    keepDirtyOnReinitialize: false,
    enableReinitialize: false,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
})(ProfileSurveyForm);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSurveyReduxForm);
