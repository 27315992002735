import React from 'react';
import IdentityApiBase from './IdentityApiBase';
import { Method } from 'axios';
import { ChangePassword, EnableMFA, Login } from '../../types/model';


const endpoint = 'account' as string;

class AccountApi extends IdentityApiBase {
    login(model: Login): Promise<Login> {
        return this.doHttpRequest<Login>('post', `${endpoint}/login`, model, false);
    }
    logout(): Promise<string> {
        return this.doHttpRequest<string>('get', `${endpoint}/signout`, null, false);
    }
    forgotPassword(email: string): Promise<string> {
        return this.doHttpRequest<string>('post', `${endpoint}/forgotpassword/${email}`, null, false);
    }
    resetPassword(id: string): Promise<string> {
        return this.doHttpRequest<string>('post', `${endpoint}/resetpassword/${id}`, null, false);
    }
    changePassword(model: ChangePassword): Promise<string> {
        return this.doHttpRequest<string>('post', `${endpoint}/changePassword`, model, false);
    }
    enableMFAImage(): Promise<string> {
        return this.doHttpRequest<string>('get', `${endpoint}/enableMFA/image`, null, false);
    }
    enableMFAText(): Promise<string> {
        return this.doHttpRequest<string>('get', `${endpoint}/enableMFA/text`, null, false);
    }
    enableMFAPost(model: EnableMFA): Promise<string> {
        return this.doHttpRequest<string>('post', `${endpoint}/enableMFA`, model, false);
    }
    isMFAEnabled(): Promise<boolean> {
        return this.doHttpRequest<boolean>('get', `${endpoint}/IsMFAEnabled`, null, false);
    }
}

export default new AccountApi();