import React from 'react';
import { connect } from 'react-redux'
import "bulma/css/bulma.min.css";
import AppContainer from './containers/AppContainer'
import { ToastContainer } from "react-toastify";
import './App.css';
import { ThunkDispatch } from 'redux-thunk';
import { LoginState, RootState } from './types/state';
import { AnyAction } from 'redux';
import { getLanguages } from './actions/CMS/languageActions';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import { CustomWindow } from './types';

const customWindow = window as CustomWindow;

export interface StateProps {
  LoginState: LoginState;
  isLoading: boolean;
}

interface DispatchProps {
  getLanguages: () => void;
}

class App extends React.Component<StateProps & DispatchProps, {} > {


  render() {
    
    Sentry.init({
      dsn: customWindow.settings.sentryDSN,
      integrations: [new BrowserTracing()],
  
      environment: customWindow.settings.environment,
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
    
    const { isLoading } = this.props;

    var waitScreen = null;
    if (isLoading)
        waitScreen = "<div> Loading ... </div>";

    return (
      <> 
        <ToastContainer
          position="top-right"
          autoClose={3000}
          closeOnClick
        />
        <AppContainer />

        {waitScreen}
      </>
    )
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>) => ({
  getLanguages: () => dispatch(getLanguages())
})


const mapStateToProps = (state: RootState) => {
  return {
      LoginState: state.login,
      isLoading: state.isLoading
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
