import React, { } from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm, change } from 'redux-form';
import { DisplayType, IdentityProfile, Policies, Question, QuestionType, StatusType, Survey } from '../../types/model';
import ComponentHeader from '../ComponentHeader';
import GeneralComponent from '../GeneralComponent';
import PageHeader from '../PageHeader';
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import TextEditorComponent from '../InputComponents/TextEditorComponent';
import { ConvertHtmlToEditorState } from '../../helpers/textAreaHelper';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Gridview from '../gridview/GridviewComponent';
import { questionsColumns } from '../../constants/tableColumns';
import QuestionForm from './QuestionForm';
import { enumToDropdown, getQuestionOfRank } from '../../helpers/commonHelper';
import { Modal } from '../Modal';
import { TextInputComponent } from '../InputComponents/TextInputComponent';
import integer from '../../validators/integer';
import QuestionOrderForm from './QuestionOrderForm';


interface OwnProps {
    survey: Survey | null;
    onSubmitSurvey: (values: any, saveQuestion: boolean) => void;
    onSubmitQuestion: (values: any) => void;
    copyQuestion: (values: any) => void;
    copySurvey: (id: string) => void;
    removeQuestion: (values: any, row: any) => void;
    moveQuestion: (row: any, position: any) => void
}

interface MatchParams {
    id: string
}

interface DispatchProps {
    updateFormValue: (field: string, value: string) => any;
    dispatch: any
}

interface State {
    editorStateHeaderText: EditorState
    editorStateFooterText: EditorState
    showModal: boolean
    showOrderModal: boolean
    selectedQuestionGuid: string | null,
    selectedRow: any,
    forceUpdate: boolean
}

interface StateProps {
    formValues: any;
    selectedLanguageId: number;
    initialValues: any;
    i18n: any,
    profile: IdentityProfile
}

type Props = OwnProps & DispatchProps & StateProps & RouteComponentProps<MatchParams>;

const formName = "SurveyForm"
class SurveyForm extends React.Component<Props & InjectedFormProps<{}, any>, State> {
    constructor(props: Props & InjectedFormProps<{}, any>) {
        super(props);
        this.state = {
            editorStateHeaderText: EditorState.createEmpty(),
            editorStateFooterText: EditorState.createEmpty(),
            showModal: false,
            showOrderModal: false,
            forceUpdate: false,
            selectedQuestionGuid: null,
            selectedRow: null,
        }
    }

    componentDidUpdate = (prevProps: Props) => {

        if (this.props.survey !== prevProps.survey || this.props.selectedLanguageId !== prevProps.selectedLanguageId) {

            var surveyText = this.props.survey != null ? this.props.survey?.surveyTexts?.find((st) => st.languageId === this.props.selectedLanguageId) : null;

            var editorStateHeader = ConvertHtmlToEditorState(surveyText ? surveyText.headerText : "<div></div>");
            var editorStateFooter = ConvertHtmlToEditorState(surveyText ? surveyText.footerText : "<div></div>")

            this.setState({ editorStateHeaderText: editorStateHeader, editorStateFooterText: editorStateFooter })
        }
    }

    onEditorStateChange = (editor: EditorState, accessor?: string) => {
        const rawContentState = convertToRaw(editor.getCurrentContent());
        const html = draftToHtml(rawContentState);

        if (accessor != null) {
            this.props.updateFormValue(accessor, html);
            accessor == 'headerText' ? this.setState({ editorStateHeaderText: editor }) : this.setState({ editorStateFooterText: editor });
        }
    }

    addItem = () => {
        this.setState({ showModal: !this.state.showModal })
    }

    onRowClick = (row: any) => {
        this.setState({ selectedQuestionGuid: row.original.guid, showModal: !this.state.showModal })
    }

    changeOrder = (row: any) => {
        this.setState({ selectedRow: row, showOrderModal: true })
    }

    removeItem = async (row: any, index: any) => {
        await this.props.removeQuestion(row, index);

        this.setState({ forceUpdate: !this.state.forceUpdate })
    }

    closeQuestionModal = () => {
        this.setState({ selectedQuestionGuid: null, showModal: !this.state.showModal })
    }

    closeOrderModal = () => {
        this.setState({ selectedQuestionGuid: null, showOrderModal: false })
    }

    onSubmitQuestionWrapper = async (values: any) => {
        await this.props.onSubmitQuestion(values);
        this.props.onSubmitSurvey(this.props.formValues, true);
    }

    copyQuestionWrapper = async (values: any) => {
        await this.props.copyQuestion(values);
        this.props.onSubmitSurvey(this.props.formValues, true);
        this.setState({ selectedQuestionGuid: null, showModal: false })
    }

    onSaveQuestionOrder = (values: any) => {
        this.props.moveQuestion(this.state.selectedRow, values.newIndex - 1);
        this.closeOrderModal();
    }

    onCopySurvey = async () => {
        await this.props.copySurvey(this.props.match.params.id);
        this.props.history.push({ pathname: `/CMS/Vragenlijsten` });
    }

    render() {
        const { survey, onSubmitSurvey, handleSubmit, i18n } = this.props;
        const { showModal, showOrderModal, selectedRow } = this.state;

        const claims = this.props.profile ? this.props.profile.roles[0].claims : [];
        const isEditable = claims.includes(Policies.QuestionnaireEdit);
        const isDeletable = claims.includes(Policies.QuestionnaireDelete);

        var question = survey?.questions?.find(e => e.guid == this.state.selectedQuestionGuid);

        let questions = survey?.questions?.filter(e => {
            if (e != null && e != undefined) {
                if (e.id as number >= 0)
                    return e;
            }
        }).filter(e => e);

        questions = questions?.sort((a: any, b: any) => a.rank > b.rank ? 1 : -1);

        var listOfTooltips = [] as any;
        questions?.forEach((q, i) => {
            if (i + 1 === questions?.length) {
                listOfTooltips.push(<div>{i18n.endSurvey}</div>);
                return;
            }
            if (q.questionType === QuestionType.multipleChoice) {
                var tooltipMulti = [] as any;
                q?.answers?.sort((a: any, b: any) => a.rank > b.rank ? 1 : -1).forEach((a, answerIndex) => {
                    var followUpQuestion = questions?.find(e => e.guid === a.followUpQuestionGuid);
                    var answerText = a.answerTextViewModels?.find(atv => atv.languageId === this.props.selectedLanguageId);
                    tooltipMulti.push(<div>{answerText ? answerText.answerText : a.description} =&gt; {a.followUpQuestionGuid == "246f6869-b4af-4591-ba8e-aee503c51ff1" ? i18n.endSurvey : followUpQuestion ? getQuestionOfRank(followUpQuestion.rank) : i + 2}</div>)

                });
                listOfTooltips.push(tooltipMulti)
            } else {
                var followupQuestionId = q?.answers ? q?.answers.find(e => e)?.followUpQuestionGuid : null;
                var followUpQuestion = questions?.find(e => e.guid === followupQuestionId)
                listOfTooltips.push(<div>{i18n.goesToQuestion} =&gt; {followupQuestionId == "246f6869-b4af-4591-ba8e-aee503c51ff1" ? i18n.endSurvey : followUpQuestion ? getQuestionOfRank(followUpQuestion.rank) : i + 2}</div>)
            }
        })

        return (
            <>

                {showOrderModal && <QuestionOrderForm
                    row={selectedRow}
                    i18n={i18n}
                    closeOrderModal={this.closeOrderModal}
                    moveQuestion={this.props.moveQuestion}
                    onSave={this.onSaveQuestionOrder}
                />}

                {showModal && <QuestionForm
                    question={question}
                    questions={(survey && survey.questions) ? survey.questions : []}
                    onSubmitQuestion={this.onSubmitQuestionWrapper}
                    closeQuestionModal={this.closeQuestionModal}
                    copyQuestion={this.copyQuestionWrapper}
                />}

                <form onSubmit={handleSubmit((values: any) => onSubmitSurvey(values, false))}>
                    <div className="columns" >
                        <div className="column">
                            <PageHeader
                                title={`${i18n.survey}`}
                                showLanguageSwitcher={true}
                                button={<button className="blueButton" style={{ display: isEditable ? 'flex' : 'none' }} type="submit">{i18n.save}</button>}
                            />
                            <div className="columns">
                                <div className="column componentWrapper">
                                    <GeneralComponent isCampaign={false} statusDropdownOptions={enumToDropdown(i18n.statusTypeEnum)} />
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column componentWrapper" style={{ marginTop: '20px', marginBottom: '20px' }}>
                                    <ComponentHeader title={i18n.questions} textButton={isEditable ? i18n.addQuestion : null} onClickButton={this.addItem} />
                                    {survey?.questions &&
                                        <Gridview
                                            columns={questionsColumns(i18n)}
                                            data={questions ? questions : []}
                                            onRowClick={(e: any) => this.onRowClick(e)}
                                            deleteItem={isDeletable}
                                            listOfTooltips={listOfTooltips}
                                            onDeleteClick={(e: any, i: any) => this.removeItem(e, i)}
                                            changeOrder={this.changeOrder}
                                        // onDeleteClick={this.manageFields} 
                                        />
                                    }
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column componentWrapper">
                                    <ComponentHeader title={i18n.content} />
                                    <div className="columns">
                                        <div className="column">
                                            <Field
                                                component={TextEditorComponent}
                                                label={i18n.headerText}
                                                name="headerText"
                                                disabled={!isEditable}
                                                editorState={this.state.editorStateHeaderText}
                                                onEditorStateChange={this.onEditorStateChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <div className="column">
                                            <Field
                                                component={TextEditorComponent}
                                                label={i18n.footerText}
                                                name="footerText"
                                                disabled={!isEditable}
                                                editorState={this.state.editorStateFooterText}
                                                onEditorStateChange={this.onEditorStateChange}
                                            />
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="columns">
                                <div className="column componentWrapper">
                                    <ComponentHeader title={i18n.Copy} />
                                    <div className="columns">
                                        <div style={{ width: '400px' }}>
                                            <div className="column">
                                                <button type={"button"} onClick={this.onCopySurvey} className="whiteButton">{i18n.Copy}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </>
        )
    }
}



const mapStateToProps = (state: any, props: OwnProps & RouteComponentProps<MatchParams>): StateProps => {
    var surveyText = props.survey ? props.survey.surveyTexts?.find((st: any) => st.languageId === state.selectedLanguageId) : null;
    var statusValue = props.survey ? props.survey.status : StatusType.ready;

    return ({
        initialValues: {
            id: props.survey ? props.survey.id : 0,
            guid: props.match.params.id,
            code: props.survey ? props.survey.code : "",
            title: surveyText ? surveyText?.title : "",
            characteristic: props.survey ? props.survey.characteristic : "",
            status: statusValue ? statusValue : StatusType.ready,
            isArchived: props.survey ? props.survey.isArchived : false,
            headerText: surveyText ? surveyText.headerText : "",
            footerText: surveyText ? surveyText.footerText : "",
            languageId: state.selectedLanguageId,
        } as any,
        selectedLanguageId: state.selectedLanguageId,
        formValues: getFormValues(formName)(state) as any,
        i18n: state.i18n,
        profile: state.profile
    })
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    updateFormValue: (field: string, value: string) => dispatch(change(formName, field, value)),
    dispatch
});

const SurveyReduxForm = reduxForm<any, any>({
    form: formName,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
})(SurveyForm);

const conn = connect(mapStateToProps, mapDispatchToProps)(SurveyReduxForm);

export default withRouter(conn);
