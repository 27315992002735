import React from "react";
import { connect } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm, change } from 'redux-form';
import { Language } from "../../types/model";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Modal } from '../Modal';
import { DropdownComponent } from '../InputComponents/DropdownComponent';
import ComponentHeader from "../ComponentHeader";
import { TextInputComponent } from "../InputComponents/TextInputComponent";

interface OwnProps {
    onSubmit2FA: (value: string) => void;
}

interface MatchParams {
    id: string
}

interface DispatchProps {
    updateFormValue: (field: string, value: string) => any;
}

interface State {
    code: string;
}

interface StateProps {
    formValues: any;
    initialValues: any;
}

type Props = OwnProps & DispatchProps & StateProps & RouteComponentProps<MatchParams>;

const formName = "MFAForm"
class MFAForm extends React.Component<Props & InjectedFormProps<{}, any>, State> {
    constructor(props: Props & InjectedFormProps<{}, any>) {
        super(props);
        this.state = {
            code: ""
        }
    }

    render() {

        const { onSubmit2FA, handleSubmit } = this.props;

        return (
            <>
                <form onSubmit={handleSubmit((e: any) => { onSubmit2FA(e.mfa.code); })}>
                    <ComponentHeader title="2FA confirmation" isForm={true} textButton="set" />
                    <div className="columns">
                        <div className="column is-12">
                            <Field
                                component={TextInputComponent}
                                labelInComponent={true}
                                name="mfa.code"
                                type="text"
                                label="2Fa Code:"
                            />
                        </div>

                    </div>
                </form>
            </>
        )
    }

}

const mapStateToProps = (state: any, props: OwnProps): StateProps => {
    return ({
        initialValues: {
            mfa: {
                code: ""
            }
        } as any,
        formValues: getFormValues(formName)(state) as any
    })
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    updateFormValue: (field: string, value: string) => dispatch(change(formName, field, value)),
});

const MFAReduxForm = reduxForm<{}, Props>({
    form: formName,
    keepDirtyOnReinitialize: true,
    enableReinitialize: false
})(MFAForm);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MFAReduxForm));
