import SurveyApi from "../../api/CMS/Survey";
import { Survey } from "../../types/model";

export const getSurvey = async (guid: string) => {
    return await SurveyApi.getSurvey(guid);
}

export const getSurveys = async () => {
    return await SurveyApi.getSurveys();
}

export const copySurvey = async (guid: string) => {
    return await SurveyApi.copySurvey(guid);
}

export const upsertSurvey = async (values: Survey) => {
    return await SurveyApi.upsertSurvey(values);
}