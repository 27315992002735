import React from "react";
import { connect } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm, change } from 'redux-form';
import { User, CmsRole, IdentityProfile, Policies } from "../../types/model";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Modal } from '../Modal';
import { DropdownComponent } from '../InputComponents/DropdownComponent';
import ComponentHeader from "../ComponentHeader";
import { TextInputComponent } from "../InputComponents/TextInputComponent";
import cryptoRandomString from 'crypto-random-string';
import { i18nReducer } from "../../reducers/i18nReducer";

interface OwnProps {
    cmsUser: User | undefined;
    cmsRoles: CmsRole[];
    closeCmsUserModal: () => void;
    onSubmitCmsUser: (values: User) => void;
}

interface MatchParams {
    id: string
}

interface DispatchProps {
    updateFormValue: (field: string, value: string) => any;
}

interface State {
    cmsUser: User | undefined
}

interface StateProps {
    formValues: any;
    initialValues: any;
    i18n: any
    profile: IdentityProfile
}

type Props = OwnProps & DispatchProps & StateProps & RouteComponentProps<MatchParams>;

const formName = "CmsUserForm"
class CmsUserForm extends React.Component<Props & InjectedFormProps<{}, any>, State> {
    constructor(props: Props & InjectedFormProps<{}, any>) {
        super(props);
        this.state = {
            cmsUser: props.cmsUser
        }
    }

    cmsUserContent = () => {
        const { i18n } = this.props;

        var statusTypes = [
            { label: 'Actief', value: true },
            { label: 'Inactief', value: false }
        ];

        const claims = this.props.profile ? this.props.profile.roles[0].claims : [];
        const isEditable = claims.includes(Policies.SettingsEdit);

        var roleTypes = this.props.cmsRoles.map(x => ({ label: x.name, value: x.id }));

        return (
            <>
                <ComponentHeader onClose={this.props.closeCmsUserModal} title={i18n.user} isForm={true} textButton={isEditable ? i18n.save : null} />
                <div className="columns">
                    {this.props.cmsUser &&
                        <div className="column">
                            <Field
                                component={TextInputComponent}
                                labelInComponent={true}
                                name="cmsUser.id"
                                type="text"
                                label={i18n.id + ":"}
                                readOnly={true}
                                disabled={true}
                            />
                        </div>
                    }
                    {!this.props.cmsUser &&
                        <div className="column">
                            <Field
                                component={TextInputComponent}
                                labelInComponent={true}
                                name="cmsUser.password"
                                type="text"
                                label={i18n.password + ":"}
                                readOnly={true}
                                disabled={true}
                            />
                        </div>
                    }
                    {/* <div className="column is-4">
                        <Field
                            component={DropdownComponent}
                            label={i18n.status + ":"}
                            name="cmsUser.isActive"
                            required={true}
                            labelInComponent={true}
                            options={statusTypes}
                        />
                    </div> */}
                </div>
                <div className="columns">
                    <div className="column is-8">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            name="cmsUser.email"
                            type="text"
                            readOnly={!isEditable}
                            label={i18n.emailAddress + ":"}
                        />
                    </div>
                    <div className="column is-4">
                        <Field
                            component={DropdownComponent}
                            label={i18n.role + ":"}
                            name="cmsUser.roleId"
                            required={true}
                            readOnly={!isEditable}
                            labelInComponent={true}
                            options={roleTypes}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-6">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            name="cmsUser.firstName"
                            required={true}
                            readOnly={!isEditable}
                            type="text"
                            label={i18n.firstName + ":"} />
                    </div>
                    <div className="column is-6">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            required={true}
                            readOnly={!isEditable}
                            label={i18n.lastName + ":"}
                            type="text"
                            name="cmsUser.surName"
                        />
                    </div>
                </div>
            </>
        )
    }


    render() {

        const { onSubmitCmsUser, handleSubmit, closeCmsUserModal, i18n } = this.props;

        return (
            <>
                <form onSubmit={handleSubmit((e: any) => { onSubmitCmsUser(e.cmsUser); })}>
                    <Modal
                        content={this.cmsUserContent()}
                    />
                </form>
            </>
        )
    }

}

const mapStateToProps = (state: any, props: OwnProps): StateProps => {
    return ({
        initialValues: {
            cmsUser: {
                firstName: props.cmsUser?.firstName,
                surName: props.cmsUser?.surName,
                email: props.cmsUser?.email,
                roleId: props.cmsUser?.roleId ?? props.cmsRoles[0]?.id,
                isActive: props.cmsUser?.isActive,
                id: props.cmsUser?.id,
                password: props.cmsUser?.password ? props.cmsUser?.password : cryptoRandomString({ length: 10, type: 'alphanumeric' })
            }
        } as any,
        formValues: getFormValues(formName)(state) as any,
        i18n: state.i18n,
        profile: state.profile
    })
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    updateFormValue: (field: string, value: string) => dispatch(change(formName, field, value)),
});

const CmsUserReduxForm = reduxForm<{}, Props>({
    form: formName,
    keepDirtyOnReinitialize: true,
    enableReinitialize: false
})(CmsUserForm);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CmsUserReduxForm));
