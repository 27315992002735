import * as React from "react";
import { SurveysPage } from "../pages/Surveys";
import { SurveyPage } from "../pages/Survey";
import { CampaignsPage } from "../pages/Campaigns";
import { CampaignPage } from "../pages/Campaign";
import { ProfilesPage } from "../pages/Profiles";
import { SettingsPage } from "../pages/Settings";

import { ProfileSurveyPage } from "../pages/ProfileSurvey";

import { Switch, Redirect } from "react-router-dom";
import RouteCMSComponent from "./RouteCMSComponent";
import RouteSurveyComponent from "./RouteSurveyComponent";
import { getLanguages } from '../actions/CMS/languageActions';
import { RootState } from "../types/state";
import { initialize } from "../actions/applicationActions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { MfaPage } from "../pages/Mfa";
import { getUserProfile } from "../actions/CMS/profileActions";
import { getCountries } from "../actions/CMS/languageActions";

const mapStateToProps = ({ initialized }: RootState) => ({
    initialized: initialized
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>) => ({
    getLanguages: () => dispatch(getLanguages()),
    initialize: () => dispatch(initialize()),
    getUserProfile: () => dispatch(getUserProfile()),
    getCountries: () => dispatch(getCountries())
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const cms = "cms";

const CMSRouterWrapper: React.FunctionComponent<Props> = ({ initialized, initialize, getLanguages, getUserProfile, getCountries }) => {
    React.useEffect(() => {
        getLanguages();
        initialize();
        getUserProfile();
        getCountries();
    }, [initialize]);

    return (
        <Switch>
            <RouteCMSComponent exact={true} path={[`/${cms}`, `/${cms}/Vragenlijsten`]} component={SurveysPage} requiredClaims={["questionnaire.edit"]} />
            <RouteCMSComponent exact={true} path={`/${cms}/Vragenlijsten/:id`} component={SurveyPage} requiredClaims={["questionnaire.view"]} />
            <RouteCMSComponent exact={true} path={`/${cms}/Campagnes`} component={CampaignsPage} requiredClaims={["campaign.edit"]} />
            <RouteCMSComponent exact={true} path={`/${cms}/Campagnes/:id`} component={CampaignPage} requiredClaims={["campaign.view"]} />
            <RouteCMSComponent exact={true} path={`/${cms}/Profielen`} component={ProfilesPage} requiredClaims={["profile.edit"]} />
            <RouteCMSComponent exact={true} path={`/${cms}/Instellingen`} component={SettingsPage} requiredClaims={["settings.edit"]} />
            <RouteCMSComponent exact={true} path={`/${cms}/MFA`} component={MfaPage} requiredClaims={["settings.view"]} />
        </Switch>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CMSRouterWrapper);
