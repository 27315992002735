import React, { CSSProperties } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Styles } from "react-select";
import Logo from '../images/bvdg_logo.png';
import { DropdownOption } from "../types/model";
import LanguageSwitcher from "./InputComponents/LanguageSwitcher";
import { Campaign, DisplayType, InformationType, ProfileAnswer, Question, QuestionType, StatusType, Survey, SurveyCampaign, SurveyQuestionAnswer, SurveySurvey, SurveyText, TranslatedLabels } from '../types/model';
import { toast } from "react-toastify";
import SurveyForm from "../components/forms/SurveyForm";
import { createGuid } from "../helpers/guidHelper";
import { getCampaign } from '../actions/Survey/campaignActions';
import { getTranslatedWords } from '../actions/Survey/languageActions';
import { getSurvey } from '../actions/Survey/campaignSurveyActions';
import { postQuestionAnswer } from '../actions/Survey/questionAnswerActions';
import ProfileSurveyComponent from '../components/ProfileSurveyComponent';

interface State {
}

interface StateProps {
    i18n: any;
}

type Props = StateProps & RouteComponentProps;
class NotFoundComponent extends React.Component<Props, State> {

    state: StateProps = {
        i18n: undefined,
    }

    render() {
        const { i18n } = this.props;

        let languageOptions = [
            { value: "Nederland", label: "Nederland", icon: "nl" },
            { value: "Duitsland", label: "Duitsland", icon: "de" }
        ] as DropdownOption[];

        const customStyles = {
            option: (cssProps: CSSProperties) => ({
                ...cssProps,
                display: 'flex',
                alignItems: 'center'
            }),

            container: (cssProps: CSSProperties) => ({
                ...cssProps,
                width: 200,
                height: 50,
                float: 'right',
                marginTop: '30px',
                marginRight: '40px'
            }),

            indicatorSeparator: (cssProps: CSSProperties) => ({
                ...cssProps,
                width: '0px'
            })
        } as Partial<Styles<any, any, any>>;

        return (
            <>
                <div className="columns is-mobile">
                    <div className="column is-half logoContainer">
                        <img src={Logo} />
                    </div>
                    <div className="column is-half">
                    </div>
                </div>
                <div className="columns is-centered is-mobile">
                    <div className="columns is-centered is-mobile loginFormContainer">
                        <div className="column is-centered is-full">
                            <p>{i18n.notFoundText}</p>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}

const mapStateToProps = (state: any): StateProps => {
    return ({
        i18n: state.i18n
    })
}

export default withRouter(connect(mapStateToProps, {})(NotFoundComponent))

