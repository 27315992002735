import CampaignApi from "../../api/CMS/Campaign";
import { Campaign } from "../../types/model";


// export const getLanguages = (): AsyncAction<GetLanguageAction> => {
export const getCampaign = async (guid: string) => {
    return await CampaignApi.getCampaign(guid);
}

export const getCampaigns = async () => {
    return await CampaignApi.getCampaigns();
}

export const upsertCampaign = async (values: Campaign) => {
    return await CampaignApi.upsertCampaign(values);
}

export const exportCampaign = async (guid: string, filename: string, callback: () => void) =>
{
    return await CampaignApi.exportCampaign(guid, filename, callback);
}