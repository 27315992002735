import CMSApiBase from './CMSApiBase';
import { Campaign, IEntity, Survey } from '../../types/model';


const endpoint = 'campaigns' as string;

class CampaignApi extends CMSApiBase {
    getCampaigns(): Promise<Campaign[]> {
        return this.doHttpRequest<Campaign[]>('get', `${endpoint}`, null, false);
    }
    getCampaign(id : string): Promise<Campaign> {
        return this.doHttpRequest<Campaign>(`get`, `${endpoint}/${id}`, null, true);
    }
    upsertCampaign(campaign : IEntity) : Promise<any> {
        return this.doHttpRequest<Campaign>(`post`, `${endpoint}/${campaign.guid}`, campaign, true);
    }
    exportCampaign(id: string, filename: string, callback : () => void) : Promise<any>
    {
        return this.downloadFile(`exportcampaign/${id}`, `${filename}.xlsx`, callback)
    }
}

export default new CampaignApi();