const MESSAGE = "----";

export default (value: string, format: any) => {
    if (value && format) {
        var arrayOfInputCharacters = value.split('');
        var arrayOfValidateCharacters = format.split('');

        if (arrayOfInputCharacters.length !== arrayOfValidateCharacters.length)
            return MESSAGE;

        for (var i = 0; i < arrayOfValidateCharacters.length; i++) {
            var charValidateValue = arrayOfValidateCharacters[i];
            var charInputValue = arrayOfInputCharacters[i];


            if (charValidateValue.toUpperCase() === charInputValue.toUpperCase())
                continue;

            if (charValidateValue.toUpperCase() === "A" && isNaN(parseInt(charInputValue))) {
                continue;
            }
            if (charValidateValue.toUpperCase() === "N" && !isNaN(parseInt(charInputValue))) {
                continue;
            }

            return MESSAGE;
        }
    }
}
