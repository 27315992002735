interface Props {
    content: any;
    size?: "small" | "large"
}

export const Modal = (props: Props) => {
    const { content, size } = props;

    return (
        <>
            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card" style={{height: size === "small" ? "250px" : '100%', minHeight: size=== "small" ? "0px" : "92%" }} >
                    {content}
                </div>
            </div>
        </>
    )
}