import { Campaign, DisplayType, EmptyAnswer, InformationType, ProfileAnswer, Question, QuestionType, StatusType, Survey, SurveyCampaign, SurveyQuestionAnswer, SurveySurvey, SurveyText, TranslatedLabels } from '../types/model';

import { CustomWindow } from "../types";
import Logo from '../images/bvdg_logo.png';
import ProfileSurveyComponent from '../components/ProfileSurveyComponent';
import React from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { createGuid } from "../helpers/guidHelper";
import { getCampaign } from '../actions/Survey/campaignActions';
import { getSurvey } from '../actions/Survey/campaignSurveyActions';
import { getTranslatedWords } from '../actions/Survey/languageActions';
import moment from "moment";
import { postQuestionAnswer, postEmptyAnswer, previousQuestion } from '../actions/Survey/questionAnswerActions';
import { toast } from "react-toastify";
import { updateProfileStatus } from '../actions/Survey/profileActions';
import { withRouter } from 'react-router-dom';

interface State {
    surveyQuestionAnswers: SurveyQuestionAnswer[] | null,
    surveyIndex: number;
    showStartCampaignMessage: boolean
    showStartSurveyMessage: boolean
    showEndSurveyMessage: boolean
    hasError: boolean
    // surveySurveyQuestionAnswers: SurveyQuestionAnswer[][] | null,
    campaign: SurveyCampaign | null,
    translatedLabels: TranslatedLabels | null
    updateProfileStatus: boolean
    answerSending: boolean
    profileLoading: boolean
}

interface MatchParams {
    campaignGuid: string
    profileGuid: string
    languageId: string
    allowEmpty: string
}

interface StateProps {
    selectedLanguageId: number,
    i18n: any
}

const customWindow = window as CustomWindow;

type Props = StateProps & RouteComponentProps<MatchParams>

const generatedProfileGuid = createGuid();
const emptyGuid = "00000000-0000-0000-0000-000000000000";

class ProfileSurveyContainer extends React.Component<Props, State> {
    state = {
        surveyQuestionAnswers: null,
        campaign: null,
        surveyIndex: 0,
        showStartCampaignMessage: true,
        showStartSurveyMessage: true,
        showEndSurveyMessage: false,
        hasError: false,
        translatedLabels: null,
        updateProfileStatus: false,
        answerSending: false,
        profileLoading: false
    }

    async componentDidUpdate(prevProps: any) {
        if (this.props?.location?.pathname !== prevProps.location?.pathname) {
            await this.didMount()
        }
    }

    async didMount() {
        var translatedWords = await getTranslatedWords(parseInt(this.props.match.params.languageId));
        var campaign = await getCampaign(this.props.match.params.campaignGuid, parseInt(this.props.match.params.languageId));
        var index = 0;

        if (!campaign) return;

        var surveyQuestionAnswers = null;
        var newQuestionAnswers: SurveyQuestionAnswer[] = [];
        var showStartCampaignMessage = this.state.showStartCampaignMessage;
        var showStartSurveyMessage = this.state.showStartSurveyMessage;

        for (var i = 0; i < campaign.surveys.length; i++) {
            index = i;
            surveyQuestionAnswers = await getSurvey(this.props.match.params.campaignGuid, campaign.surveys[i].guid, this.props.match.params.profileGuid === emptyGuid ? generatedProfileGuid : this.props.match.params.profileGuid, parseInt(this.props.match.params.languageId));
            if (surveyQuestionAnswers) {
                var lastFinishedQuestion = surveyQuestionAnswers.reverse().find(e => e.finished);
                if (lastFinishedQuestion) {
                    showStartSurveyMessage = false;
                    showStartCampaignMessage = false;
                }
                newQuestionAnswers = surveyQuestionAnswers.sort((a, b) => a.rank > b.rank ? 1 : -1);
                if (newQuestionAnswers.find(x => x.questionId == newQuestionAnswers[newQuestionAnswers.length - 1].questionId && x.finished) || lastFinishedQuestion?.followupQuestion === '246f6869-b4af-4591-ba8e-aee503c51ff1') {
                }
                else {
                    break;
                }
            }
        }
        this.setState({ surveyQuestionAnswers: newQuestionAnswers, campaign, surveyIndex: index, translatedLabels: translatedWords, showStartSurveyMessage, showStartCampaignMessage, profileLoading: false })
    }

    async componentDidMount() {
        await this.didMount()
    }

    onSubmitEmptyAnswer = async (questionId: number) => {
        var campaign = this.state.campaign as unknown as SurveyCampaign;
        var emptyAnswer = {
            profileId: this.props.match.params.profileGuid === emptyGuid ? generatedProfileGuid : this.props.match.params.profileGuid,
            questionId: questionId,
            campaignId: this.props.match.params.campaignGuid,
            surveyId: campaign.surveys[this.state.surveyIndex].guid
        } as EmptyAnswer

        await postEmptyAnswer(emptyAnswer);
    }

    onPreviousQuestion = async(questionAnswers: SurveyQuestionAnswer[], isEmpty: boolean, countryValue?: number): Promise<boolean> => {
        this.setState({ answerSending: true });
        var baseQuestion = questionAnswers[0];
        var questionId = baseQuestion.questionId;

        var campaign = this.state.campaign as unknown as SurveyCampaign;
        var emptyAnswer = {
            profileId: this.props.match.params.profileGuid === emptyGuid ? generatedProfileGuid : this.props.match.params.profileGuid,
            questionId: questionId,
            campaignId: this.props.match.params.campaignGuid,
            surveyId: campaign.surveys[this.state.surveyIndex].guid
        } as EmptyAnswer

        var result = false;

        await previousQuestion(emptyAnswer).then(profileGuid => {
            if (profileGuid) {
                this.props.history.push({
                    pathname: `/campagne/${this.props.match.params.campaignGuid}/${profileGuid}/${this.props.match.params.languageId}`
                });
                this.setState({ profileLoading: true });
                return;
            }
            result = true;
        }).catch(error => {
            this.onErrorOccured(error);
            //Ask user to retry when error is timeout
            result = false;
        });

        if(result)
        {
            var surveyQuestionAnswers = [...this.state.surveyQuestionAnswers ?? new Array()] as SurveyQuestionAnswer[]
            if(baseQuestion.guid === surveyQuestionAnswers[0].guid){
                //First question in survey
                this.setState({ answerSending: false });
                this.didMount();
                return result;
            }
            if(surveyQuestionAnswers.length > 1){
                var questionAnswer = surveyQuestionAnswers.filter(x => x.followupQuestion === baseQuestion.questionGuid);

                //Check for additional questions that are not connected by "followupQuestion"
                //Could happen with multiple ways to one question
                var currentQuestionIndex = surveyQuestionAnswers.findIndex((e) => e.questionId === baseQuestion.questionId);
                var previousQuestionsId = surveyQuestionAnswers[currentQuestionIndex - 1].questionId;
                var previousQuestions = surveyQuestionAnswers.filter(e => e.questionId === previousQuestionsId).find(x => x.finished)

                if(questionAnswer.length > 0 || previousQuestions){
                    for(var i = 0; i < surveyQuestionAnswers.length; i++){
                        if(surveyQuestionAnswers[i].followupQuestion === baseQuestion.questionGuid || surveyQuestionAnswers[i] === previousQuestions){
                            surveyQuestionAnswers[i].finished = false;
                            surveyQuestionAnswers[i].hasNextQuestion = false;
                        }
                    }
                }
                this.setState({surveyQuestionAnswers: surveyQuestionAnswers});
            }
        }

        this.setState({ answerSending: false });
        return result;
    }

    onSubmitAnswer = async (values: any, questionAnswers: SurveyQuestionAnswer[], isEmpty: boolean, countryValue?: number): Promise<boolean> => {
        // Prevent form submit when the error dialog is still shown
        if (this.state.hasError) {
            return false;
        }
        
        this.setState({ answerSending: true });
        var result = false;
        var baseQuestion = questionAnswers[0];
        var profileGuid = this.props.match.params.profileGuid;
        var campaign = this.state.campaign as unknown as Campaign;

        if (isEmpty) {
            await this.onSubmitEmptyAnswer(baseQuestion.questionId).then( () => {
                result = true;
            })
                .catch(error => {
                    this.onErrorOccured(error);
                    result = false;
                });
        }
        else {

            var profileAnswer = {
                profileId: profileGuid,
                campaignId: this.props.match.params.campaignGuid
            } as ProfileAnswer

            if (baseQuestion.questionType === QuestionType.open) {
                profileAnswer.questionAnswerIds = [baseQuestion.guid];
                if (baseQuestion.displayType === DisplayType.date) {
                    // profileAnswer.customAnswer = values.value.format('YYYY-MM-DD hh:mm:ss');
                    profileAnswer.customAnswer = moment(`${values.year}-${values.month}-${values.day}`, 'YYYY-M-D').format('YYYY-M-D hh:mm:ss');
                } else
                    profileAnswer.customAnswer = baseQuestion.displayType === DisplayType.country ? countryValue : values.value
            }
            if (baseQuestion.questionType === QuestionType.multipleChoice) {
                //  = questionAnswers.find(e => e.guid === values.value) as SurveyQuestionAnswer;

                // baseQuestion = questionAnswers.find(e => e.guid === values.value) as SurveyQuestionAnswer;

                if (baseQuestion.showInDropdown) {
                    if (baseQuestion.hasMultipleAnswers) {
                        profileAnswer.questionAnswerIds = values.value.map((e: any) => e.value);
                        var question;
                        for (var i = 0; i < profileAnswer.questionAnswerIds.length; i++) {
                            var newQuestion = questionAnswers.find(e => e.guid === profileAnswer.questionAnswerIds[i]) as SurveyQuestionAnswer;
                            if (question) {
                                if (newQuestion.followupQuestion) {
                                    var surveyQuestionAnswers = this.state.surveyQuestionAnswers as unknown as SurveyQuestionAnswer[];
                                    var followUpQuestion = surveyQuestionAnswers.find((e => e.followupQuestion === newQuestion.followupQuestion));
                                    if (followUpQuestion) {
                                        if (followUpQuestion.rank < question.rank) {
                                            question = newQuestion
                                        }
                                    }
                                }
                            } else {
                                question = newQuestion;
                            }
                        }
                        baseQuestion = question ? question : baseQuestion;

                    } else {
                        baseQuestion = questionAnswers.find(e => e.guid === values.value) as SurveyQuestionAnswer;
                        profileAnswer.questionAnswerIds = [values.value];
                        if (values.ownTextValue) {
                            profileAnswer.customAnswer = values.ownTextValue;
                        }
                    }
                } else {
                    if (baseQuestion.hasMultipleAnswers) {
                        var selectedAnswers = Object.keys(values.multipleAnswers).map((e: any, index: number) => {
                            if (Object.values(values.multipleAnswers)[index] === true) {

                                return e;
                            }
                        }).filter(e => e);

                        var question;
                        for (var i = 0; i < selectedAnswers.length; i++) {
                            var newQuestion = questionAnswers.find(e => e.guid === selectedAnswers[i]) as SurveyQuestionAnswer;
                            if (question) {
                                if (newQuestion.followupQuestion) {
                                    var surveyQuestionAnswers = this.state.surveyQuestionAnswers as unknown as SurveyQuestionAnswer[];
                                    var followUpQuestion = surveyQuestionAnswers.find((e => e.followupQuestion === newQuestion.followupQuestion));
                                    if (followUpQuestion) {
                                        if (followUpQuestion.rank < question.rank) {
                                            question = newQuestion
                                        }
                                    }
                                }
                            } else {
                                question = newQuestion;
                            }
                        }
                        baseQuestion = question ? question : baseQuestion;

                        profileAnswer.questionAnswerIds = selectedAnswers
                        if (values.ownTextValue) {
                            profileAnswer.customAnswer = values.ownTextValue;
                        }
                    } else {
                        baseQuestion = questionAnswers.find(e => e.guid === values.value) as SurveyQuestionAnswer;
                        var isOwnAnswer = questionAnswers.find(e => e.guid === values.value);
                        if (isOwnAnswer?.isOwnValueAnswer) {
                            profileAnswer.questionAnswerIds = [values.value]
                            profileAnswer.customAnswer = values.ownTextValue;
                        }
                        else {
                            profileAnswer.questionAnswerIds = [values.value];
                        }
                    }
                }
                if (baseQuestion.followupQuestion) {
                    baseQuestion.hasNextQuestion = true;
                }

            }
            if (baseQuestion.questionType === QuestionType.slider) {
                profileAnswer.questionAnswerIds = [baseQuestion.guid];
                profileAnswer.customAnswer = values.value ? values.value : baseQuestion.minValue
            }

            if (campaign.isProfileCampaign) {
                profileAnswer.languageId = parseInt(this.props.match.params.languageId);
                profileAnswer.profileId = this.props.match.params.profileGuid === emptyGuid ? generatedProfileGuid : this.props.match.params.profileGuid;
            }

            await postQuestionAnswer(profileAnswer).then(profileGuid => {
                if (profileGuid) {
                    this.props.history.push({
                        pathname: `/campagne/${this.props.match.params.campaignGuid}/${profileGuid}/${this.props.match.params.languageId}`
                    });
                    this.setState({ profileLoading: true });
                    return;
                }
                result = true;
            }).catch(error => {
                this.onErrorOccured(error);
                //Ask user to retry when error is timeout
                result = false;
            });
        }

        var surveyQuestionAnswers = this.state.surveyQuestionAnswers as unknown as SurveyQuestionAnswer[];

        let newQuestions = null;
        let showStartSurveyMessage = this.state.showStartSurveyMessage
        let showEndSurveyMessage = this.state.showEndSurveyMessage

        var notNullableCampaign = this.state.campaign as unknown as Campaign;

        if (result) {
            if (baseQuestion.questionId === surveyQuestionAnswers[surveyQuestionAnswers.length - 1].questionId || (baseQuestion.followupQuestion === '246f6869-b4af-4591-ba8e-aee503c51ff1' && !isEmpty)) {
                if (this.state.campaign && notNullableCampaign.surveys) {
                    if (campaign.isProfileCampaign && !notNullableCampaign.surveys[this.state.surveyIndex + 1] && campaign) {
                        await updateProfileStatus(this.props.match.params.profileGuid === emptyGuid ? generatedProfileGuid : this.props.match.params.profileGuid);
                    }
                }
                showEndSurveyMessage = true;
                showStartSurveyMessage = true;
            } else {
                surveyQuestionAnswers.filter(sqa => sqa.guid === baseQuestion.guid).map((e) => {
                    e.finished = true;
                });

                newQuestions = surveyQuestionAnswers.map(s => ({ ...s }));
            }
            this.setState({ surveyQuestionAnswers: newQuestions, showEndSurveyMessage })
        }
        this.setState({ answerSending: false });
        return result;
    }

    onErrorOccured(error: any) {
        const { i18n } = this.props;

        this.setState({ hasError: true, answerSending: false });
        setTimeout(() => { this.state.hasError = false; }, 2500)

        toast.error(i18n.ErrorInPostQuestionAnswer, { autoClose: 8000 });
    }

    toggleMessages = async (type: InformationType) => {
        if (type === InformationType.startCampaign)
            this.setState({ showStartCampaignMessage: !this.state.showStartCampaignMessage })
        if (type === InformationType.startSurvey) {
            this.setState({ showStartSurveyMessage: !this.state.showStartSurveyMessage })
        }
        if (type === InformationType.endSurvey) {
            var campaign = this.state.campaign as unknown as Campaign;

            let newQuestions = null;
            let index = this.state.surveyIndex;
            let showStartSurveyMessage = this.state.showStartSurveyMessage
            let showEndSurveyMessage = this.state.showEndSurveyMessage
            if (campaign.surveys) {
                index++
                showEndSurveyMessage = true;
                if (index !== campaign.surveys.length) {
                    newQuestions = await getSurvey(this.props.match.params.campaignGuid, campaign.surveys[index].guid as string, this.props.match.params.profileGuid, parseInt(this.props.match.params.languageId));
                    showStartSurveyMessage = true;
                }
            }
            this.setState({ surveyQuestionAnswers: newQuestions, surveyIndex: index, showStartSurveyMessage, showEndSurveyMessage: false })
        }
    }

    increaseSurveyIndex = async () => {
        var campaign = this.state.campaign as unknown as Campaign;

        var index = this.state.surveyIndex + 1;
        if (campaign && campaign.surveys) {
            if (campaign.surveys[index]) {
                var newQuestions = await getSurvey(this.props.match.params.campaignGuid, campaign.surveys[index].guid as string, this.props.match.params.profileGuid, parseInt(this.props.match.params.languageId));
                const showEndSurveyMessage = true;
                const showStartSurveyMessage = true;
                this.setState({ surveyIndex: index, surveyQuestionAnswers: newQuestions, showEndSurveyMessage, showStartSurveyMessage })
            }
        }
        this.setState({ surveyIndex: index, surveyQuestionAnswers: null })
    }

    render() {
        const { surveyQuestionAnswers, campaign, showStartCampaignMessage, showStartSurveyMessage, showEndSurveyMessage, hasError, answerSending, profileLoading } = this.state;
        let campaignImage = Logo;

        if (campaign) {
            var notNullableCampaign = campaign as unknown as SurveyCampaign;
            if (notNullableCampaign?.base64StringPhotos && notNullableCampaign?.base64StringPhotos.length > 0) {
                campaignImage = notNullableCampaign?.base64StringPhotos[0]
            }
        }

        return (
            <>
                <div className="columns is-mobile profileImageWrapper">
                    <div className="column is-narrow profileImage">
                        <img src={campaignImage} />
                    </div>
                </div>
                <div className="columns is-centered is-mobile">

                    <ProfileSurveyComponent
                        campaign={campaign}
                        surveyIndex={this.state.surveyIndex}
                        increaseSurveyIndex={this.increaseSurveyIndex}
                        sendAnswer={this.onSubmitAnswer}
                        surveyQuestionAnswers={surveyQuestionAnswers}
                        showStartCampaignMessage={showStartCampaignMessage}
                        showStartSurveyMessage={showStartSurveyMessage}
                        showEndSurveyMessage={showEndSurveyMessage}
                        hasError={hasError}
                        toggleMessages={this.toggleMessages}
                        translatedLabels={this.state.translatedLabels}
                        emptyAllowed={this.props.match.params.allowEmpty ? this.props.match.params.allowEmpty.toLowerCase() === "allowempty" : false}
                        answerSending={answerSending}
                        profileLoading={profileLoading}
                        onPreviousQuestion={this.onPreviousQuestion}
                    />
                </div >
            </>
        )
    }

}

const mapStateToProps = (state: any): StateProps => {
    return ({
        selectedLanguageId: state.selectedLanguageId,
        i18n: state.i18n
    })
}

export default withRouter(connect(mapStateToProps, {})(ProfileSurveyContainer))


