import React from "react";
import { isatty } from "tty";
import { StatusType } from "../../types/model";
import ContentDivider from "../ContentDivider";
import classNames from 'classnames'
import { connect } from "react-redux";


interface OwnProps {
    onClickActive: (isActive: boolean) => void;
    onClickStatus: (status: StatusType) => void;
    status: StatusType;
    isActive: boolean
}

interface DispatchProps {
    i18n: any;
}

type Props = DispatchProps & OwnProps;

class GridviewStatusComponent extends React.Component<Props> {
    render() {
        const { onClickActive, onClickStatus, status, isActive, i18n } = this.props;


        var isSelectedActiveClass = "statusLink-active"
        var isSelectedStatusTypeClass = "statusFilteringItem-active"

        return (
            <>
                <div className="columns ml-0">
                    <div className={classNames("column is-narrow statusLink ", isActive ? isSelectedActiveClass : "")} onClick={() => onClickActive(true)}>
                        <a>{i18n.active}</a>
                    </div>
                    <div className={classNames("column is-narrow statusLink ", !isActive ? isSelectedActiveClass : "")} onClick={() => onClickActive(false)}>
                        <a>{i18n.archive}</a>
                    </div>
                    <ContentDivider width="100%" />
                </div>
                <div className="columns ml-0 pt-3">
                    <div className="column">
                        <div className="columns statusFiltering">
                            <div className={classNames("column is-narrow statusFilteringItem", StatusType.unknown == status ? isSelectedStatusTypeClass : "")} onClick={() => onClickStatus(StatusType.unknown)}>
                                <a>{i18n.all}</a>
                            </div>
                            {isActive && <>
                                <div className={classNames("column is-narrow statusFilteringItem", StatusType.ready == status ? isSelectedStatusTypeClass : "")} onClick={() => onClickStatus(StatusType.ready)}>
                                    <a>{i18n.ready}</a>
                                </div>
                                <div className={classNames("column is-narrow statusFilteringItem", StatusType.concept == status ? isSelectedStatusTypeClass : "")} onClick={() => onClickStatus(StatusType.concept)}>
                                    <a>{i18n.concept}</a>
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any, props: any): any => {
    return ({
        i18n: state.i18n
    })
}

export default connect(mapStateToProps, null)(GridviewStatusComponent)
