import React, { CSSProperties } from "react";
import { Styles } from "react-select";
import Logo from '../images/bvdg_logo.png';
import { DropdownOption } from "../types/model";
import LanguageSwitcher from "./InputComponents/LanguageSwitcher";

class Loadingcomponent extends React.Component {
    render() {
        var loader = require('../images/loader.gif').default;
        let languageOptions = [
            { value: "Nederland", label: "Nederland", icon: "nl" },
            { value: "Duitsland", label: "Duitsland", icon: "de" }
        ] as DropdownOption[];

        const customStyles = {
            option: (cssProps: CSSProperties) => ({
                ...cssProps,
                display: 'flex',
                alignItems: 'center'
            }),

            container: (cssProps: CSSProperties) => ({
                ...cssProps,
                width: 200,
                height: 50,
                float: 'right',
                marginTop: '30px',
                marginRight: '40px'
            }),

            indicatorSeparator: (cssProps: CSSProperties) => ({
                ...cssProps,
                width: '0px'
            })
        } as Partial<Styles<any, any, any>>;

        return (
            <div className="login">
                <div className="columns is-mobile">
                    <div className="column is-half logoContainer">
                        <img src={Logo} />
                    </div>
                    {/* <div className="column is-half">
                        <LanguageSwitcher
                            options={languageOptions}
                            customStyles={customStyles}
                        />
                    </div> */}
                </div>
                <div className="columns is-centered is-mobile">
                    <div className="column is-centered is-4">
                        <img src={loader} />
                    </div>
                </div>
                <div className="blueFooter">
                </div>
                <div className="redFooter">
                </div>
            </div>
        );
    }
}

export default (Loadingcomponent);

