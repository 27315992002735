import CMSApiBase from './CMSApiBase';
import { Profile, ProfileFilters } from '../../types/model';

const endpoint = 'profiles' as string;

class ProfileApi extends CMSApiBase {
    getProfiles(): Promise<Profile[]> {
        return this.doHttpRequest<Profile[]>('get', `${endpoint}`, null, false);
    }

    testProfileFilters(filters : ProfileFilters): Promise<number> {
        return this.doHttpRequest<number>('post', `${endpoint}/filters`, filters, false);
    }

}

export default new ProfileApi();