import React from "react";
import ContentDivider from "../components/ContentDivider";
import classNames from "classnames";
import PageHeader from "../components/PageHeader";
import { Language, CmsRole, User, CmsPolicy, CmsUser, EnableMFA } from "../types/model";
import { RouteComponentProps, withRouter } from "react-router-dom";
import AccountApi from "../api/Identity/Account";
import MFAForm from "../components/forms/MFAForm";
import { toast } from "react-toastify";


interface State {
    mfaImage: string,
    mfaCode: string
}


class MfaContainer extends React.Component<RouteComponentProps, State> {
    state = {
        mfaImage: '',
        mfaCode: ''
    }

    async componentDidMount() {
        var mfaImage = await AccountApi.enableMFAImage();
        var mfaCode = await AccountApi.enableMFAText();

        this.setState({
            mfaCode,
            mfaImage
        })
    }

    onSubmitCode = async (value: string) => {
        var mfa = { code: value } as EnableMFA;
        await AccountApi.enableMFAPost(mfa).then(async e => {
            toast.success("2FA geactiveerd");
            localStorage.setItem('mfaEnabled', 'true')
            this.props.history.push({ pathname: `/CMS` });
        }).catch((e => {
            toast.error("2FA niet geactiveerd");
        }));

    }

    render() {
        const { mfaCode, mfaImage } = this.state;

        return (
            <>
                <PageHeader
                    title={`Multi-factor authentication`}
                    showLanguageSwitcher={false}
                />
                <div className="columns componentWrapper">
                    <div className={classNames("column")}>
                        <div>QR-Code:</div>
                        <img src={`data:image/jpeg;base64, ${mfaImage}`} width={320} height={320} />

                        <div>Text-Code:</div>
                        <div>{mfaCode}</div>

                    </div>
                    <div className={classNames("column")}>
                        <div>
                            <MFAForm onSubmit2FA={this.onSubmitCode} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


export default withRouter(MfaContainer) as any;

