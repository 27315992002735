


import React from "react";
import { connect } from "react-redux";

interface OwnProps {
  amountOfResults: number;
  pageCount: number;
  goToPage: (index: number) => void;
  currentPage: number;
}

interface DispatchProps {
  i18n : any
}

type Props = OwnProps & DispatchProps

class GridviewFooterComponent extends React.Component<Props> {
  render() {
    const { amountOfResults, currentPage, goToPage, pageCount, i18n } = this.props;


    return (
      <div className="columns mt-3 mb-3 mr-0">
        <div className="column is-3">
          {amountOfResults} {i18n.results}
            </div>
        <div className="column is-flex is-justify-content-flex-end">
          <div className="columns">

            {currentPage != 1 && <><div onClick={() => goToPage(0)} className="column is-narrow pageNumber">
              <a>1</a>
            </div>
              <div className="pageNumberDeiider">...</div>
            </>
            }
            {currentPage > 2 && <div onClick={() => goToPage(currentPage - 2)} className="column is-narrow pageNumber">
              <a>{currentPage - 1}</a>
            </div>
            }
            {(pageCount > 1) && <div onClick={() => goToPage(currentPage - 1)} className="column is-narrow pageNumber">
              <a>{currentPage}</a>
            </div>
            }
            {(pageCount > 1 && currentPage < (pageCount - 1) || (pageCount === 2 && currentPage === 1)) && <div onClick={() => goToPage(currentPage)} className="column is-narrow pageNumber">
              <a>{currentPage + 1}</a>
            </div>
            }
            {
              pageCount > 2 && pageCount != currentPage && <>
                <div className="pageNumberDeiider">...</div>
                <div onClick={() => goToPage(pageCount - 1)} className="column is-narrow pageNumber">
                  <a>{pageCount}</a>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any, props: any): any => {
  return ({
      i18n: state.i18n
  })
}

export default connect(mapStateToProps, null)(GridviewFooterComponent)
