import axios, { AxiosRequestConfig, Method } from "axios";
import { CustomWindow } from "../../types";
import userManager from "../../utils/userManager";
import * as Sentry from "@sentry/react";

type Header = Record<string, string>

const customWindow = window as CustomWindow;


class IdentityApiBase {
    public async doHttpRequest<TResponse>(
        method: string,
        endpoint: string,
        data: {} | PromiseLike<{}> | null = null,
        isSingleItem?: boolean,
        contentType: string = "application/json",
    ): Promise<TResponse> {
        const access_token = (await userManager.getUser())?.access_token
        const headers: Header = {};
        headers["Content-Type"] = contentType;
        headers["Authorization"] = `Bearer ${access_token}`;

        const baseUrl = customWindow.settings.identityBaseUrl;
        const url = `${baseUrl}/api/${endpoint}`;

        const config: AxiosRequestConfig = { method: (method as Method), url, headers, data };
        const response = await axios.request<TResponse>(config)
            .catch(ex => {Sentry.captureException(ex);throw ex;}) as any;

        return isSingleItem ? response.data[0] : response.data;
    }
}

export default IdentityApiBase;
