import * as React from "react";
import { ProfileSurveyPage } from "../pages/ProfileSurvey";
import { Switch } from "react-router-dom";
import RouteSurveyComponent from "./RouteSurveyComponent";
import NotFoundComponent from "./NotFoundComponent";
import { UnsubscribeProfilePage } from "../pages/UnsubscribeProfile";
import { getCountries } from "../actions/Survey/languageActions";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";


const mapDispatchToProps = (dispatch: ThunkDispatch<any, {}, AnyAction>) => ({
    getCountries: () => dispatch(getCountries()),
});

type Props = ReturnType<typeof mapDispatchToProps>;


const RouterWrapper: React.FunctionComponent<Props> = ({ getCountries }) => {
    React.useEffect(() => {
        getCountries();
    }, []);
    return (
        <Switch>
            <RouteSurveyComponent exact={true} path={[`/Unsubscribe/:profileGuid/:languageId`,]} component={UnsubscribeProfilePage} />
            <RouteSurveyComponent exact={true} path={[`/Campagne/:campaignGuid/:profileGuid/:languageId/:allowEmpty?`,]} component={ProfileSurveyPage}/>
            <RouteSurveyComponent path={['/']} component={NotFoundComponent} />
        </Switch>
    );
}

export default connect(null, mapDispatchToProps)(RouterWrapper);
