import React from "react";
import { RouteProps, Route } from "react-router-dom";
import Logo from '../images/bvdg_logo.png';


const RouteSurveyComponent = ({ component, ...rest }: RouteProps) => {
    return (
        <div>
            <Route {...rest} component={component} />
            <div className="blueFooter">
            </div>
            <div className="redFooter">
            </div>
        </div>
    );
}

export default RouteSurveyComponent;


