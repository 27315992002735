import React from "react";
import CMSRouterWrapper from "../components/CMSRouterWrapper";
import SurveyRouterWrapper from "../components/SurveyRouterWrapper";
import { BrowserRouter } from 'react-router-dom'
import { Router, Route, Switch } from "react-router";
import CallbackComponent from "../components/CallbackComponent";

export default class AppContainer extends React.Component {

    render() {
        return (
            <>
                <BrowserRouter>
                    <Switch>
                        <Route exact path='/callback' component={CallbackComponent} />                        
                        <Route path='/cms' component={CMSRouterWrapper} />
                        <Route path='/' component={SurveyRouterWrapper} />
                    </Switch>
                </BrowserRouter>
            </>
        );
    }
}


