import React from "react";
import { connect } from "react-redux";
import GridviewWithFilteringComponent from '../components/GridviewWithFilteringComponent'
import { ThunkDispatch } from "redux-thunk";
import { constants } from "buffer";
import ComponentHeader from "../components/ComponentHeader";
import Gridview from "../components/gridview/GridviewComponent";
import ContentDivider from "../components/ContentDivider";
import classNames from "classnames";
import PageHeader from "../components/PageHeader";
import { CmsRoleColumns, CmsUserColumns, LanguageColumns } from "../constants/tableColumns";
import LanguageForm from "../components/forms/LanguageForm";
import { Language, CmsRole, User, CmsPolicy, CmsUser, IdentityProfile, Policies } from "../types/model";
import { toast } from "react-toastify";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { editLanguage, getLanguages } from "../actions/CMS/languageActions";
import { RootState } from "../types/state";
import { AnyAction } from "redux";
import CmsUserForm from "../components/forms/CmsUserForm";
import CmsRoleForm from "../components/forms/CmsRoleForm";
import { createRole, deleteRole, getPolicies, getRoles, updateRole } from "../actions/CMS/cmsRoleActions";
import { createUser, deleteUser, getUsers, updateUser } from "../actions/CMS/cmsUserActions";
import NoAccessComponent from "../components/NoAccessComponent";


interface State {
    showLanguage: boolean,
    selectedLanguageId: number | null,
    selectedCmsUserId: number | null,
    selectedCmsRoleId: number | null,
    showCmsUserModal: boolean,
    showCmsRoleModal: boolean,
    showLanguageModal: boolean,
    cmsUsers: CmsUser[],
    cmsRoles: CmsRole[],
    defaultPolicies: CmsPolicy[]
}

interface OwnProps {
    languages: Language[]
    profile: IdentityProfile
    i18n: any
}

interface DispatchProps {
    getLanguages: () => void;
}

type Props = RouteComponentProps & OwnProps & DispatchProps;

class SettingsContainer extends React.Component<Props, State> {
    state = {
        showLanguage: false,
        selectedLanguageId: null,
        selectedCmsUserId: null,
        selectedCmsRoleId: null,
        showLanguageModal: false,
        showCmsUserModal: false,
        showCmsRoleModal: false,
        cmsUsers: [] as CmsUser[],
        cmsRoles: [] as CmsRole[],
        defaultPolicies: [] as CmsPolicy[]
    }

    async componentDidMount() {
        this.props.getLanguages();
        var users = await getUsers();
        var cmsRoles = await getRoles(this.props.languages);
        var defaultPolicies = await getPolicies();

        var cmsUsers = users.map(x => ({ ...x, role: cmsRoles.find(q => q.id == x.roleId)?.name } as CmsUser));

        this.setState({
            showLanguage: false,
            cmsUsers,
            cmsRoles,
            defaultPolicies
        })
    }

    onTaalRowClick = (row: any) => {
        this.setState({ selectedLanguageId: row.original.id, showLanguageModal: !this.state.showLanguageModal })
    }

    onUserRowClick = (row?: any) => {
        {
            row ?
                this.setState({ selectedCmsUserId: row.original.id, showCmsUserModal: !this.state.showCmsUserModal })
                :
                this.setState({ showCmsUserModal: !this.state.showCmsUserModal })
        }
    }

    onRoleRowClick = (row?: any) => {
        {
            row ?
                this.setState({ selectedCmsRoleId: row.original.id, showCmsRoleModal: !this.state.showCmsRoleModal })
                :
                this.setState({ showCmsRoleModal: !this.state.showCmsRoleModal })
        }
    }

    onClickActive = (isActive: boolean) => { this.setState({ showLanguage: isActive }) }

    closeLanguageModal = () => {
        this.setState({ selectedLanguageId: null, showLanguageModal: !this.state.showLanguageModal })
    }

    closeCmsUserModal = () => {
        this.setState({ selectedCmsUserId: null, showCmsUserModal: !this.state.showCmsUserModal })
    }

    closeCmsRoleModal = () => {
        this.setState({ selectedCmsRoleId: null, showCmsRoleModal: !this.state.showCmsRoleModal })
    }

    onSubmitLanguage = async (values: any) => {
        const { i18n } = this.props;
        var language = values.language as Language;
        language.languageTexts = values.translations;

        await editLanguage(language).then(e => {
            toast.success(i18n.languageHasBeenUpdated);
            this.props.getLanguages();
        }).catch((e => {
            toast.error(i18n.languageHasNotBeenUpdated);
        }))

        this.setState({ selectedLanguageId: null, showLanguageModal: !this.state.showLanguageModal })
    }

    onSubmitCmsUser = async (values: User) => {
        const { i18n } = this.props;

        if (values.id)
            await updateUser(values).then(async e => {
                toast.success(i18n.userHasBeenUpdated);
                var users = await getUsers();
                var cmsRoles = await getRoles(this.props.languages);
                var cmsUsers = users.map(x => ({ ...x, role: cmsRoles.find(q => q.id == x.roleId)?.name } as CmsUser));
                this.setState({ cmsUsers });
            }).catch((e => {

                toast.error(i18n.userHasNotBeenUpdated);
            }));
        else
            await createUser(values).then(async e => {
                toast.success(i18n.userIsCreate);
                var users = await getUsers();
                var cmsRoles = await getRoles(this.props.languages);
                var cmsUsers = users.map(x => ({ ...x, role: cmsRoles.find(q => q.id == x.roleId)?.name } as CmsUser));
                this.setState({ cmsUsers });
            }).catch((e => {

                toast.error(i18n.userIsNotCreated);
            }));

        this.setState({ selectedCmsUserId: null, showCmsUserModal: !this.state.showCmsUserModal })
    }

    onSubmitCmsRole = async (values: CmsRole) => {
        const { i18n } = this.props;
        if (values.id)
            await updateRole(values).then(async e => {
                toast.success(i18n.roleHasBeenUpdated);

                var cmsRoles = await getRoles(this.props.languages);
                this.setState({ cmsRoles });
            }).catch((e => {
                toast.error(i18n.roleHasNotBeenUpdated);
            }));
        else
            await createRole(values).then(async e => {
                toast.success(i18n.roleIsCreated);
                var cmsRoles = await getRoles(this.props.languages);
                this.setState({ cmsRoles });
            }).catch((e => {

                toast.error(i18n.roleIsNotCreated);
            }));

        this.setState({ selectedCmsRoleId: null, showCmsRoleModal: !this.state.showCmsRoleModal })
    }

    onRemoveUser = async (e: any, row: any) => {
        const { i18n } = this.props;

        var id = row.original.id;
        await deleteUser(id).then(async e => {
            toast.success(i18n.userIsDeleted);
            var users = await getUsers();
            var cmsRoles = await getRoles(this.props.languages);
            var cmsUsers = users.map(x => ({ ...x, role: cmsRoles.find(q => q.id == x.roleId)?.name } as CmsUser));
            this.setState({ cmsUsers });
        }).catch((e => {

            toast.error(i18n.userIsNotDeleted);
        }));
    }

    onRemoveRole = async (e: any, row: any) => {
        const { i18n } = this.props;
        var id = row.original.id;
        await deleteRole(id).then(async e => {
            toast.success(i18n.roleIsDeleted);
            var cmsRoles = await getRoles(this.props.languages);
            this.setState({ cmsRoles });
        }).catch((e => {

            toast.error(i18n.roleIsNotDeleted);
        }));

    }

    render() {
        const { languages, i18n } = this.props;
        const { cmsRoles, cmsUsers, showLanguage, showLanguageModal, showCmsUserModal, showCmsRoleModal, selectedLanguageId, selectedCmsUserId, selectedCmsRoleId, defaultPolicies } = this.state;

        var isSelectedActiveClass = "statusLink-active";

        const claims = this.props.profile ? this.props.profile.roles[0].claims : [];
        const isEditable = claims.includes(Policies.SettingsEdit);
        const isDeletable = claims.includes(Policies.SettingsDelete);
        var isValid = claims.some((c: any) => [Policies.SettingsView, Policies.SettingsEdit, Policies.SettingsDelete].includes(c))

        var language = languages.find(l => l.id == selectedLanguageId)
        var cmsUser = cmsUsers.find(u => u.id == selectedCmsUserId)
        var cmsRole = cmsRoles.find(u => u.id == selectedCmsRoleId)

        var defaultPoliciesLanguage = languages.map(q => ({ enabled: false, name: q.languageText, type: 'Language', id: q.id } as CmsPolicy));

        var settingContent = !showLanguage ?
            <div>
                {showCmsUserModal && <CmsUserForm cmsUser={cmsUser}
                    cmsRoles={cmsRoles}
                    closeCmsUserModal={this.closeCmsUserModal}
                    onSubmitCmsUser={this.onSubmitCmsUser}
                />}
                <div className="column componentWrapper" style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <ComponentHeader title={i18n.users} textButton={isEditable ? i18n.addUser : null} onClickButton={() => { return this.onUserRowClick() }} />
                    {cmsUsers &&
                        <Gridview columns={CmsUserColumns(i18n)} data={cmsUsers} onRowClick={(e: any) => this.onUserRowClick(e)} deleteItem={isDeletable} onDeleteClick={this.onRemoveUser} />
                    }
                </div>

                {showCmsRoleModal && <CmsRoleForm
                    defaultPoliciesLanguage={defaultPoliciesLanguage}
                    defaultPolicies={defaultPolicies}
                    cmsRole={cmsRole}
                    closeCmsRoleModal={this.closeCmsRoleModal}
                    onSubmitCmsRole={this.onSubmitCmsRole}
                />}
                <div className="column componentWrapper" style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <ComponentHeader title={i18n.roles} textButton={isEditable ? i18n.addRoles : null} onClickButton={() => { return this.onRoleRowClick() }} />
                    {cmsRoles &&
                        <Gridview columns={CmsRoleColumns(i18n)} data={cmsRoles} onRowClick={(e: any) => this.onRoleRowClick(e)} deleteItem={isDeletable} onDeleteClick={this.onRemoveRole} />
                    }
                </div>
            </div>
            :
            <div>
                {showLanguageModal && <LanguageForm language={language}
                    closeLanguageModal={this.closeLanguageModal}
                    onSubmitLanguage={this.onSubmitLanguage}
                />}
                <div className="column componentWrapper" style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <ComponentHeader title={i18n.languages} />
                    {languages &&
                        <Gridview columns={LanguageColumns(i18n)} data={languages} onRowClick={(e: any) => this.onTaalRowClick(e)} />
                    }
                </div>
            </div>;

        return (
            isValid ?
                <>
                    <PageHeader
                        title={i18n.settings}
                        showLanguageSwitcher={false}
                    />
                    <div className="columns ml-0">
                        <div className={classNames("column is-narrow statusLink ", !showLanguage ? isSelectedActiveClass : "")} onClick={() => this.onClickActive(false)}>
                            <a>{i18n.usersAndRoles}</a>
                        </div>
                        <div className={classNames("column is-narrow statusLink ", showLanguage ? isSelectedActiveClass : "")} onClick={() => this.onClickActive(true)}>
                            <a>{i18n.languages}</a>
                        </div>
                        <ContentDivider width="100%" />
                    </div>
                    {settingContent}
                </> : <NoAccessComponent />
        );
    }
}

const mapStateToProps = (state: any): any =>
({
    languages: state.languages,
    i18n: state.i18n,
    profile: state.profile
})

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>) => ({
    getLanguages: () => dispatch(getLanguages())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsContainer)) as any;



