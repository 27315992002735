import React from 'react';
import IdentityApiBase from './IdentityApiBase';
import { Method } from 'axios';
import { IdentityProfile } from '../../types/model';


const endpoint = 'profile' as string;

class ProfileApi extends IdentityApiBase {
    get(userId: string = ''): Promise<IdentityProfile> {
        return this.doHttpRequest<IdentityProfile>('get', userId ? `${endpoint}/${userId}` : `${endpoint}`, null, false);
    }
    post(model: IdentityProfile, userId: string = ''): Promise<IdentityProfile> {
        return this.doHttpRequest<IdentityProfile>('post', userId ? `${endpoint}/${userId}` : `${endpoint}`, model, false);
    }
    picture(image: any): Promise<IdentityProfile> {
        return this.doHttpRequest<IdentityProfile>('post', `${endpoint}/ProfilePicture`, image, false, 'multipart/form-data');
    }
}

export default new ProfileApi();