import React from 'react';
import CMSApiBase from './CMSApiBase';
import { Method } from 'axios';
import { Country, IEntity, Language, Survey } from '../../types/model';

const endpoint = 'languages' as string;

class LanguageApi extends CMSApiBase {
    getLanguages(): Promise<Language[]> {
        return this.doHttpRequest<Language[]>('get', `${endpoint}`, null, false);
    }
    getLanguage(id : string): Promise<Language> {
        return this.doHttpRequest<Language>(`get`, `${endpoint}/${id}`, null, true);
    }
    editLanguage(language : Language) : Promise<any> {
        return this.doHttpRequest<Language>(`put`, `${endpoint}/${language.guid}`, language, true);
    }
    getCountries(): Promise<Country[]> {
        return this.doHttpRequest<Country[]>('get', `${'countries'}`, null, false);
    }
}

export default new LanguageApi();