export const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')

export const enumToDropdown = (enumValues: any) => {
    return Object.keys(enumValues).map(e => {
        let key = parseInt(e);
        if (key !== 0) {
            return { label: enumValues[key], value: key }
        }
    }).filter(e => e)
}

export const enumToDropdownWithoutUnkown = (enumValues: any) => {
    return Object.keys(enumValues).map(e => {
        let key = parseInt(e);
        return { label: enumValues[key], value: key }
    })
}

export const getQuestionOfRank = (rank: any) => (
    rank ? rank.toString().substring(0, rank.toString().length - 2) : ""
)

export const isValidPostalCode = (value: string) => {
    const dutchPostalCodeRegex = /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/;
    return dutchPostalCodeRegex.test(value);
}
