import React from 'react';
import IdentityApiBase from './IdentityApiBase';
import { Method } from 'axios';
import { User } from '../../types/model';


const endpoint = 'user' as string;

class UserApi extends IdentityApiBase {
    get(userId?: string): Promise<User[]> {
        return this.doHttpRequest<User[]>('get', userId ? `${endpoint}/${userId}` : `${endpoint}`, null, false);
    }
    post(model: User): Promise<User> {
        return this.doHttpRequest<User>('post', `${endpoint}`, model, false);
    }
    put(userId: string, model: User): Promise<User> {
        return this.doHttpRequest<User>('put', `${endpoint}/${userId}`, model, false);
    }
    delete(userId: string): Promise<User> {
        return this.doHttpRequest<User>('delete', `${endpoint}/${userId}`, null, false);
    }
}

export default new UserApi();