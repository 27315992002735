import UserApi from "../../api/Identity/User"
import { User } from "../../types/model";

export const getUsers = async () => {
    return await UserApi.get();
}

export const updateUser = async (cmsUser: User) => {
    cmsUser = { ...cmsUser, userName: cmsUser.email }
    return await UserApi.put(cmsUser.id, cmsUser);
}

export const createUser = async (cmsUser: User) => {
    cmsUser = { ...cmsUser, userName: cmsUser.email }
    return await UserApi.post(cmsUser);
}

export const deleteUser = async (userId: string) => {
    await UserApi.delete(userId);
}