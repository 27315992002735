import React from "react";
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface Props {
    label?: string;
    editorState: EditorState;
    formValues: Event;
    onEditorStateChange: (editorState: EditorState, label?: string) => void;
    readOnly: boolean;
    input: HTMLInputElement;
}

class TextEditorComponent extends React.Component<Props> {
    onEditorChanged = (e:EditorState) => {
        this.onEditorChanged(e);
    };

    render() {
        const { label, editorState, onEditorStateChange, readOnly, input } = this.props;

        return (
            <>
                <label className="label">{label}</label>
                <Editor
                    editorState={editorState} 
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={(e:EditorState) => onEditorStateChange(e, input.name)}
                    readOnly={readOnly}
                />
            </>
        );
    }
}

export default TextEditorComponent;
