import React from "react";
import {  TranslatedLabels } from "../types/model";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getTranslatedWords } from "../actions/Survey/languageActions";
import { unsubscribeProfile } from "../actions/Survey/profileActions";
import UnsubscribeComponent from "../components/UnsubscribeComponent";

interface State {
    translatedLabels: TranslatedLabels | null
}

interface MatchParams {
    profileGuid: string
    languageId: string
}

type Props = RouteComponentProps<MatchParams>

class UnsubscribeProfileContainer extends React.Component<Props, State> {
    state = {
        translatedLabels: null
    }

    async componentDidMount() {
        var translatedWords = await getTranslatedWords(parseInt(this.props.match.params.languageId));

        await unsubscribeProfile(this.props.match.params.profileGuid);

        this.setState({
            translatedLabels: translatedWords
        })
    }

    render() {

        return (
            <UnsubscribeComponent 
            translatedLabels={this.state.translatedLabels} />
        );
    }
}


export default withRouter(UnsubscribeProfileContainer) as any;

