import React from 'react'
import PageHeader from './PageHeader'
import { Field } from 'redux-form';
import { TextInputComponent } from "./InputComponents/TextInputComponent";
import ContentDivider from './ContentDivider';
import ComponentHeader from './ComponentHeader';
import { DropdownComponent } from './InputComponents/DropdownComponent'
import { IdentityProfile, Policies, Survey } from '../types/model';
import { connect } from 'react-redux';
import required from '../validators/required';
import { RadioButtonComponent } from './InputComponents/RadioButtonComponent';


interface OwnProps {
    statusDropdownOptions: any
    isCampaign: boolean;
}

interface DispatchProps {
    i18n: any;
    profile: IdentityProfile
}

type Props = OwnProps & DispatchProps;

class GeneralComponent extends React.Component<Props> {
    render() {
        const claims = this.props.profile ? this.props.profile.roles[0].claims : [];
        const isEditable = this.props.isCampaign ? claims.includes(Policies.CampaignEdit) : claims.includes(Policies.QuestionnaireEdit);

        const { statusDropdownOptions, i18n } = this.props;
        return (
            <>
                <ComponentHeader title={i18n.general} />
                <div className="columns">
                    {/* <div className="column">
                        <Field
                            component={TextInputComponent}
                            readOnly={true}
                            labelInComponent={true}
                            type="text"
                            name="guid"
                            label={`${i18n.id}:`}
                        />
                    </div> */}
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            type="text"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                            labelInComponent={true}
                            name="code"
                            label={`${i18n.number}:`}
                        />
                    </div>
                    <div className="column">
                        <Field
                            component={DropdownComponent}
                            label={`${i18n.status}:`}
                            name="status"
                            validate={required}
                            labelInComponent={true}
                            readOnly={!isEditable}
                            required={true}
                            options={statusDropdownOptions}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            type="text"
                            name="title"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                            labelInComponent={true}
                            label={`${i18n.title}:`}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            type="text"
                            labelInComponent={true}
                            readOnly={!isEditable}
                            label={`${i18n.characteristic}:`}
                            name="characteristic"
                            required={true}
                            validate={required}
                        />
                    </div>
                </div>
                {
                    this.props.isCampaign && <div className="columns">
                        <div className="column">
                            <Field
                                component={TextInputComponent}
                                type="text"
                                labelInComponent={true}
                                readOnly={!isEditable}
                                label="Mailchimp Template"
                                name="mailchimpTemplate"
                                required={true}
                                validate={required}
                            />
                        </div>
                    </div>
                }
                {
                    this.props.isCampaign && 
                    
                        <div className="columns">
                            <div className='column'>
                                <div className={"formGroup"} style={{minHeight: '60px'}}>
                                    <label>{`${i18n.ProfileCampaign}`}</label>
                                    <div style={{display: 'flex'}}>
                                        <div className='rows'>
                                            <div className='row'>
                                            <div className='columns'>
                                                <div className='column' style={{minWidth: '100px', marginLeft: '20px'}}>
                                            <Field 
                                                component={RadioButtonComponent}
                                                type="radio"
                                                labelInComponent={true}
                                                readOnly={!isEditable}
                                                label={i18n.Yes}
                                                value={"yes"}
                                                name="isProfileCampaign"
                                            />
                                                </div>
                                                <div className='column' style={{minWidth: '100px', marginLeft: '20px'}}>
                                            <Field 
                                                component={RadioButtonComponent}
                                                type="radio"
                                                labelInComponent={true}
                                                readOnly={!isEditable}
                                                label={i18n.No}
                                                value={"no"}
                                                name="isProfileCampaign"
                                            />
                                            </div>
                                            </div>
                                            </div>
                                        </div>  
                                    </div>

                                </div>
                            </div>
                        </div>
                }
            </>
        )
    }
}


const mapStateToProps = (state: any, props: any): any => {
    return ({
        i18n: state.i18n,
        profile: state.profile
    })
}

export default connect(mapStateToProps, null)(GeneralComponent)

// export default connect( GeneralComponent
