import { ActionCreator } from 'redux';
import { loadUser } from 'redux-oidc';
import { ThunkAction } from 'redux-thunk';
import userManager from '../utils/userManager';
import { User } from 'oidc-client';
import { RootState } from "../types/state";
import { store } from '../store/store';
import { InitializeAction } from '../types/actionTypes/applicationActionTypes';
import * as actionTypes from "../types/actionTypes/applicationActionTypes";
import AccountApi from "../api/Identity/Account";

export const initialize: ActionCreator<ThunkAction<Promise<any>, RootState, {}, InitializeAction>> = () => async (dispatch) => {
    // Get the user from local storage.
    let user: User | undefined;

    try {
        user = await loadUser(store, userManager.manager);
    }
    catch (err) {
        console.error(err);
        // Failed to retrieve a user from local storage.
        userManager.manager.signinRedirect();
        return;
    }

    if (!user) {
        // No existing user found, redirect to login page.
        userManager.manager.signinRedirect();
        return;
    }

    if (user.expired) {
        // User found but the token is expired. Attempt a silent login.
        try {
            await userManager.manager.signinSilent();
        }
        catch (err) {
            // Silent log in failed, redirect to login page.
            userManager.manager.signinRedirect();
            return;
        }
    }

    var isMfaEnabled = await AccountApi.isMFAEnabled();
   
    localStorage.setItem("mfaEnabled", isMfaEnabled ? 'true' : 'false');
    localStorage.setItem("token", user.access_token);

    return dispatch({ type: actionTypes.SET_INITIALIZED, initialized: true });
};
