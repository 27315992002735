import React from "react"
import { Input, CustomInput } from "reactstrap"


export const Filter = ({ column }: any) => {
    return (
        <div style={{ marginTop: 5 }}>
            {column.canFilter && column.render("Filter")}
        </div>
    )
}

export const DefaultColumnFilter = ({
    column: {
        filterValue,
        setFilter,
        preFilteredRows: { length },
    } }: any) => {
    return (
        <Input
            value={filterValue || ""}
            // style={{width: '30px'}}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        />
    )
}

export const SelectColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id } }: any, i18n: any, enumType: any) => {

    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row: any) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    return (
        <CustomInput
            id='custom-select'
            type='select'
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        >
            
            <option value=''>{i18n.all}</option>
            {options.map((option) => (
                <option key={option as any} value={option as any}>
                    {enumType[option as any] as any}
                </option>
            ))}
        </CustomInput>
    );
};



export const enumFilter = (rows: any, filter: any, enumValues: any) => {
    return rows.filter((r: any) => r._original.alwaysVisible || ((r[filter.id] || r[filter.id] === 0) &&
        (
            enumValues[r[filter.id]].toString()
                .toLowerCase()
                .indexOf(filter.value.toLowerCase()) !== -1
        ))
    )
}