import React from 'react';
import SurveyApiBase from './SurveyApiBase';
import { Method } from 'axios';
import { Campaign, Country, IEntity, Language, Survey, SurveyQuestionAnswer, TranslatedLabels } from '../../types/model';
import * as Sentry from "@sentry/react";

const endpoint = 'languagetranslations' as string;

class LanguageApi extends SurveyApiBase {
    getLabels(languageId: number): Promise<TranslatedLabels> {
        return this.doHttpRequest<TranslatedLabels>('get', `${endpoint}/${languageId}`, null, false)
            .catch(ex => {Sentry.captureException(ex);throw ex;}) as any;
    }
    getCountries(): Promise<Country[]> {
        return this.doHttpRequest<Country[]>('get', `${'countries'}`, null, false)
            .catch(ex => {Sentry.captureException(ex);throw ex;}) as any;
    }
}

export default new LanguageApi();