import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import classnames from 'classnames';
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from "date-fns/locale/nl";
import moment from 'moment';
registerLocale("nl", nl);

interface Props {
    placeholder?: string;
    label?: string;
    readOnly?: boolean
    type?: string;
    showSideBySide?: boolean;
    required?: boolean;
    showTimeSelect?: boolean;
    dateFormat?: string
}

export default class DatePickerComponent extends React.Component<WrappedFieldProps & Props> {
    onChange = (newDate: Date) => {
        this.props.input.onChange(newDate ? moment(newDate) : "");
    }

    render() {
        const { input, label, required, meta: { touched, error }, showSideBySide, dateFormat, readOnly } = this.props;
        const format = dateFormat || "dd-MM-yyyy";

        let style = {};

        return (
            <div>
                {label && <div className="field-label" style={{ display: 'flex' }}><label className="label is-pulled-left" htmlFor={input.name} style={style}>{label} {required && <span style={{ color: "red" }}>*</span>}</label></div>}
                <div className="field-body">
                    <ReactDatePicker
                        selected={input.value ? new Date(input.value) : null}
                        name={input.name}
                        className={classnames("input", touched && error && "is-danger")}
                        dateFormat={format}
                        dateFormatCalendar={format}
                        readOnly={readOnly}
                        timeCaption="Tijdstip"
                        timeIntervals={15}
                        autoComplete="off"
                        onChange={this.onChange}
                        showTimeSelect={this.props.showTimeSelect ? this.props.showTimeSelect : false}
                        strictParsing={true}
                        disabled={readOnly}
                        todayButton={"Vandaag"}
                    />
                    {touched && error && <p className="help is-danger">{error}</p>}
                </div>
            </div>
        );
    }
} 