import { createStore, applyMiddleware, compose } from "redux";
import { rootReducer } from "../reducers";
import thunk from "redux-thunk";
import createOidcMiddleware from 'redux-oidc';
import userManager from "../utils/userManager";

const oidcMiddleware = createOidcMiddleware(userManager.manager);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(oidcMiddleware, thunk)),
);