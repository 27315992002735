import { Country, Language } from "../types/model";
import * as languageTypes from '../types/actionTypes/languageType';

const initalLanguageState = [] as Language[]
const initalLanguageIdState = 1 as number
const initalCountryState = [] as Country[]

type LanguageState = typeof initalLanguageState
type LanguageIdState = typeof initalLanguageIdState
type CountryState = typeof initalCountryState


export const languagesReducer = (state: LanguageState = initalLanguageState, action: languageTypes.GetLanguageAction) => {
    switch (action.type) {
        case languageTypes.GET_LANGUAGES:
            return action.languages;
        default:
            return state;
    }
};

export const languageIdReducer = (state: LanguageIdState = initalLanguageIdState, action: languageTypes.SetSelectedLanguageId) => {
    switch (action.type) {
        case languageTypes.SET_LANGUAGE_ID:
            return action.languageId;
        default:
            return state;
    }
};

export const countriesReducer = (state: CountryState = initalCountryState, action: languageTypes.GetCountyAction) => {
    switch (action.type) {
        case languageTypes.GET_COUNTRIES:
            return action.countries;
        default:
            return state;
    }
};