import React from "react";
import { connect } from "react-redux";
import GridviewWithFilteringComponent from '../components/GridviewWithFilteringComponent'
import { ThunkDispatch } from "redux-thunk";
import { constants } from "buffer";
import SurveyApi from '../api/CMS/Survey'
import { Campaign, IdentityProfile, Policies, Survey } from '../types/model';
import { CampaignsColumns, SurveysColumns } from '../constants/tableColumns'
import { useHistory } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { cwd } from "process";
import { getCampaigns } from '../actions/CMS/campaignActions'
import { RouteComponentProps } from "react-router";
import NoAccessComponent from "../components/NoAccessComponent";

interface State {
    campaigns: Campaign[],
}

interface MatchParams {
    id: string
}

interface DispatchProps {
    i18n: any
    profile: IdentityProfile
}

type Props = DispatchProps & RouteComponentProps<MatchParams>

class CampaignsContainer extends React.Component<Props, State> {
    state = {
        campaigns: []
    }

    onRowClick = (row: any) => {
        var guid = row.original.guid
        this.props.history.push({
            pathname: `/CMS/Campagnes/${guid}`
        });
    }

    async componentDidMount() {
        var campaigns = await getCampaigns();

        this.setState({
            campaigns
        })
    }

    render() {
        const { campaigns } = this.state;
        const { i18n } = this.props;

        const claims = this.props.profile ? this.props.profile.roles[0].claims : [];
        const isEditable = claims.includes(Policies.CampaignEdit);
        var isValid = claims.some((c: any) => [Policies.CampaignView, Policies.CampaignEdit, Policies.CampaignDelete].includes(c))

        return (
            isValid ?
                <GridviewWithFilteringComponent
                    columns={CampaignsColumns(i18n)}
                    data={campaigns}
                    routeName={"Campagnes"}
                    title={i18n.campaigns}
                    disableButton={!isEditable}
                    buttonName={i18n.createCampaign}
                    onRowClick={this.onRowClick}
                /> : <NoAccessComponent />
        );
    }
}

const mapStateToProps = (state: any, props: any): any => {
    return ({
        i18n: state.i18n,
        profile: state.profile

    })
}

export default withRouter(connect(mapStateToProps, null)(CampaignsContainer))


