import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import classnames from 'classnames';
import Select, { OptionsType } from 'react-select';
// import { OptionsType } from 'react-select/lib/types';

interface Props {
    options: OptionsType<{ label: string; value: string }>;
    label?: string;
    readOnly?: boolean
    showSideBySide?: boolean;
    style?: React.CSSProperties;
    isMulti?: boolean
    labelInComponent?: boolean
    clearable: boolean;
    onValueChanged?: (value: any) => void;
    required: boolean;
    isBorderError?: boolean
}

export type DropdownProps = WrappedFieldProps & Props;

export const DropdownComponent = (props: DropdownProps) => {
    const { options, labelInComponent, isMulti, input, label, style, meta: { touched, error }, required, clearable = false, showSideBySide, readOnly, onValueChanged, isBorderError } = props;

    var value = options ? (options.find((o: { value: string, label: string }) => o?.value?.toString() == input.value.toString()) ?? null) : null;

    const styles = {
        control: (base: any) => ({
            ...base,
            border: 0,
            boxShadow: 'none'
        })
    };

    var reactSelect = <Select
        className="field single-select"
        classNamePrefix="react-select"
        menuPlacement="auto"
        maxMenuHeight={400}
        options={options}
        styles={styles}
        isDisabled={readOnly}
        isSearchable={true}
        value={isMulti ? props.input.value : value}
        isMulti={isMulti}
        isClearable={clearable}
        defaultValue = {options && options[0]}
        onChange={(option: any) => {
            if (isMulti)
                props.input.onChange(option)
            else
                input.onChange(option ? option.value : "")
            if (onValueChanged)
                onValueChanged(option ? option.value : "");
        }}
        onBlur={() => isMulti ? props.input.onBlur(props.input.value) : () => { }}
    />

    var inputComponent = labelInComponent ? <div className="formGroup">
        <label>{label}</label>
        {reactSelect}
    </div> :
        <>{reactSelect}</>
    return (
        <>
            {/* {<div className="field-label is-normal"><label className="label is-pulled-left" htmlFor={input.name} >{required && <span style={{ color: "red" }}></span>}</label></div>} */}
            <div className="field-body" style={{...style, border : isBorderError && touched && error ? '1px solid red' : '' }}>
                <div className="field" >
                    {inputComponent}
                </div>
            </div>
        </>
    );
}

