import moment from 'moment'
import { zeroPad } from './commonHelper';

export const showDateTime = (date: Date): string => {
    var newDate = moment(date);

    if (!date)
        return "";
    return `${newDate.date()}-${newDate.month() + 1}-${newDate.year()}  ${zeroPad(newDate.hour(), 2)}:${zeroPad(newDate.minute(), 2)}`;
}

export const showDate = (date: Date): string => {
    var newDate = moment(date);

    if (!date)
        return "";
    return `${newDate.date()}-${newDate.month() + 1}-${newDate.year()}`;
}