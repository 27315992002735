import React from 'react';
import { Question, QuestionType, TableColumn } from '../types/model'
import { StatusType } from '../types/model';
import { showDate, showDateTime } from '../helpers/timeHelper';
import { SelectColumnFilter, DefaultColumnFilter, enumFilter } from '../components/gridview/filter/columnFilter';
import { useColumnOrder } from 'react-table';
import ArrowIcon from '../images/icons/arrow-forward.svg'
import { Field } from 'redux-form';
import { TextInputComponent } from '../components/InputComponents/TextInputComponent';
import { DropdownComponent } from '../components/InputComponents/DropdownComponent';
import required from '../validators/required';
import integer from '../validators/integer';
import { getQuestionOfRank } from '../helpers/commonHelper';

export const CampaignsColumns = (i18n: any): TableColumn[] => [
    // {
    //     Header: i18n.id,
    //     accessor: "id",
    // },
    {
        Header: i18n.number,
        accessor: "code",
    },
    {
        Header: i18n.title,
        accessor: "title",
    },
    {
        Header: i18n.characteristic,
        accessor: "characteristic",
    },
    {
        Header: i18n.lists,
        accessor: "",
        Cell: (props: any) => props.data[props.row.index].surveys.length,
    },
    {
        Header: i18n.status,
        accessor: "status",
        Cell: (props: any) => i18n.statusTypeEnum[props.value],
        Filter: (props: any) => SelectColumnFilter(props, i18n, i18n.statusTypeEnum),
    },
    {
        Header: i18n.createdOn,
        accessor: (props: any) => {
            return showDateTime(props.createdOn)
        },
    },
    {
        // Header: i18n.updatedOn,
        Header: i18n.lastUpdated,
        accessor: (props: any) => {
            return showDateTime(props.updatedOn)
        },
    },
    {
        Header: "",
        accessor: "edit",
        disableFilters: true,
        Cell: () => <img src={ArrowIcon} />
    },
] as TableColumn[];

export const SurveysColumns = (i18n: any): TableColumn[] => [
    // {
    //     Header: i18n.id,
    //     accessor: "id"
    // },
    {
        Header: i18n.number,
        accessor: "code",
    },
    {
        Header: i18n.characteristic,
        accessor: "characteristic",
    },
    {
        Header: i18n.questions,
        accessor: "numberOfQuestions",
    },
    {
        Header: i18n.status,
        accessor: 'status',
        Cell: (props: any) => i18n.statusTypeEnum[props.value],
        Filter: (props: any) => SelectColumnFilter(props, i18n, i18n.statusTypeEnum),
    },
    {
        Header: i18n.createdOn,
        accessor: (props: any) => {
            return showDateTime(props.createdOn)
        },
    },
    {
        Header: i18n.lastUpdated,
        accessor: (props: any) => {
            return showDateTime(props.updatedOn)
        },
    },
    {
        Header: "",
        accessor: "edit",
        disableFilters: true,
        Cell: () => <img src={ArrowIcon} />
    },
] as TableColumn[];


export const questionsColumns = (i18n: any) => [
    // {
    //     Header: i18n.id,
    //     accessor: "guid",
    // },
    {
        Header: i18n.number,
        accessor: "rank",
        Cell: (props: any) => getQuestionOfRank(props.value)
    },
    {
        Header: i18n.question,
        accessor: "description",
    },
    {
        Header: i18n.questionType,
        accessor: "questionType",
        Filter: (props: any) => SelectColumnFilter(props, i18n, i18n.questionTypeEnum),
        Cell: (props: any) => i18n.questionTypeEnum[props.value],
    },
    {
        Header: i18n.status,
        accessor: "status",
        Filter: (props: any) => SelectColumnFilter(props, i18n, i18n.statusTypeEnum),
        Cell: (props: any) => i18n.statusTypeEnum[props.value],
    },
    {
        Header: i18n.createdOn,
        accessor: (props: any) => {
            return showDateTime(props.createdOn)
        },
    },
    {
        Header: i18n.lastUpdated,
        accessor: (props: any) => {
            return showDateTime(props.updatedOn)
        },
    },
    {
        Header: "",
        accessor: "edit",
        disableFilters: true,
        Cell: () => <img src={ArrowIcon} />
    },
] as TableColumn[];



// export const answersColumns = (questions: Question[], selectedRank: number | undefined, i18n : any): TableColumn[] => {
export const answersColumns = (dropdown: any, i18n: any, readOnly: boolean, hasMultipleAnswers: boolean): TableColumn[] => {
    const followUpQuestion = {
        Header: i18n.followUp,
        accessor: 'followUpQuestionGuid',
        className: "followUpQuestion",
        disableFilters: true,
        Cell: (props: any) => {
            return (
                <Field
                    component={DropdownComponent}
                    readOnly={readOnly || hasMultipleAnswers}
                    name={`answers[${props.row.index}].followUpQuestionGuid`}
                    options={dropdown}
                />
            )
        }
    }

    const answerColumns = [
        {
            Header: "#",
            accessor: "rank",
            Cell: (props: any) => {
                var rank = props?.data[props.row.index].rank.toString();
                var stripped = parseInt(rank.substring(rank.length - 2, rank.length))
                return (
                    <Field
                        component={TextInputComponent}
                        type="text"
                        useOwnValue={stripped}
                        name={`answers[${props.row.index}].rank`}
                        readOnly={true}
                    />)
            }
        },
        {
            Header: i18n.generalDescription,
            accessor: "generalDescription",
            Cell: (props: any) => {
                var isOwnAnswer = props?.data[props.row.index].isOwnAnswer;
                var isFilled = props?.data[props.row.index].generalDescription;
                return (<Field
                    component={TextInputComponent}
                    type="text"
                    required={true}
                    readOnly={isFilled || isOwnAnswer ? true : false || readOnly}
                    validate={required}
                    name={`answers[${props.row.index}].generalDescription`}
                />)
            }
        },
        {
            Header: i18n.answer,
            accessor: "answer",
            Cell: (props: any) => {
                var isOwnAnswer = props?.data[props.row.index].isOwnAnswer;
                return (<Field
                    component={TextInputComponent}
                    type="text"
                    required={true}
                    readOnly={readOnly}
                    validate={required}
                    name={`answers[${props.row.index}].answerText`}
                />)
            }
        },
        {
            Header: i18n.score,
            accessor: "score",
            Cell: (props: any) => {
                return (<Field
                    component={TextInputComponent}
                    type="number"
                    required={true}
                    readOnly={readOnly}
                    validate={[required, integer]}
                    name={`answers[${props.row.index}].score`}

                />)
            }
        },
    ]

    if(!hasMultipleAnswers)
        answerColumns.push(followUpQuestion)

    return answerColumns;
}

export const ProfilesColumns = (i18n: any) => [
    // {
    //     Header: i18n.id,
    //     accessor: "guid"
    // },
    // {
    //     Header: i18n.emailAddress,
    //     accessor: "emailAddress"
    // }, 
    {
        Header: i18n.zipCode,
        accessor: "postalCode",
        Cell: (props: any) => {
            if(props.value){
                return props.value.length >= 4 ? props.value.substring(0,4) : props.value
            }
            return ""
        }
    },
    {
        Header: i18n.age,
        accessor: "birthDate",
        Cell: (props: any) => {
            if(props.value){
                var birthDate = new Date(props.value);
                var ageDifMs = Date.now() - birthDate?.getTime();
                var ageDate = new Date(ageDifMs); // miliseconds from epoch
                return Math.abs(ageDate.getUTCFullYear() - 1970);
            }
            return ""
        }
    },
    {
        Header: i18n.country,
        accessor: "country"
    },
    {
        Header: i18n.countryOfBirth,
        accessor: "countryOfBirth"
    },
    {
        Header: i18n.gender,
        accessor: "gender",
        Filter: (props: any) => SelectColumnFilter(props, i18n, i18n.genderEnum),
        Cell: (props: any) => i18n.genderEnum[props.value],
    },
    {
        Header: i18n.maritalStatus,
        accessor: "maritalStatus",
        Filter: (props: any) => SelectColumnFilter(props, i18n, i18n.maritalStatusEnum),
        Cell: (props: any) => i18n.maritalStatusEnum[props.value],
    },
    {
        Header: i18n.income,
        accessor: "income",
        Filter: (props: any) => SelectColumnFilter(props, i18n, i18n.incomeEnum),
        Cell: (props: any) => i18n.incomeEnum[props.value],
    },
    {
        Header: i18n.smoking,
        accessor: "smoking",
        Filter: (props: any) => SelectColumnFilter(props, i18n, i18n.smokingEnum),
        Cell: (props: any) => i18n.smokingEnum[props.value],
    },
    {
        Header: i18n.diabetesDuration,
        accessor: "diabetesDuration",
        Cell: (props: any) => props.value + " " + i18n.year,
    },
    {
        Header: i18n.education,
        accessor: "education",
        Filter: (props: any) => SelectColumnFilter(props, i18n, i18n.educationEnum),
        Cell: (props: any) => i18n.educationEnum[props.value],
    },

    // {
    //     Header: i18n.maritalStatus,
    //     accessor: "status",
    //     Filter: (props: any) => SelectColumnFilter(props, i18n, i18n.statusTypeEnum),
    //     Cell: (props: any) => i18n.statusTypeEnum[props.value],
    // },
    // {
    //     Header: i18n.createdOn,
    //     accessor: (props: any) => {
    //         return showDateTime(props.createdOn)
    //     },
    // },
    // {
    //     Header: i18n.lastUpdated,
    //     accessor: (props: any) => {
    //         return showDateTime(props.updatedOn)
    //     },
    // },
] as TableColumn[];

export const LanguageColumns = (i18n: any): TableColumn[] => [
    {
        Header: i18n.language,
        accessor: "languageText"
    },
    {
        Header: i18n.languageEnglish,
        accessor: "languageTextEN"
    },
    {
        Header: i18n.languageOriginal,
        accessor: "originalLanguage"
    },
    {
        Header: i18n.icon,
        accessor: "locale",
        disableFilters: true,
        Cell: (props: any) => {
            var src;
            try {
                src = require('../images/icons/flags/' + props.value + '.png');
            } catch (ex: any) {
            }
            if (!src)
                return 'no image'

            return <img
                src={src.default}
                style={{ width: 36, paddingRight: '10px' }}
            />
        }

    },
    {
        Header: i18n.status,
        accessor: "isActive",
        Cell: (props: any) => {

            return props.value ? "Actief" : "Inactief";
        }
    }
] as TableColumn[];

export const CmsUserColumns = (i18n: any): TableColumn[] => [

    {
        Header: i18n.firstName,
        accessor: "firstName"
    },
    {
        Header: i18n.lastName,
        accessor: "surName"
    },
    {
        Header: i18n.emailAddress,
        accessor: "email"
    },
    {
        Header: i18n.role,
        accessor: "role"
    },
    {
        Header: "Status",
        accessor: "isActive",
        Cell: (props: any) => {

            return props.value ? "Actief" : "Inactief";
        }
    },
    {
        Header: i18n.createdOn,
        accessor: (props: any) => {
            return showDateTime(props.createdOn)
        },
    },
    {
        Header: i18n.lastUpdated,
        accessor: (props: any) => {
            return showDateTime(props.updatedAt)
        },
    }
] as TableColumn[];

export const CmsRoleColumns = (i18n: any) => [
    {
        Header: i18n.name,
        accessor: "name"
    }
    // {
    //     Header: "Aantal Gebruikers",
    //     accessor: "userCount"
    // }
] as TableColumn[];