import { Country, IdentityProfile, Language } from "../types/model";
import * as profileTypes from '../types/actionTypes/profileType';
import { GetProfileAction } from "../types/actionTypes/profileType";

const initalProfileState = null
type ProfileState = typeof initalProfileState


export const profileReducer = (state: ProfileState = initalProfileState, action: GetProfileAction) => {
    switch (action.type) {
        case profileTypes.GET_PROFILE:
            return action.profile;
        default:
            return state;
    }
};
