import React from "react";
import { connect } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm, change } from 'redux-form';
import { IdentityProfile, Language, Policies } from "../../types/model";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Modal } from '../Modal';
import { DropdownComponent } from '../InputComponents/DropdownComponent';
import ComponentHeader from "../ComponentHeader";
import { TextInputComponent } from "../InputComponents/TextInputComponent";
import required from "../../validators/required";
import { i18nReducer } from "../../reducers/i18nReducer";

interface OwnProps {
    language: Language | undefined;
    closeLanguageModal: () => void;
    onSubmitLanguage: (values: Language) => void;
}

interface MatchParams {
    id: string
}

interface DispatchProps {
    updateFormValue: (field: string, value: string) => any;
}

interface State {
    language: Language | undefined
}

interface StateProps {
    i18n: any;
    formValues: any;
    initialValues: any;
    profile : IdentityProfile
}

type Props = OwnProps & DispatchProps & StateProps & RouteComponentProps<MatchParams>;

const formName = "LanguageForm"
class LanguageForm extends React.Component<Props & InjectedFormProps<{}, any>, State> {
    constructor(props: Props & InjectedFormProps<{}, any>) {
        super(props);
        this.state = {
            language: props.language
        }
    }

    languageContent = () => {
        const { i18n, profile } = this.props;

        var languageStatusTypes = [
            { label: i18n.active, value: true },
            { label: i18n.inActive, value: false }
        ];
        
        const claims =this.props.profile ? this.props.profile.roles[0].claims : [];
        const isEditable = claims.includes(Policies.SettingsEdit);

        return (
            <>
                <ComponentHeader onClose={this.props.closeLanguageModal} title={i18n.languageStatus} isForm={true} textButton={i18n.save} />
                <div className="columns">
                    <div className="column is-8">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            name="language.guid"
                            type="text"
                            label={`${i18n.id}:`}
                            disabled={true}
                            readOnly={true}
                        />
                    </div>
                    <div className="column is-4">
                        <Field
                            component={DropdownComponent}
                            label={`${i18n.status}:`}
                            name="language.isActive"
                            required={true}
                            readOnly={!isEditable}
                            labelInComponent={true}
                            options={languageStatusTypes}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-8">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            name="language.languageText"
                            type="text"
                            label={`${i18n.language}:`}
                            disabled={true}
                            readOnly={true}
                        />
                    </div>
                    <div className="column is-4">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.abbreviation}:`}
                            type="text"
                            name="language.locale"
                            disabled={true}
                            readOnly={true}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-6">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            name="language.languageTextEN"
                            type="text"
                            label={`${i18n.languageEnglish}:`}
                            disabled={true}
                            readOnly={true}
                        />
                    </div>
                    <div className="column is-6">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.languageOriginal}:`}
                            type="text"
                            name="language.originalLanguage"
                            disabled={true}
                            readOnly={true}
                        />
                    </div>
                </div>
                <ComponentHeader title={i18n.labelTranslations} />
                <div className="columns">
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.startSurvey}:`}
                            readOnly={!isEditable}
                            type="text"
                            name="translations.startSurvey"
                            required={true}
                            validate={required}
                        />
                    </div>
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.endSurvey}:`}
                            type="text"
                            name="translations.endSurvey"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>

                </div>
                <div className="columns">
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.startCampaign}:`}
                            type="text"
                            name="translations.startCampaign"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.endCampaign}:`}
                            type="text"
                            name="translations.endCampaign"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.EmailAddress}:`}
                            type="text"
                            name="translations.EmailAddress"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.EmailAddressConfirm}:`}
                            type="text"
                            name="translations.EmailAddressConfirm"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.survey}:`}
                            type="text"
                            name="translations.survey"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.questionOfSurvey}:`}
                            type="text"
                            name="translations.questionOfSurvey"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            type="text"
                            labelInComponent={true}
                            label={`${i18n.nextQuestion}:`}
                            name="translations.nextQuestion"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.goToCampaignLinkTitle}:`}
                            type="text"
                            name="translations.goToCampaignLinkTitle"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.unsubscribeEmailLinkTitle}:`}
                            type="text"
                            name="translations.unsubscribeEmailLinkTitle"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.unsubscribeProfileTitle}:`}
                            type="text"
                            name="translations.unsubscribeProfileTitle"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.unsubscribeProfileText}:`}
                            type="text"
                            name="translations.unsubscribeProfileText"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.MultipleChoiceValidationText}:`}
                            type="text"
                            name="translations.MultipleChoiceValidationText"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.TextValidationText}:`}
                            type="text"
                            name="translations.TextValidationText"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.NoNumberValidationText}:`}
                            type="text"
                            name="translations.NoNumberValidationText"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.NumberTooHighValidationText}:`}
                            type="text"
                            name="translations.NumberTooHighValidationText"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.NumberTooLowValidationText}:`}
                            type="text"
                            name="translations.NumberTooLowValidationText"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.InvalidEmailValidationText}:`}
                            type="text"
                            name="translations.InvalidEmailValidationText"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.NoEmailValidationText}:`}
                            type="text"
                            name="translations.NoEmailValidationText"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.InvalidPostalCodeValidationText}:`}
                            type="text"
                            name="translations.InvalidPostalCodeValidationText"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.NoPostalCodeValidationText}:`}
                            type="text"
                            name="translations.NoPostalCodeValidationText"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.invalidDateValidationText}:`}
                            type="text"
                            name="translations.InvalidDateValidationText"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.EmailAddressNotEqualValidation}:`}
                            type="text"
                            name="translations.EmailAddressNotEqualValidation"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.stringTooLongValidation}:`}
                            type="text"
                            name="translations.StringTooLongValidation"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            label={`${i18n.previousQuestion}:`}
                            type="text"
                            name="translations.PreviousQuestion"
                            required={true}
                            validate={required}
                            readOnly={!isEditable}
                        />
                        </div>
                </div>
            </>
        )
    }


    render() {
        const { onSubmitLanguage, handleSubmit, closeLanguageModal, i18n } = this.props;

        return (
            <>
                <form onSubmit={handleSubmit((e: any) => { onSubmitLanguage(e); })}>
                    <Modal
                        content={this.languageContent()}
                    />
                </form>
            </>
        )
    }

}

const mapStateToProps = (state: any, props: OwnProps): StateProps => {
    return ({
        initialValues: {
            language: {
                guid: props.language?.guid,
                locale: props.language?.locale,
                languageText: props.language?.languageText,
                languageTextEN: props.language?.languageTextEN,
                originalLanguage: props.language?.originalLanguage,
                isActive: props.language?.isActive,
                id: props.language?.id
            },
            translations: {
                id: props.language?.languageTexts ? props.language.languageTexts.id : 0,
                startSurvey: props.language?.languageTexts ? props.language?.languageTexts.startSurvey : undefined,
                endSurvey: props.language?.languageTexts ? props.language?.languageTexts.endSurvey : undefined,
                startCampaign: props.language?.languageTexts ? props.language?.languageTexts.startCampaign : undefined,
                endCampaign: props.language?.languageTexts ? props.language?.languageTexts.endCampaign : undefined,
                survey: props.language?.languageTexts ? props.language?.languageTexts.survey : undefined,
                questionOfSurvey: props.language?.languageTexts ? props.language?.languageTexts.questionOfSurvey : undefined,
                nextQuestion: props.language?.languageTexts ? props.language?.languageTexts.nextQuestion : undefined,
                previousQuestion: props.language?.languageText ? props.language?.languageTexts.previousQuestion: undefined,
                goToCampaignLinkTitle: props.language?.languageTexts ? props.language?.languageTexts.goToCampaignLinkTitle : undefined,
                unsubscribeEmailLinkTitle: props.language?.languageTexts ? props.language?.languageTexts.unsubscribeEmailLinkTitle : undefined,
                unsubscribeProfileText: props.language?.languageTexts ? props.language?.languageTexts.unsubscribeProfileText : undefined,
                unsubscribeProfileTitle: props.language?.languageTexts ? props.language?.languageTexts.unsubscribeProfileTitle : undefined,
                MultipleChoiceValidationText: props.language?.languageTexts ? props.language?.languageTexts.multipleChoiceValidationText : undefined,
                TextValidationText: props.language?.languageTexts ? props.language?.languageTexts.textValidationText : undefined,
                NoNumberValidationText: props.language?.languageTexts ? props.language?.languageTexts.noNumberValidationText : undefined,
                NumberTooHighValidationText: props.language?.languageTexts ? props.language?.languageTexts.numberTooHighValidationText : undefined,
                NumberTooLowValidationText: props.language?.languageTexts ? props.language?.languageTexts.numberTooLowValidationText : undefined,
                InvalidEmailValidationText: props.language?.languageTexts ? props.language?.languageTexts.invalidEmailValidationText : undefined,
                NoEmailValidationText: props.language?.languageTexts ? props.language?.languageTexts.noEmailValidationText : undefined,
                InvalidPostalCodeValidationText: props.language?.languageTexts ? props.language?.languageTexts.invalidPostalCodeValidationText : undefined,
                NoPostalCodeValidationText: props.language?.languageTexts ? props.language?.languageTexts.noPostalCodeValidationText : undefined,
                InvalidDateValidationText: props.language?.languageTexts ? props.language?.languageTexts.invalidDateValidationText : undefined,
                StringTooLongValidation: props.language?.languageTexts ? props.language?.languageTexts.stringTooLongValidation : undefined,
                EmailAddress: props.language?.languageTexts ? props.language?.languageTexts.emailAddress : undefined,
                EmailAddressConfirm: props.language?.languageTexts ? props.language?.languageTexts.emailAddressConfirm : undefined,
                EmailAddressNotEqualValidation: props.language?.languageTexts ? props.language?.languageTexts.emailAddressNotEqualValidation : undefined,
                PreviousQuestion: props.language?.languageTexts ? props.language?.languageTexts.previousQuestion : undefined,
            },
        } as any,
        formValues: getFormValues(formName)(state) as any,
        i18n: state.i18n,
        profile: state.profile
    })
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    updateFormValue: (field: string, value: string) => dispatch(change(formName, field, value)),
});

const LanguageReduxForm = reduxForm<{}, Props>({
    form: formName,
    keepDirtyOnReinitialize: true,
    enableReinitialize: false
})(LanguageForm);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LanguageReduxForm));
