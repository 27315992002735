import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import classnames from 'classnames'


interface Props {
    placeholder?: string;
    label?: string;
    hidden?: boolean;
    readOnly?: boolean
    type?: string;
    useOwnValue?: string
    labelInComponent?: boolean
    min?: number;
    max?: number;
    maxLength?: number;
    iconName?: string;
    onClickIcon?: () => void;
    required?: boolean;
    isBorderError?: boolean;
}

export class TextInputComponent extends React.Component<WrappedFieldProps & Props> {
    render() {
        const { input, label, useOwnValue, type = "text", required, placeholder, iconName, onClickIcon, readOnly, hidden, labelInComponent, isBorderError, min, max, maxLength } = this.props;
        let { meta: { touched, error } } = this.props;
        let style = {};


        if (Array.isArray(error)) {
            var isUndefined = true;
            error.forEach(element => {
                if (element)
                    isUndefined = false;

            });
            if (isUndefined)
                error = undefined;
        }

        if (useOwnValue) {
            input.value = useOwnValue;
        }

        var baseComponent = <>
            {label ? <label>{label}</label> : <></>}
            <input
                {...input}
                min={type == "number" ? min : undefined}
                max={type == "number" ? max : undefined}
                type={type}
                maxLength={maxLength ? maxLength : undefined}
                className={classnames("input")}
                style={{ position: 'inherit' }}
                placeholder={placeholder}
                readOnly={readOnly}
            />
            {iconName &&
                <i onClick={onClickIcon}
                    className={classnames("inputIcon", iconName)}
                    id="togglePassword">
                </i>
            }
            {!labelInComponent && !isBorderError && touched && error && <p className="help is-danger">{error}</p>}
        </>

        var inputComponent = labelInComponent ?
            <>
                <div className={"formGroup"}>
                    {baseComponent}
                </div>
                {!isBorderError && touched && error && <p className="help is-danger">{error}</p>}

            </> : baseComponent

        return (
            <>
                { !hidden &&
                    <>
                        <div className="field-body">
                            <div className="field" style={{ border: isBorderError && touched && error ? '1px solid red' : '' }}>
                                {inputComponent}
                            </div>
                        </div>
                    </>
                }
            </>
        );
    }
}