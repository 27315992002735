


import React from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm, change } from 'redux-form';
import { Question, Survey } from '../../types/model';
import ComponentHeader from '../ComponentHeader';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Modal } from '../Modal';
import { DropdownComponent } from '../InputComponents/DropdownComponent';

interface OwnProps {
    surveys: Survey[] | undefined;
    survey: Survey | undefined;
    closeSurveyModal: () => void;
    onSubmitSurveys: (values: any) => void;
}

interface MatchParams {
    id: string
}

interface DispatchProps {
    dispatch: any
    updateFormValue: (field: string, value: string) => any;
}

interface State {
    question: Question | undefined;
}

interface StateProps {
    formValues: any;
    selectedLanguageId: number;
    i18n: any
    initialValues: any
}

type Props = OwnProps & DispatchProps & StateProps & RouteComponentProps<MatchParams>;

const formName = "AddSurveyForm"
class AddSurveyForm extends React.Component<Props & InjectedFormProps<{}, any>, State> {
    constructor(props: Props & InjectedFormProps<{}, any>) {
        super(props);
        this.state = {
            question: undefined,
        }
    }

    questionContent = () => {
        var { i18n, surveys } = this.props;

        var dropdownValues = surveys?.map(s => {
            return { label: s.characteristic + " - " + s.title, value: s.id }
        });

        var headerText = this.state.question ? i18n.editSurvey : i18n.addSurvey
        return (
            <>
                <ComponentHeader title={headerText} onClose={this.props.closeSurveyModal} isForm={true} textButton={i18n.save} />
                <div className="columns">
                    <div className="column">
                        <Field
                            component={DropdownComponent}
                            label={`${i18n.selectSurvey}:`}
                            name="id"
                            required={true}
                            labelInComponent={true}
                            options={dropdownValues}
                        />
                    </div>
                </div>
            </>
        )
    }



    submitSurveys = (e: any) => {
        const { onSubmitSurveys, closeSurveyModal } = this.props;
        onSubmitSurveys(e);
        closeSurveyModal();
    }

    render() {
        const { handleSubmit, i18n } = this.props;
        var content = this.questionContent();

        return (
            <>
                <form onSubmit={handleSubmit((e: any) => { this.submitSurveys(e) })}>
                    <Modal
                        content={content}
                    />
                </form>
            </>
        )
    }
}

const mapStateToProps = (state: any): StateProps => {
    return ({
        initialValues: {
            id: 3
            // languageId: state.selectedLanguageId,
        } as any,
        selectedLanguageId: state.selectedLanguageId,
        i18n: state.i18n,
        formValues: getFormValues(formName)(state) as any
    })
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    dispatch,
    updateFormValue: (field: string, value: string) => dispatch(change(formName, field, value)),
});

const AddSurveyReduxForm = reduxForm<{}, Props>({
    form: formName,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
})(AddSurveyForm);



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddSurveyReduxForm));
