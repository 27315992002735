import React from "react";
import { RouteProps, Route } from "react-router-dom";
import LeftMenuBarContainer from "../containers/LeftMenuBarContainer";
import userManager from "../utils/userManager";

export interface Props {
    navigation?: boolean;
    requiredClaims: string[];
}

const RouteCMSComponent = ({ component, navigation = true, requiredClaims, ...rest }: RouteProps & Props) => {
    var isMfa = localStorage.getItem('mfaEnabled');
    if (isMfa == 'false') {
        if (!window.location.href.includes('/CMS/MFA'))
            window.location.href = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/CMS/MFA`;
            
        userManager.getUser().then((e) => {
            const profile = e?.profile;

            if (!profile)
                throw 'User not found';

            if (!profile.Permission)
                throw 'Access denied';

            var res = requiredClaims.find(x => profile.Permission.find((c: String) => c == x))
            if (!res)
                throw 'Access denied';
        });
    }

    return (
        <div className="columns">
            <LeftMenuBarContainer />
            <div className="column contentContainer">
                <div className="columns is-justify-content-center">
                    <div className="column is-11 mt-6 " >
                        <Route {...rest} component={component} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RouteCMSComponent;