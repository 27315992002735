import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import Slider, { SliderTooltip, createSliderWithTooltip } from "rc-slider";
import classnames from 'classnames'
import { ReactComponent as SwipeIcon } from '../../images/icons/swipegesture.svg'
import Tooltip from 'rc-tooltip';


interface Props {
    placeholder?: string;
    label?: string;
    hidden?: boolean;
    readOnly?: boolean
    type?: string;
    useOwnValue?: string
    labelInComponent?: boolean
    iconName?: string;
    onClickIcon?: () => void;
    required?: boolean;
    isBorderError?: boolean;
    marks: any
    minValue: number
    maxValue: number
    defaultValue: number
    step: number
}

export class SliderComponent extends React.Component<WrappedFieldProps & Props> {
    render() {
        const { input, required, meta: { touched, error }, onClickIcon, hidden, isBorderError, marks, minValue, maxValue, defaultValue, step } = this.props;

        const Handle = Slider.Handle;

        const handle = (props: any) => {
            const { value, dragging, index, ...restProps } = props;
            return (
                <Tooltip
                    prefixCls="rc-slider-tooltip"
                    overlay={value}
                    visible={true}
                    placement="top"
                    key={value}
                    
                >
                    <Handle value={value} {...restProps} />
                </Tooltip>
            );
        };

        return (
            <>
                {!hidden &&
                    <>
                        <div className="sliderDiv">
                            <div className="field-body">
                                <div className="field sliderField" style={{ border: isBorderError && touched && error ? '1px solid red' : '' }}>
                                    <Slider
                                        value={input.value !== "" ? input.value : minValue}
                                        defaultValue={minValue}
                                        onChange={input.onChange}
                                        min={minValue}
                                        max={maxValue}
                                        marks={marks}
                                        step={step}
                                        railStyle={{
                                            height: 2
                                        }}
                                        trackStyle={{
                                            background: "none"
                                        }}
                                        handle={handle}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="swipeIconContainer" style={{ display: "none" }}> <SwipeIcon /></div>
                    </>
                }
            </>
        );
    }
}