import React from 'react'
import { CSSProperties } from 'styled-components';
import { SurveyCampaign, TranslatedLabels } from '../types/model';
import ContentDivider from './ContentDivider';
import ProgressBar from "@ramonak/react-progress-bar";
import { connect } from 'react-redux';
import classNames from 'classnames'

interface OwnProps {
    campaign: SurveyCampaign | null;
    currentQuestionId?: number;
    translatedLabels: TranslatedLabels | null
}

interface StateProps {
    i18n: any;
}

type Props = OwnProps & StateProps

const _maxValue = 100 as number;
const _minValue = 0 as number;

class ProgressBarComponent extends React.Component<Props> {

    render() {
        const { campaign, currentQuestionId } = this.props

        var isEmpty = false;

        var items = campaign?.surveys.map((s: any, index: number) => {
            var indexOfQuestion = s.questionIds.indexOf(currentQuestionId);
            var completed = indexOfQuestion !== -1 ? Math.round((indexOfQuestion / s.questionIds.length) * _maxValue) : isEmpty ? _minValue : _maxValue
            var text = ""

            if (indexOfQuestion !== -1) {
                isEmpty = true;
            }
            if (indexOfQuestion !== -1) {
                text = (indexOfQuestion + 1) + " " + this.props.translatedLabels?.questionOfSurvey + " " + s.questionIds.length
            } else {
                if (!isEmpty) {
                    text = s.questionIds.length + " " + this.props.translatedLabels?.questionOfSurvey + " " + s.questionIds.length
                } else {
                    text = 0 + " " + this.props.translatedLabels?.questionOfSurvey + " " + s.questionIds.length
                }
            }
            return (<div key={index + 1000} className="column progressbarItem" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', flexBasis: 'auto' }}>
                <span key={index + 100} className="has-text-centered surveyNumber">{this.props.translatedLabels?.survey} {index + 1}</span>
                <ProgressBar
                    width="100%"
                    bgColor="#009CDE"
                    borderRadius="0px"
                    labelAlignment={completed === _maxValue ? 'center' : 'right'}
                    transitionDuration="1s"
                    className={classNames("progressBar padding-top-bottom-progressBar", index === 0 ? 'padding-left-progressBar' : '', index === campaign.surveys.length - 1 ? 'padding-right-progressBar' : '', index !== campaign.surveys.length - 1 ? 'border-right-progressBar' : '')}
                    baseBgColor={'#F2F7FF'}
                    completed={completed} />


            </div>)
        })

        return (
            <div className="columns is-gapless progressBarColumns" >
                {items}
            </div>
        )
    }
}


const mapStateToProps = (state: any): StateProps => {
    return ({
        i18n: state.i18n
    })
}

export default connect(mapStateToProps, {})(ProgressBarComponent)
