import React from 'react';
import SurveyApiBase from './SurveyApiBase';
import { Method } from 'axios';
import { Campaign, IEntity, Survey, SurveyQuestionAnswer } from '../../types/model';
import * as Sentry from "@sentry/react";

const endpoint = 'campaignsurveys' as string;

class CampaignSurveyApi extends SurveyApiBase {
    getSurvey(campaignId: string, surveyId: string, profileGuid: string, languageId: number): Promise<SurveyQuestionAnswer[]> {
        return this.doHttpRequest<SurveyQuestionAnswer[]>('get', `${endpoint}/${campaignId}/${surveyId}/${profileGuid}/${languageId}`, null, false)
            .catch(ex => {Sentry.captureException(ex);throw ex;}) as any;
    }
}

export default new CampaignSurveyApi();