


import React from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm, change, registerField, unregisterField } from 'redux-form';
import { DisplayType, IdentityProfile, Policies, Question, QuestionType } from '../../types/model';
import ComponentHeader from '../ComponentHeader';
import required from '../../validators/required';
import draftToHtml from 'draftjs-to-html';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Gridview from '../gridview/GridviewComponent';
import { answersColumns } from '../../constants/tableColumns';
import { Modal } from '../Modal';
import { DropdownComponent } from '../InputComponents/DropdownComponent';
import { TextInputComponent } from '../InputComponents/TextInputComponent';
import { Checkbox } from '../InputComponents/CheckboxInputComponent';
import { enumToDropdown } from '../../helpers/commonHelper';
import TextEditorComponent from '../InputComponents/TextEditorComponent';
import integer from '../../validators/integer';
import minValue from '../../validators/minValue';
import { ConvertHtmlToEditorState } from '../../helpers/textAreaHelper';
import { EditorState, convertToRaw } from "draft-js";
import maxValue from '../../validators/maxValue';
import { collapseTextChangeRangesAcrossMultipleVersions, isThisTypeNode } from 'typescript';


interface OwnProps {
    closeOrderModal?: () => void;
    onSubmitQuestion?: (values: any) => void;
    copyQuestion?: (values: any) => void;
    onSave: (values: any) => void;
    moveQuestion: (row: any, position: any) => void
    row?: any;
}

interface MatchParams {
    id?: string
}

interface DispatchProps {
    dispatch?: any;
    updateFormValue?: (field: string, value: any) => any;
}

interface State {
    editorStateExtraInformation?: EditorState
    forceUpdate?: boolean
}

interface StateProps {
    i18n: any
}


type Props = OwnProps & DispatchProps & StateProps & RouteComponentProps<MatchParams>;

const formName = "QuestionOrderForm"
class QuestionOrderForm extends React.Component<Props & InjectedFormProps<{}, any>, State> {
    
    render() {
        const { handleSubmit, closeOrderModal: closeQuestionModal, i18n } = this.props;
        return (
            <>
                <form onSubmit={handleSubmit((e: any) => { this.props.onSave(e) })}>
                    <Modal
                        content={<>
                            <ComponentHeader onClose={closeQuestionModal} title={i18n.changeOrder} isForm={true} textButton={i18n.save} />
                            <div className="column">
                                <Field
                                    component={TextInputComponent}
                                    name="newIndex"
                                    labelInComponent={true}
                                    type="number"
                                    validate={[integer]}
                                    required={true}
                                    label={`${i18n.number}:`}
                                />
                            </div>
                        </>}
                        size="small"
                    />
                </form>
            </>
        )
    }
}

const mapStateToProps = (state: any, props: OwnProps & RouteComponentProps<MatchParams>): any => {


    return ({
        initialValues: {
            newIndex: props.row.index + 1
        } as any,
        i18n: state.i18n,
        formValues: getFormValues(formName)(state) as any
    })
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    dispatch,
    updateFormValue: (field: string, value: string) => dispatch(change(formName, field, value)),
});

const QuestionOrderReduxForm = reduxForm<{}, Props>({
    form: formName,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
})(QuestionOrderForm);



const conn = connect(mapStateToProps, mapDispatchToProps)(QuestionOrderReduxForm);

export default withRouter(conn);
