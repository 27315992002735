import React from 'react';
import SurveyApiBase from '../Survey/SurveyApiBase';
import { Method } from 'axios';
import { Campaign, IEntity, Survey, SurveyCampaign } from '../../types/model';
import * as Sentry from "@sentry/react";


const endpoint = 'campaigns' as string;

class SurveyCampaignApi extends SurveyApiBase {
    getCampaign(guid: string, languageId : number): Promise<SurveyCampaign> {
        return this.doHttpRequest<SurveyCampaign>('get', `${endpoint}/${guid}/${languageId}`, null, true)
            .catch(ex => {Sentry.captureException(ex);throw ex;}) as any;
    }
}

export default new SurveyCampaignApi();