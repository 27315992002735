import { AsyncAction } from "..";
import ProfileApi from "../../api/CMS/Profile";
import * as profileType from '../../types/actionTypes/profileType';
import { IdentityProfile, Profile, ProfileFilters } from "../../types/model";
import userManager from "../../utils/userManager";


export const getProfiles = async () => {
    return await ProfileApi.getProfiles();
}

export const getUserProfile = (): AsyncAction<any> => {
    return async (dispatch) => {
        const profile = await userManager.getProfile();

        return dispatch({ type: profileType.GET_PROFILE, profile});
    };
}

export const testProfileFilters = async (filters : ProfileFilters) => {
    return await ProfileApi.testProfileFilters(filters);
}