import axios, { AxiosRequestConfig, Method } from "axios";
import { CustomWindow } from "../../types";
import * as Sentry from "@sentry/react";

type Header = Record<string, string>

const customWindow = window as CustomWindow;

class SurveyApiBase {
    public async doHttpRequest<TResponse>(
        method: string,
        endpoint: string,
        data: {} | PromiseLike<{}> | null = null,
        isSingleItem?: boolean   
    ): Promise<TResponse> {
        const headers: Header = {};
        headers["Content-Type"] = "application/json"; 
        headers["X-BvdG-Correlation-ID"] = "8d083735-b31f-40ab-a425-e88c1215a4e5";

        const baseUrl = customWindow.settings.baseUrlSurvey;
        const url = `${baseUrl}/${endpoint}`;

        const config: AxiosRequestConfig = { method : (method as Method), url, headers, data };
        const response = await axios.request<TResponse>(config)
            .catch(ex => {Sentry.captureException(ex);throw ex;}) as any;

        return isSingleItem ? response.data[0] : response.data ;
    }
}

export default SurveyApiBase;
