import React from 'react'
import ContentDivider from './ContentDivider';
import classNames from 'classnames';
import { connect } from 'react-redux';

interface Props {
    title: string;
    textButton?: string;
    htmlText?: any;
    isForm?: boolean;
    disabled?: boolean;
    onClickButton?: () => void;
    onClose?: () => void;
    onCopy?: () => void;
}

interface StateProps {
    i18n: any
}


class ComponentHeader extends React.Component<Props & StateProps> {
    render() {
        const { title, textButton, isForm, onClickButton, htmlText, onClose, onCopy } = this.props

        return (
            <>
                <div className={classNames("columns", isForm ? "questionFormHeader" : "")}>
                    <div className="column formHeader is-7">
                        {title}
                    </div>
                    <div className="column  is-flex is-justify-content-flex-end">
                        {onCopy ? <button style={{marginRight: '10px'}} type="button" onClick={onCopy} className="whiteButton">{this.props.i18n.copy}</button> : <></>}
                        {onClose ? <button style={{marginRight: '10px'}} onClick={onClose} className="whiteButton">{this.props.i18n.close}</button> : <></>}
                        {textButton ? <button type={isForm ? "submit" : "button"} disabled={this.props.disabled} onClick={onClickButton} className="whiteButton">{textButton}</button> : <></>}
                        {htmlText ? <span>{htmlText}</span> : <> </>}
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <ContentDivider width={'100%'} />
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: any): StateProps => {
    return ({
        i18n: state.i18n
    })
}

export default connect(mapStateToProps, {})(ComponentHeader)

