import React from "react";
import { connect } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm, change } from 'redux-form';
import { CmsRole, CmsPolicy, Language, IdentityProfile, Policies } from "../../types/model";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Modal } from '../Modal';
import { DropdownComponent } from '../InputComponents/DropdownComponent';
import ComponentHeader from "../ComponentHeader";
import { TextInputComponent } from "../InputComponents/TextInputComponent";
import { Checkbox } from "../InputComponents/CheckboxInputComponent";
import { i18nReducer } from "../../reducers/i18nReducer";

interface OwnProps {
    cmsRole: CmsRole | undefined;
    defaultPolicies: CmsPolicy[],
    defaultPoliciesLanguage: CmsPolicy[]
    closeCmsRoleModal: () => void;
    onSubmitCmsRole: (values: CmsRole) => void;
}

interface MatchParams {
    id: string
}

interface DispatchProps {
    updateFormValue: (field: string, value: string) => any;
}

interface State {
    cmsRole: CmsRole | undefined
}

interface StateProps {
    formValues: any;
    initialValues: any;
    languages: Language[]
    i18n: any;
    profile: IdentityProfile
}

type Props = OwnProps & DispatchProps & StateProps & RouteComponentProps<MatchParams>;

const formName = "CmsRoleForm"
class CmsRoleForm extends React.Component<Props & InjectedFormProps<{}, any>, State> {
    constructor(props: Props & InjectedFormProps<{}, any>) {
        super(props);
        this.state = {
            cmsRole: props.cmsRole
        }
    }

    translatePermissions = (permission: string) => {
        const { i18n } = this.props;
        switch (permission) {
            case 'questionnaire.view':
                return i18n.questionnaireView;
            case 'questionnaire.edit':
                return i18n.questionnaireEdit;
            case 'questionnaire.delete':
                return i18n.questionnaireDelete;
            case 'campaign.view':
                return i18n.campaignView;
            case 'campaign.edit':
                return i18n.campaignEdit;
            case 'campaign.delete':
                return i18n.campaignDelete;
            case 'settings.view':
                return i18n.settingsView;
            case 'settings.edit':
                return i18n.settingsEdit;
            case 'settings.delete':
                return i18n.settingsDelete;
            case 'profile.view':
                return i18n.profileView;
        }
    }

    cmsLanguage = () => {
        let { languages } = this.props;

        const claims = this.props.profile ? this.props.profile.roles[0].claims : [];
        const isEditable = claims.includes(Policies.SettingsEdit);

        var languagePermission = this.props.cmsRole ? this.props.cmsRole.policies.filter(e => e.type === "Language") : null;


        if (languagePermission !== null && languagePermission.length !== 0) {
            let innerContent = [] as JSX.Element[]
            for (let index = 0; (index < (languagePermission ? languagePermission.length : 1)); index++) {
                innerContent?.push(<Field
                    component={Checkbox}
                    readOnly={!isEditable}
                    name={'cmsRole.languagePolicies[' + index + '].enabled'}
                    label={languages[index].languageText}
                    displayInline={true} />);
            }
            return innerContent

        } else {
            let innerContent = [] as JSX.Element[]
            for (let index = 0; index < languages.length; index++) {
                innerContent?.push(<Field
                    component={Checkbox}
                    name={'cmsRole.languagePolicies[' + index + '].enabled'}
                    readOnly={!isEditable}
                    label={languages[index].languageText}
                    displayInline={true} />);
            }
            return innerContent
        }
    }

    cmsRoleContent = () => {
        const { i18n } = this.props;
        var upperContent = [] as JSX.Element[];

        const claims = this.props.profile ? this.props.profile.roles[0].claims : [];
        const isEditable = claims.includes(Policies.SettingsEdit);

        if (this.props.cmsRole) {
            var content = [] as JSX.Element[];
            var innerContent = [] as JSX.Element[];
            upperContent.push(<div className="columns">{content}</div>);

            var permissions = this.props.cmsRole.policies.filter(p => p.type === "Permission");

            for (let index = 0; index < permissions?.length; index++) {

                if (index % 6 == 0) {
                    content = [] as JSX.Element[];
                    upperContent.push(<div className="columns">{content}</div>);
                    innerContent = [] as JSX.Element[]
                    content.push(<div className="column is-6">{innerContent}</div>)
                    innerContent?.push(<Field
                        component={Checkbox}
                        name={'cmsRole.policies[' + index + '].enabled'}
                        readOnly={!isEditable}
                        label={this.translatePermissions(permissions[index].name)}
                        displayInline={true} />);
                }
                else if (index % 3 == 0) {
                    innerContent = [] as JSX.Element[]
                    content.push(<div className="column is-6">{innerContent}</div>)
                    innerContent?.push(<Field
                        component={Checkbox}
                        name={'cmsRole.policies[' + index + '].enabled'}
                        readOnly={!isEditable}
                        label={this.translatePermissions(permissions[index].name)}
                        displayInline={true} />);

                }
                else {
                    innerContent?.push(<Field
                        component={Checkbox}
                        readOnly={!isEditable}
                        name={'cmsRole.policies[' + index + '].enabled'}
                        label={this.translatePermissions(permissions[index].name)}
                        displayInline={true} />);
                }

            }
        }
        else {
            var content = [] as JSX.Element[];
            var innerContent = [] as JSX.Element[];
            upperContent.push(<div className="columns">{content}</div>);

            for (let index = 0; index < this.props.defaultPolicies?.length; index++) {

                if (index % 6 == 0) {
                    content = [] as JSX.Element[];
                    upperContent.push(<div className="columns">{content}</div>);
                    innerContent = [] as JSX.Element[]
                    content.push(<div className="column is-6">{innerContent}</div>)
                    innerContent?.push(<Field
                        component={Checkbox}
                        name={'cmsRole.policies[' + index + '].enabled'}
                        readOnly={!isEditable}
                        label={this.translatePermissions(this.props.defaultPolicies[index].name)}
                        displayInline={true} />);
                }
                else if (index % 3 == 0) {
                    innerContent = [] as JSX.Element[]
                    content.push(<div className="column is-6">{innerContent}</div>)
                    innerContent?.push(<Field
                        component={Checkbox}
                        name={'cmsRole.policies[' + index + '].enabled'}
                        readOnly={!isEditable}
                        label={this.translatePermissions(this.props.defaultPolicies[index].name)}
                        displayInline={true} />);

                }
                else {
                    innerContent?.push(<Field
                        component={Checkbox}
                        name={'cmsRole.policies[' + index + '].enabled'}
                        readOnly={!isEditable}
                        label={this.translatePermissions(this.props.defaultPolicies[index].name)}
                        displayInline={true} />);
                }
            }
        }

        return (
            <>
                <ComponentHeader title={i18n.roles} onClose={this.props.closeCmsRoleModal} isForm={true} textButton={isEditable ? i18n.save : null} />
                <div className="columns">
                    {this.props.cmsRole &&
                        <div className="column is-8">
                            <Field
                                component={TextInputComponent}
                                labelInComponent={true}
                                name="cmsRole.id"
                                type="text"
                                label={i18n.id + ":"}
                                readOnly={true}
                                disabled={true}
                            />
                        </div>
                    }
                    <div className="column is-4">
                        <Field
                            component={TextInputComponent}
                            label="Naam:"
                            name="cmsRole.name"
                            labelInComponent={true}
                            readOnly={this.props.cmsRole && true}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-6">
                        <div className={"checkboxFormGroup"}>
                            {upperContent}
                        </div>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-6">
                        <div className={"checkboxFormGroup"}>
                            {this.cmsLanguage()}
                        </div>
                    </div>
                </div>



            </>
        )
    }


    render() {

        const { onSubmitCmsRole, handleSubmit, closeCmsRoleModal, i18n } = this.props;

        return (
            <>
                <form onSubmit={handleSubmit((e: any) => { onSubmitCmsRole(e.cmsRole); })}>
                    <Modal
                        content={this.cmsRoleContent()}
                    />
                </form>
            </>
        )
    }

}

const mapStateToProps = (state: any, props: OwnProps): StateProps => {
    var languagePolicies = props.defaultPoliciesLanguage;
    if (props.cmsRole) {
        if (props.cmsRole!.policies?.filter(e => e.type === "Language").length !== 0) {
            languagePolicies = props.cmsRole.policies.filter(e => e.type === "Language")
        }
    }
    return ({
        initialValues: {
            cmsRole: {
                id: props.cmsRole?.id,
                name: props.cmsRole?.name,
                policies: props.cmsRole ? props.cmsRole.policies.filter(e => e.type === "Permission") : props.defaultPolicies,
                languagePolicies: languagePolicies
            }
        } as any,
        formValues: getFormValues(formName)(state) as any,
        languages: state.languages,
        profile: state.profile,
        i18n: state.i18n
    })
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    updateFormValue: (field: string, value: string) => dispatch(change(formName, field, value)),
});

const CmsRoleReduxForm = reduxForm<{}, Props>({
    form: formName,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
})(CmsRoleForm);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CmsRoleReduxForm));
