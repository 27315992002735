export interface DropdownOption {
    label: string;
    value: string;
    icon?: string;
}

export interface Survey extends IEntity, IStatusType, IArchivable {
    code: string;
    title: string;
    characteristic: string;
    numberOfQuestions: number;
    surveyTexts: SurveyText[];
    questions?: Question[];
    isManualAdded?: boolean;
    rank: number;
}

export interface Campaign extends IEntity, IStatusType, IArchivable {
    code: string;
    title: string;
    characteristic: string;
    sendAt?: Date,
    numberOfSurveys: number;
    campaignTexts: CampaignText[];
    campaignImages: CampaignImage[];
    profileFilter: any;
    surveys?: Survey[];
    languageTexts?: LanguageText[];
    sendStatus: SendStatus;
    isProfileCampaign: boolean;
}

export enum SendStatus{
    NotSend = 0,
    PartiallySent = 1,
    Sent = 2
}

export interface CampaignImage extends IEntity, IArchivable {
    base64String: string
}

export interface CampaignText extends IEntity {
    emailDescriptionText: string;
    title: string;
    languageId: number;
    mailchimpTemplate: string;
}

export interface Profile extends IEntity, IStatusType, IArchivable {
    emailAddress: string;
    postalCode: string;
    birthDate: Date;
    country: string;
    gender: string;
    education: string;
}

export interface IEntity {
    id?: number;
    guid?: string;
    createdOn?: Date | string;
    updatedOn?: Date;
}

export interface IStatusType {
    status: StatusType;
}

export interface IArchivable {
    isArchived: boolean
}

export enum StatusType {
    unknown = 0,
    ready = 1,
    concept = 2,
    isArchived = 3
}

export enum QuestionType {
    unknown = 0,
    open = 1,
    multipleChoice = 2,
    slider = 3
}

export interface SurveyText extends IEntity {
    headerText: string;
    footerText: string;
    title: string;
    languageId: number;
}

export enum DisplayType {
    unknwon = 0,
    text = 1,
    date = 2,
    number = 3,
    postalCode = 4,
    email = 5,
    country = 6
}

export interface Question extends IEntity, IStatusType {
    description: string;
    rank: number;
    placeholder?: string;
    displayType: DisplayType;
    showInDropdown?: boolean;
    addOwnAnswer?: boolean;
    hasMultipleAnswers?: boolean;
    maxValue: number
    minValue: number
    steps: number,
    questionType: QuestionType
    questionTexts?: QuestionText[];
    answers?: Answer[];
}

export interface QuestionText extends IEntity {
    description: string;
    addingInformation: string;
    languageId: number;
    regexValidationFormat?: string
}

export interface Language extends IEntity {
    languageText: string;
    languageTextEN: string;
    originalLanguage: string;
    locale: string;
    isActive: boolean;
    languageTexts: LanguageText
}

export interface LanguageText {
    id: string,
    startCampaign: string
    startSurvey: string
    endSurvey: string
    endCampaign: string
    survey: string
    questionOfSurvey: string
    nextQuestion: string;
    previousQuestion: string;
    goToCampaignLinkTitle: string;
    unsubscribeEmailLinkTitle: string;
    unsubscribeProfileText: string;
    unsubscribeProfileTitle: string;
    multipleChoiceValidationText: string;
    textValidationText: string;
    noNumberValidationText: string;
    numberTooHighValidationText: string;
    numberTooLowValidationText: string;
    invalidEmailValidationText: string;
    noEmailValidationText: string;
    invalidPostalCodeValidationText: string;
    noPostalCodeValidationText: string;
    invalidDateValidationText: string;
    stringTooLongValidation: string;
    emailAddress : string,
    emailAddressConfirm : string,
    emailAddressNotEqualValidation : string,
}

export interface CmsPolicy {
    enabled: boolean;
    name: string;
    type: string;
    id: number;
}

export interface CmsRole {
    id: string;
    name: string;
    policies: CmsPolicy[];
    languagePolicies: CmsPolicy[];
}

export interface Answer extends IEntity {
    isOwnAnswer?: boolean
    description: string;
    rank: number;
    followUpQuestionGuid?: string | null,
    score?: number;
    answerTextViewModels: AnswerText[]
}

export interface AnswerText extends IEntity {
    answerText: string;
    languageId: number;
}

export interface TableColumn {
    Header: string;
    accessor?: string;
    className?: any
    style?: any;
    disableFilters?: boolean
    Cell?: (props?: any) => void,
}

export interface Login {
    userName: string;
    password: string;
    grantType: string;
    scope: string;
    clientId: string;
    clientSecret: string;
    twoFactorCode: string;
    refreshToken: string;
    accessToken: string;
    expires: string;
    tokenType: string;
}

export interface ChangePassword {
    currentPassword: string;
    newPassword: string;
}

export interface EnableMFA {
    code: string;
}

export interface IdentityProfile {
    firstname: string;
    surname: string;
    state: number;
    language: string;
    picture: string;
    roles: Role[];
}

export interface Role {
    name: string;
    claims: string[];
}
export enum ProfileOperator {
    Equals = 0,
    GreaterThan = 1,
    LessThan = 2,
}

export enum ColumnNames {
    PostalCode = 0,
    BirthDate = 1,
    Country = 2,
    Gender = 4,
    Education = 5,
    CountryOfBirth = 6,
    Smoking = 7,
    Income = 8,
    MaritalStatus = 9,
    DiabetesDuration = 10,
    CampaignParticipation = 11
}

export interface ProfileFilters {
    filters: ProfileFilter[];
}

export interface ProfileFilter {
    columnName: ColumnNames
    operator: ProfileOperator
    compareValue: string;
}


// ---------- SURVEY -------------------

export interface SurveyCampaign {
    id: number
    guid: string;
    description: string;
    locale: string;
    surveys: SurveySurvey[],
    base64StringPhotos: string[],
    isProfileCampaign: boolean,
}

export interface SurveySurvey {
    id?: number;
    guid: string;
    code: string;
    headerText: string;
    footerText: string;
    title: string;
    questionIds: number[];
    rank: number;
}

export interface SurveyQuestionAnswer {
    guid: string;
    questionText: string;
    questionAddingInformation: string;
    question: string;
    answerText: string;
    finished: boolean;
    addOwnAnswer?: boolean;
    showInDropdown?: boolean;
    hasMultipleAnswers?: boolean;
    displayType: DisplayType;
    questionType: QuestionType;
    questionId: number;
    rank: number;
    steps?: number;
    minValue?: number;
    maxValue?: number;
    followupQuestion: string; //guid
    hasNextQuestion: boolean;
    regexValidationFormat: string;
    isOwnValueAnswer: boolean,
    questionGuid: string
}

export interface ProfileAnswer {
    questionAnswerIds: string[];
    profileId: string;
    campaignId: string;
    customAnswer: string;
    languageId?: number;
}

export interface EmptyAnswer {
    questionId: number;
    profileId: string;
    campaignId: string;
    surveyId: string;
}

export enum InformationType {
    startCampaign = 1,
    startSurvey = 2,
    endSurvey = 3,
    endCampaign = 4,
    profileLoading = 5
}

export interface Role {
    id: string;
    name: string;
    claims: string[];
    languageClaims: string[];
}

export interface User {
    id: string;
    email: string;
    userName: string;
    password: string;
    firstName: string;
    surName: string;
    isActive: boolean;
    roleId: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface CmsUser {
    id: string;
    email: string;
    userName: string;
    password: string;
    firstName: string;
    surName: string;
    isActive: boolean;
    role: string;
    roleId: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface TranslatedLabels {
    startCampaign: string;
    startSurvey: string;
    endSurvey: string;
    endCampaign: string;
    survey: string;
    questionOfSurvey: string;
    nextQuestion: string;
    previousQuestion: string;
    unsubscribeProfileTitle: string;
    unsubscribeProfileText: string;
    multipleChoiceValidationText: string;
    textValidationText: string;
    noNumberValidationText: string;
    numberTooHighValidationText: string;
    numberTooLowValidationText: string;
    invalidEmailValidationText: string;
    noEmailValidationText: string;
    invalidPostalCodeValidationText: string;
    noPostalCodeValidationText: string;
    invalidDateValidationText: string;
    stringTooLongValidation: string;
    emailAddress : string
    emailAddressConfirm: string
    emailAddressNotEqualValidation : string
}

export interface Country extends IEntity {
    id : number
    iso : string
    name : string
    iso3: string;
    numCode?: number 
    PhoneCode?: number 
}

export enum Policies {
    QuestionnaireView = "questionnaire.view",
    QuestionnaireEdit = "questionnaire.edit",
    QuestionnaireDelete = "questionnaire.delete",
    CampaignView = "campaign.view",
    CampaignEdit = "campaign.edit",
    CampaignDelete = "campaign.delete",
    SettingsView = "settings.view",
    SettingsEdit = "settings.edit",
    SettingsDelete = "settings.delete",
    ProfileView = "profile.view",
}