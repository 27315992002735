import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import userManager from '../utils/userManager';
import LoadingComponent from './LoadingComponent';

const CallbackComponent: React.FC<RouteComponentProps> = ({ history }: RouteComponentProps) => {

    useEffect(() => {
        userManager.manager.signinRedirectCallback()
            .then(user => {
                history.push('/cms')
            })
            .catch(error => console.error(error));
    }, [history]);

    return <LoadingComponent />;
};

export default CallbackComponent;