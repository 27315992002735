import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { store } from "./store/store";
import { OidcProvider } from 'redux-oidc';
import userManager from './utils/userManager';

declare global {
  interface Window {
    store: any;
    editor: any;
    settings: any;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

ReactDOM.render(
  <Provider store={store}>
    <OidcProvider store={store} userManager={userManager.manager}>
      <App />
    </OidcProvider>
  </Provider>
  , document.getElementById('root')
);

reportWebVitals();


