import nl from '../i18n/nl';
import en from '../i18n/en';
import * as languageTypes from '../types/actionTypes/languageType';

const strings = {
    0: nl,
    1: en
} as any;

const initalI18nState = strings[0] as any;
type I18nState = typeof initalI18nState

export const i18nReducer = (state: I18nState = initalI18nState, action: languageTypes.SetLocalLanguageId) => {
    switch (action.type) {
        case languageTypes.SET_LOCAL_LANGUAGE_ID:
            const languageId = action.languageId;
            return strings[languageId];
        default:
            return state;
    }
};

