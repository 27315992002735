import "rc-slider/assets/index.css";

import { Country, DisplayType, DropdownOption, InformationType, Language, QuestionType, SurveyCampaign, SurveyQuestionAnswer, TranslatedLabels } from '../types/model';
import { Field, change, formValues, getFormValues } from 'redux-form';
import React from 'react'

import { CSSProperties } from 'styled-components';
import { Checkbox } from './InputComponents/CheckboxInputComponent';
import { DropdownComponent } from './InputComponents/DropdownComponent';
import LanguageSwitcher from './InputComponents/LanguageSwitcher';
import ProfileSurveyForm from '../components/forms/ProfileSurveyForm';
import ProgressBarComponent from '../components/ProgressBarComponent';
import { RadioButtonComponent } from './InputComponents/RadioButtonComponent';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { SliderComponent } from './InputComponents/SliderComponent';
import { Styles } from 'react-select';
import { TextInputComponent } from './InputComponents/TextInputComponent';
import { connect } from 'react-redux';
import moment from 'moment';
import postalCode from '../validators/postalCode';
import required from '../validators/required';
import * as DOMPurify from 'dompurify';


require("moment/min/locales.min");


interface Props {
    surveyQuestionAnswers: SurveyQuestionAnswer[] | null,
    campaign: SurveyCampaign | null,
    surveyIndex: number;
    increaseSurveyIndex: () => void;
    sendAnswer: (values: any, surveyQuestionAnswers: SurveyQuestionAnswer[], isEmpty: boolean) => Promise<boolean>;
    onPreviousQuestion: (surveyQuestionAnswers: SurveyQuestionAnswer[], isEmpty: boolean) => Promise<boolean>;
    showStartCampaignMessage: boolean
    showStartSurveyMessage: boolean
    showEndSurveyMessage: boolean
    hasError: boolean
    toggleMessages: (type: number) => void;
    translatedLabels: TranslatedLabels | null;
    emptyAllowed: boolean;
    answerSending: boolean;
    profileLoading: boolean;
}

interface State {
    countryValue: number | undefined,
    showOwnAnswerField: boolean
}

interface StateProps {
    i18n: any,
    formValues: any,
    countries: Country[]
}

interface DispatchProps {
    dispatch: any;
    updateFormValue: (field: string, value: any) => any;
}

const options: HTMLReactParserOptions = {
    replace(domNode: any) {
        if (domNode && domNode.type === "tag" && (domNode.attribs.class === "campaignLink" || domNode.attribs.class === "unsubscribeFooter"))
            return null;
    },
}

class ProfileSurveyComponent extends React.Component<Props & StateProps & DispatchProps, State> {
    state = {
        countryValue: undefined,
        showOwnAnswerField: false
    }

    changeCountryValue = (e: any) => {
        this.setState({ countryValue: parseInt(e.value) })
    }

    hideMessage = (informationType: InformationType) => {
        this.props.toggleMessages(informationType)
    }

    showButton = (informationType: InformationType) => {
        if (informationType === InformationType.startCampaign)
            return this.props.translatedLabels?.startCampaign;
        else if (informationType === InformationType.startSurvey)
            return this.props.translatedLabels?.startSurvey;
        else if (informationType === InformationType.endSurvey)
            return this.props.translatedLabels?.endSurvey;
    }

    renderImages = () => {
        const { campaign } = this.props;

        var images = campaign?.base64StringPhotos?.map((e: string, index: number) => {
            if (index === 0)
                return (<div key={index}></div>);
            return (<div className="column" key={index}>
                <img style={{ width: '220px', marginLeft: '2%' }} src={e} />
            </div>)
        })
        return images;

    }

    renderImagesBottom = () => {
        const { campaign } = this.props;

        var images = campaign?.base64StringPhotos?.map((e: string, index: number) => {
            if (index !== 0)
                return (<div key={index} style={{ width: '70%', marginLeft: '2%', marginRight: '2%' }} className="column">
                    <img src={e} />
                </div>)
        })

        return images;

    }

    renderInformation = (informationType: InformationType) => {
        const { campaign, i18n } = this.props;
        var content = ""

        if (informationType === InformationType.startCampaign) {
            content = campaign ? campaign?.description : ""
        } else if (informationType === InformationType.startSurvey) {
            content = campaign ? campaign?.surveys[this.props.surveyIndex].headerText : ""
        } else if (informationType === InformationType.endSurvey) {
            content = campaign ? campaign?.surveys[this.props.surveyIndex].footerText : ""
        } else if (informationType === InformationType.endCampaign) {
            content = `<h1></h1><h1><span style="font-size: 24px;">${this.props.translatedLabels?.endCampaign} </span></h1>`
        } else if (informationType === InformationType.profileLoading) {
            content = i18n.ProfileLoadingMessage
        }

        return (
            <div className="column surveyContainer">
                <div className="columns is-narrow">
                    <div className="column is-narrow surveyImagesLeft" style={{ width: '20%', padding: '0px' }}>
                        <div className="columns surveyImageWrapper" style={{ flexDirection: 'column' }}>
                            {this.renderImages()}
                        </div>
                    </div>
                    <div className="column questionContainer informationContainer">
                        {parse(content, options)}
                        {informationType !== InformationType.endCampaign && informationType !== InformationType.profileLoading && <button onClick={() => this.hideMessage(informationType)} style={{ marginTop: '20px' }} className="blueButton">
                            {this.showButton(informationType)}
                        </button>}
                    </div>
                    <div className="column is-narrow bottomSurveyImages" style={{ padding: '0px', display: 'none' }}>
                        <div className="columns surveyImageWrapper" style={{ flexDirection: 'column', alignItems: 'center', display: 'flex' }}>
                            {this.renderImagesBottom()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    groupBy(list: SurveyQuestionAnswer[], keyGetter: any): Map<number, SurveyQuestionAnswer[]> {
        const map = new Map();
        list.forEach((item: any) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    zeroPad = (value: string, places: number) => value.padStart(places, '0')


    renderRadioButtonField = ({ input, label, type, checked }: any) => (
        <div>
            <input {...input} type={type} checked={checked} />
            <label style={{ marginLeft: '10px' }}>{label}</label>
        </div>
    );

    validatePostalCode = (value: any, regexFormat: any) => {
        var requireError = required(value);
        if (requireError)
            return requireError;
        var postalCodeError = postalCode(value, regexFormat);
        if (postalCodeError)
            return postalCodeError;
    }

    renderAnswers = (questionAnswers: SurveyQuestionAnswer[]) => {
        var baseQuestion = questionAnswers[0];
        var reduxName = "value";
        var formValues = this.props.formValues
        moment.locale(this.props.campaign?.locale)


        var date;
        if (formValues && formValues.year && formValues.year.length >= 4 && formValues.month && formValues.day) {
            date = moment(`${formValues.year}-${formValues.month}-${formValues.day}`, 'YYYY-M-D');
        }

        var content = <div></div>;
        if (baseQuestion.questionType === QuestionType.open) {

            if (baseQuestion.displayType === DisplayType.postalCode) {

                var placeholder = "";
                if (baseQuestion.regexValidationFormat) {
                    var charArray = baseQuestion.regexValidationFormat?.split('')?.map(e => {
                        if (e === '-')
                            return "-"
                        if (e === 'A')
                            return "N"
                        if (e === 'N')
                            return "8"
                    })


                    placeholder = charArray.join('')
                }

                content = <div className="column is-3">
                    <Field
                        component={TextInputComponent}
                        name={reduxName}
                        placeholder={placeholder}
                        type="text"
                    />
                </div>
            }
            if (baseQuestion.displayType === DisplayType.email) {
                content = <>
                    <div>
                        {this.props.translatedLabels?.emailAddress}
                    </div>
                    <Field
                        component={TextInputComponent}
                        name={reduxName}
                        type="text"
                    />
                    <div>
                        {this.props.translatedLabels?.emailAddressConfirm}
                    </div>
                    <Field
                        component={TextInputComponent}
                        name={"valueConfirmEmail"}
                        type="text"
                    /></>
            }
            if (baseQuestion.displayType === DisplayType.country) {

                const customStyles = {
                    option: (cssProps: CSSProperties) => ({
                        ...cssProps,
                        display: 'flex',
                        alignItems: 'center'
                    }),

                    container: (cssProps: CSSProperties) => ({
                        ...cssProps,
                        width: 350,
                        marginRight: '40px',
                        height: 50
                    }),

                    indicatorSeparator: (cssProps: CSSProperties) => ({
                        ...cssProps,
                        width: '0px'
                    }),
                    control: (cssProps: CSSProperties) => ({
                        ...cssProps,
                        height: '100%'
                    })
                } as Partial<Styles<any, any, any>>;

                var countries = this.props.countries && this.props.countries.map((l: Country) => {
                    return ({ value: l.id?.toString(), label: l.name, icon: "" })
                }).sort((s: any) => s.label) as DropdownOption[]

                content = <LanguageSwitcher className="countrySelect" customOnChange={(e: any) => this.changeCountryValue(e)} hideImage={true} customStyles={customStyles} options={countries} />
            }
            if (baseQuestion.displayType === DisplayType.text) {
                content = <Field
                    component={TextInputComponent}
                    name={reduxName}
                    type="text"
                />
            }
            if (baseQuestion.displayType === DisplayType.date) {
                content = <><div className="columns " style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="column is-2">
                        <Field
                            component={TextInputComponent}
                            type="number"
                            isBorderError={true}
                            name={'day'}
                            placeholder="dd"
                        />
                    </div>
                    <div className="column is-2">
                        <Field
                            component={TextInputComponent}
                            type="number"
                            name={'month'}
                            placeholder="mm"
                        />
                    </div>
                    <div className="column is-2">
                        <Field
                            component={TextInputComponent}
                            type="number"
                            name={'year'}
                            placeholder="yyyy"
                        />
                    </div>

                </div>

                    <div className="columns " style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="column">
                            {date && date?.isValid() &&
                                date.format('LL').toString()
                            }
                        </div>
                    </div>
                </>

            }
            if (baseQuestion.displayType === DisplayType.number) {
                content =
                    <div className="column is-3">
                        <Field
                            component={TextInputComponent}
                            type="number"
                            name={reduxName}
                        />
                    </div>
            }
        }
        if (baseQuestion.questionType === QuestionType.multipleChoice) {
            if (baseQuestion.showInDropdown) {
                var options = questionAnswers.map((e: SurveyQuestionAnswer) => {
                    return { label: e.answerText, value: e.guid };
                })
                content = <div><Field
                    component={DropdownComponent}
                    name={reduxName}
                    isBorderError={true}
                    isMulti={baseQuestion.hasMultipleAnswers}
                    options={options}
                    onValueChanged={(selected: string) => {
                        if (selected == questionAnswers.find(x => x.isOwnValueAnswer)?.guid) {
                            this.setState({ showOwnAnswerField: true });
                        }
                        else {
                            this.setState({ showOwnAnswerField: false });
                        }
                    }}
                />
                    {this.state.showOwnAnswerField &&
                        <Field
                            component={TextInputComponent}
                            type="text"
                            required={true}
                            validate={required}
                            isBorderError={true}
                            name={'ownTextValue'}
                            onFocus={(x: any) => { this.props.updateFormValue(reduxName, questionAnswers.find(x => x.isOwnValueAnswer)?.guid) }}
                        />
                    }
                </div>
            } else {
                var checkBoxes = questionAnswers.map((e, i) => {
                    if (e.isOwnValueAnswer) {
                        return (
                            <div style={{ display: 'inline-flex', width: '100%' }}>
                                <div style={{ display: 'flex' }}>
                                    {baseQuestion.hasMultipleAnswers ?
                                        <Field
                                            component={Checkbox}
                                            key={100}
                                            name={'multipleAnswers.' + e.guid}
                                            showLabelInCheckbox={true}
                                            displayInline={true}
                                        />
                                        :
                                        <Field
                                            name={reduxName}
                                            key={i + 200}
                                            component={RadioButtonComponent}
                                            type="radio"
                                            isBorderError={true}
                                            value={e.guid}
                                        />
                                    }
                                </div>

                                <div style={{ width: '80%', paddingLeft: baseQuestion.hasMultipleAnswers ? '10px' : '0px' }}>
                                    <Field
                                        component={TextInputComponent}
                                        type="text"
                                        placeholder={e.answerText}
                                        isBorderError={true}

                                        onFocus={(x: any) => { this.props.updateFormValue(reduxName, e.guid) }}
                                        name={'ownTextValue'}
                                    />
                                </div>
                            </div>
                        )
                    }

                    return (e.hasMultipleAnswers ?
                        <Field
                            component={Checkbox}
                            key={i}
                            name={'multipleAnswers.' + e.guid}
                            label={e.answerText}
                            displayInline={true}
                        /> :
                        <Field
                            name={reduxName}
                            key={i + 200}
                            component={RadioButtonComponent}
                            type="radio"
                            value={e.guid}
                            label={e.answerText}
                        />
                    )
                });

                var content = <div>{checkBoxes} </div>
            }
        }

        if (baseQuestion.questionType === QuestionType.slider) {
            var marks: any = {};
            const minValue = baseQuestion.minValue as number;
            const maxValue = baseQuestion.maxValue as number;
            const steps = baseQuestion.steps as number;

            const totalMarks = Math.min(Math.ceil((maxValue - minValue) / steps) + 1, 21);
            const stepSize = Math.floor((maxValue - minValue) / (totalMarks - 1));

            for (let i = 0; i < totalMarks; i++) {
                const index = minValue + i * stepSize;
                marks[index] = index.toString();
            }

            content = <Field
                name={reduxName}
                marks={marks}
                minValue={baseQuestion.minValue}
                maxValue={baseQuestion.maxValue}
                defaultValue={baseQuestion.minValue}
                step={baseQuestion.steps}
                component={SliderComponent}
            />
        }
        return content;
    }

    render() {
        const { surveyQuestionAnswers, campaign, showStartCampaignMessage, showStartSurveyMessage, showEndSurveyMessage, answerSending, profileLoading } = this.props;

        if (profileLoading)
            return (this.renderInformation(InformationType.profileLoading))
        if (campaign && !campaign?.surveys[this.props.surveyIndex])
            return (this.renderInformation(InformationType.endCampaign))
        if (showStartCampaignMessage)
            return (this.renderInformation(InformationType.startCampaign))
        if (showEndSurveyMessage)
            return (this.renderInformation(InformationType.endSurvey))
        if (showStartSurveyMessage)
            return (this.renderInformation(InformationType.startSurvey))

        var questionAnswers;
        var startValue = true;
        var indexOfQuestion = 0;
        var listOfIndex = [];


        if (surveyQuestionAnswers) {
            const groupedByQuestions = this.groupBy(surveyQuestionAnswers, (questionAnswer: any) => questionAnswer.questionId);

            for (let [key, value] of groupedByQuestions) {
                listOfIndex.push(key);
                if (value.find(e => e.finished)) {
                    indexOfQuestion = key;
                    questionAnswers = value;
                } else {
                    if (startValue && !questionAnswers) {
                        questionAnswers = value;
                        indexOfQuestion = key;
                        startValue = false;
                        break;
                    }
                }
            }

            var isSet = false;


            if (questionAnswers?.find(e => e.finished)) {
                var question = questionAnswers.find(e => e.hasNextQuestion);
                if (question) {
                    for (let [key, value] of groupedByQuestions) {
                        if (question?.followupQuestion === '246f6869-b4af-4591-ba8e-aee503c51ff1') {
                            this.props.increaseSurveyIndex();
                            break;
                        }
                        if (value.find(e => e.questionGuid?.toString() === question?.followupQuestion?.toString())) {
                            questionAnswers = value;
                            isSet = true;
                        }
                        else {
                            if (!isSet) {
                                var indexOfIndexes = listOfIndex.indexOf(indexOfQuestion) as number;
                                indexOfIndexes++;
                                var nextIndex = listOfIndex[indexOfIndexes];
                                questionAnswers = groupedByQuestions.get(nextIndex);
                            }

                        }
                    }
                } else {
                    var indexOfIndexes = listOfIndex.indexOf(indexOfQuestion) as number;
                    indexOfIndexes++;
                    var nextIndex = listOfIndex[indexOfIndexes];
                    questionAnswers = groupedByQuestions.get(nextIndex);
                }
            }
        }

        if (questionAnswers?.find(e => e.finished)) {
            return (this.renderInformation(InformationType.endCampaign))
        }
        var baseQuestion = questionAnswers ? questionAnswers[0] : null;

        return (
            <>
                <div className="column is-narrow surveyImagesLeft" style={{ width: '20%', padding: '0px' }}>
                    <div className="columns surveyImageWrapper" style={{ flexDirection: 'column' }}>
                        {this.renderImages()}
                    </div>
                </div>
                <div className="column surveyContainer">
                    <div className="columns is-justify-content-center progressBarContainerContainer">
                        <div className="column progressBarContainer ">
                            <ProgressBarComponent translatedLabels={this.props.translatedLabels} currentQuestionId={baseQuestion?.questionId} campaign={campaign} />
                        </div>
                    </div>
                    <div className="columns is-justify-content-center mt-4">
                        <div className="column questionContainer">
                            {baseQuestion && questionAnswers &&
                                <>
                                    <div className="columns">
                                        <div className="column questionHeader">
                                            {baseQuestion.questionText}
                                        </div>
                                    </div>
                                    {baseQuestion.questionAddingInformation &&
                                        <div className="columns">
                                            <div className="column addingInformation" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(baseQuestion.questionAddingInformation) }} />
                                        </div>}
                                    <div className="columns">
                                        <ProfileSurveyForm
                                            translatedLabels={this.props.translatedLabels}
                                            questionAnswers={questionAnswers}
                                            content={this.renderAnswers(questionAnswers)}
                                            padAnswer={baseQuestion ? baseQuestion.questionType !== QuestionType.slider : false}
                                            onSubmitAnswer={this.props.sendAnswer}
                                            countyValue={this.state.countryValue}
                                            emptyAllowed={this.props.emptyAllowed}
                                            questionId={questionAnswers[0].questionId}
                                            answerSending={answerSending}
                                            onPreviousQuestion={this.props.onPreviousQuestion}
                                            showPrevious={this.props.surveyQuestionAnswers ? this.props.surveyIndex > 0 || this.props.surveyQuestionAnswers.indexOf(questionAnswers[0]) >= 1 : false}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                        <div className="column is-narrow bottomSurveyImages" style={{ padding: '0px', display: 'none' }}>
                            <div className="columns surveyImageWrapper" style={{ flexDirection: 'column', alignItems: 'center', display: 'flex' }}>
                                {this.renderImagesBottom()}
                            </div>
                        </div>
                    </div>
                </div >
            </>
        )
    }
}

const mapStateToProps = (state: any): StateProps => {
    return ({
        i18n: state.i18n,
        countries: state.countries,
        formValues: getFormValues("ProfileSurveyForm")(state) as any,
    })
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    dispatch,
    updateFormValue: (field: string, value: string) => dispatch(change("ProfileSurveyForm", field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSurveyComponent)


