import React from "react";
import { connect } from "react-redux";
import GridviewWithFilteringComponent from '../components/GridviewWithFilteringComponent'
import { ThunkDispatch } from "redux-thunk";
import { constants } from "buffer";
import { IdentityProfile, Policies, Profile } from "../types/model";
import { getProfiles } from "../actions/CMS/profileActions";
import { ProfilesColumns } from "../constants/tableColumns";
import NoAccessComponent from "../components/NoAccessComponent";


interface State {
    profiles: Profile[]
}

interface Props {
    i18n: any
    profile: IdentityProfile
}

class ProfilesContainer extends React.Component<any, State> {
    state = {
        profiles: []
    }

    async componentDidMount() {
        var profiles = await getProfiles();

        this.setState({
            profiles
        })
    }

    render() {
        const { profiles } = this.state;
        const { i18n } = this.props;

        const claims = this.props.profile ? this.props.profile.roles[0].claims : [];
        var isValid = claims.some((c: any) => [Policies.ProfileView].includes(c))

        return (
            isValid ?
                <GridviewWithFilteringComponent
                    columns={ProfilesColumns(i18n)}
                    data={profiles}
                    routeName="Profielen"
                    title={i18n.profiles}
                    buttonName=""
                    disableButton={true}
                /> : <NoAccessComponent />
        );
    }
}

const mapStateToProps = (state: any): Props => {
    return ({
        i18n: state.i18n,
        profile : state.profile
    })
}


export default connect(mapStateToProps, null)(ProfilesContainer)

