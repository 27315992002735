import React from "react"
import Gridview from './gridview/GridviewComponent';
import GridviewStatusComponent from "./gridview/GridviewStatusComponent";
import PageHeader from "./PageHeader";
import { IArchivable, IStatusType, StatusType, TableColumn } from '../types/model';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { createGuid } from '../helpers/guidHelper';

interface MatchParams {
    id: string
}

type Filterable = IArchivable & IStatusType

interface Props {
    columns: TableColumn[];
    data: Filterable[];
    buttonName: string;
    title: string;
    routeName: string;
    disableButton?: boolean;
    onRowClick?: (id: string) => void;
}

interface State {
    showActive: boolean;
    filterStatus: StatusType
}

class GridviewWithFilteringComponent extends React.Component<RouteComponentProps<MatchParams> & Props, State> {
    state = {
        showActive: true,
        filterStatus: StatusType.unknown
    }

    onClickStatus = (status: StatusType) => { this.setState({ filterStatus: status }) }

    onClickActive = (isActive: boolean) => { this.setState({ showActive: isActive }) }

    filterData = (): Filterable[] => {
        const { data } = this.props;
        const { showActive, filterStatus } = this.state;
        var filteredData = data?.filter(d => showActive ? !d.isArchived : d.isArchived);

        return filteredData.filter(fd => {
            if (filterStatus === StatusType.unknown)
                return fd;
            return fd.status === filterStatus;
        });
    }

    addItem = () => {
        var guid = createGuid();
        this.props.history.push({
            pathname: `/CMS/${this.props.routeName}/${guid}`
        });
    }

    button = () => {
        return !this.props.disableButton &&
            <button className="blueButton" onClick={() => this.addItem()}>{this.props.buttonName}</button>
    }

    render() {
        const { columns, title, onRowClick } = this.props;
        const { showActive, filterStatus } = this.state;

        var filteredData = this.filterData();

        return (
            <div>
                <PageHeader title={title} button={this.button()} />
                <GridviewStatusComponent onClickActive={this.onClickActive} onClickStatus={this.onClickStatus} isActive={showActive} status={filterStatus} />
                <Gridview columns={columns} data={filteredData} onRowClick={onRowClick} canDragAndDrop={false} onDeleteClick={() => console.log('onDeleteClick():GridViewWithFiltering')} deleteItem={false} />
            </div>

        )
    }
}

export default withRouter(GridviewWithFilteringComponent);