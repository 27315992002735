import SurveyApiBase from './SurveyApiBase';
import * as Sentry from "@sentry/react";

const endpoint = 'profile' as string;

class ProfileApi extends SurveyApiBase {
    unsubscribeProfile(profileId: string): Promise<any> {
        return this.doHttpRequest<any>('put', `${endpoint}/unsubscribe/${profileId}`, false)
            .catch(ex => {Sentry.captureException(ex);throw ex;}) as any;
    }

    updateProfileStatus(profileGuid: string): Promise<any> {
        var profileObject = { profileId: profileGuid };
        return this.doHttpRequest<any>('post', `updateprofilestatus`, profileObject, false)
            .catch(ex => {Sentry.captureException(ex);throw ex;}) as any;
    }
}

export default new ProfileApi();