import React from "react";
import { NavLink } from "react-router-dom";


interface Props {
    width: string;
}

class ContentDivider extends React.Component<Props> {
    render() {
        const { width } = this.props;
        return (
            <div className="contentDivider" style={{width : width}}>
            </div>
        );
    }
}

export default ContentDivider;