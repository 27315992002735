import React from 'react';
import IdentityApiBase from './IdentityApiBase';
import { Method } from 'axios';
import { Role } from '../../types/model';


const endpoint = 'role' as string;

class RoleApi extends IdentityApiBase {
    get(roleId?: string): Promise<Role[]> {
        return this.doHttpRequest<Role[]>('get', roleId ? `${endpoint}/${roleId}` : `${endpoint}`, null, false);
    }
    post(model: Role): Promise<Role> {
        return this.doHttpRequest<Role>('post', `${endpoint}`, model, false);
    }
    put(roleId: string, model: Role): Promise<Role> {
        return this.doHttpRequest<Role>('put', `${endpoint}/${roleId}`, model, false);
    }
    delete(roleId: string): Promise<Role> {
        return this.doHttpRequest<Role>('delete', `${endpoint}/${roleId}`, null, false);
    }
    claims(): Promise<string[]> {
        return this.doHttpRequest<string[]>('get', `${endpoint}/claims`, null, false);
    }
}

export default new RoleApi();