import React from 'react';
import CMSApiBase from './CMSApiBase';
import { Method } from 'axios';
import { IEntity, Survey } from '../../types/model';


const endpoint = 'surveys' as string;

class SurveyApi extends CMSApiBase {
    getSurveys(): Promise<Survey[]> {
        return this.doHttpRequest<Survey[]>('get', `${endpoint}`, null, false);
    }
    getSurvey(id : string): Promise<Survey> {
        return this.doHttpRequest<Survey>(`get`, `${endpoint}/${id}`, null, true);
    }

    copySurvey(id : string): Promise<any> {
        return this.doHttpRequest<any>(`post`, `${endpoint}/${id}/copy`, null, true);
    }
    upsertSurvey(survey : IEntity) : Promise<any> {
        return this.doHttpRequest<Survey>(`post`, `${endpoint}/${survey.guid}`, survey, true);
    }
}

export default new SurveyApi();