import RoleApi from '../../api/Identity/Role';
import { CmsPolicy, CmsRole, Language, LanguageText, Role } from '../../types/model';

export const getRoles = async (languages: Language[]) => {
    var roles = await RoleApi.get();
    var existingClaims = await RoleApi.claims();
    var cmsRolesPermission = roles.map(x => ({
        id: x.id, name: x.name, policies: (existingClaims.map(q => ({ enabled: x.claims.includes(q), name: q, type: 'Permission' } as CmsPolicy)))
            .concat(languages.map(q => ({ enabled: x.languageClaims.includes(q.id ? q.id?.toString() : "83838"), name: q.languageText, type: 'Language', id: q.id } as CmsPolicy)))
    } as CmsRole))

    return cmsRolesPermission;
}

export const createRole = async (newRole: CmsRole) => {
    var role = { name: newRole.name, 
        claims: newRole.policies.filter(q => q.enabled == true).map(q => (q.name as string)),
        languageClaims: newRole.languagePolicies.filter(q => q.enabled == true).map(q => (q.id as unknown as string)),
    } as any;
    await RoleApi.post(role);
}

export const updateRole = async (updateRole: CmsRole) => {
    var role = {
        id: updateRole.id, name: updateRole.name,
        claims: updateRole.policies.filter(q => q.enabled == true).map(q => (q.name as string)),
        languageClaims: updateRole.languagePolicies.filter(q => q.enabled == true).map(q => (q.id as unknown as string))
    } as any;

    await RoleApi.put(updateRole.id, role);
}

export const getPolicies = async () => {
    var existingClaims = await RoleApi.claims();

    var cmsRolePolicies = existingClaims.map(x => ({ name: x, enabled: false } as CmsPolicy))
    return cmsRolePolicies;
}

export const deleteRole = async (roleId: string) => {
    await RoleApi.delete(roleId);
}