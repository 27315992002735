import React from 'react';
import { WrappedFieldProps } from 'redux-form';
interface Props {
    label?: string;
    readOnly?: boolean;
    hidden?: boolean;
    showSideBySide?: boolean;
    showLabelInCheckbox?: boolean;
    displayInline?: boolean;
    onValueChanged?: (value: any) => void;
    onClick?: (value: any) => void;
    onClickCheckbox?: (value: any) => void;
    customWrapperClass?: string;
}

export type CheckboxProps = WrappedFieldProps & Props;

interface CheckboxState {
    isChecked: boolean;
}

export class Checkbox extends React.Component<CheckboxProps, CheckboxState> {
    constructor(props: CheckboxProps) {
        super(props);

        this.state = { isChecked: props.input.value };
    }

    componentWillReceiveProps(nextProps: Readonly<CheckboxProps>) {
        this.setState({ isChecked: nextProps.input.value })
    }

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onValueChanged } = this.props;

        const newValue = !this.state.isChecked;

        if (this.props.onClick) {
            this.props.onClick(newValue);
        }

        onValueChanged && onValueChanged(newValue);
        this.setState({ isChecked: newValue });

        event.target.focus()
        event.target.blur();
    }

    render() {
        const { input, label, meta: { touched, error }, showSideBySide, readOnly, displayInline, customWrapperClass, hidden } = this.props;

        let style = { display: displayInline ? 'inline-block' : 'block', paddingLeft: displayInline ? '10px' : '0px' };
        let wrapperClass = "field column"


        const labelComponent = label && <label className="label" htmlFor={input.name} style={style}>{label}</label>;

        return (
            !hidden &&
            <div style={{ marginLeft: displayInline ? '10px' : '0px', display: displayInline ? 'flex' : 'block', alignItems: displayInline ? 'center' : 'normal' }}>
                <label className="checkbox">
                    {label && !displayInline && labelComponent}

                    <input
                        {...input}
                        type="checkbox"
                        disabled={readOnly}
                        hidden={hidden}
                        onClick={() => this.props.onClick}
                        onChange={(event) => this.onChange(event)}
                        checked={this.state.isChecked}
                    />
                    <span className="checkbox__control">
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
                            <path fill='#009CDE' /></svg>
                    </span>
                    {label && displayInline && labelComponent}
                </label>
                {touched && error && <p className="help is-danger">{error}</p>}
            </div>
        );
    }
}