import React from "react";
import { Campaign, CampaignImage, CampaignText, IdentityProfile, Policies, StatusType, Survey } from '../types/model';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";
import { getCampaign, upsertCampaign, exportCampaign, getCampaigns } from '../actions/CMS/campaignActions'
import { connect } from "react-redux";
import CampaignForm from "../components/forms/CampaignForm";
import { getSurveys } from "../actions/CMS/surveyActions";
import NoAccessComponent from "../components/NoAccessComponent";

interface State {
    campaign: Campaign | null,
    campaigns: Campaign[],
    surveys: Survey[],
    exportinProgress: boolean
}

interface MatchParams {
    id: string
}

interface DispatchProps {
    selectedLanguageId: number,
    i18n: any
    profile: IdentityProfile
}

class CampaignContainer extends React.Component<any, State> {
    state = {
        campaign: null,
        campaigns: [] as Campaign[],
        surveys: [] as Survey[],
        exportinProgress: false
    }

    async componentDidMount() {
        var campaign = await getCampaign(this.props.match.params.id);
        if (campaign) {
            campaign.surveys = campaign.surveys?.sort((a, b) => a.rank > b.rank ? 1 : -1);
        }
        var surveys = await getSurveys();
        var campaigns = await getCampaigns();
        this.setState({ campaign, surveys, campaigns })
    }

    removeSurvey = (index: any, row: any) => {
        if (this.state.campaign) {
            var campaign = this.state.campaign as unknown as Campaign;
            var survey = campaign.surveys?.find((e) => row.original.guid === e.guid);

            if (survey) {
                if (survey.isManualAdded) {
                    campaign.surveys = campaign?.surveys?.filter((e: any) => e.guid !== survey?.guid);
                } else {
                    survey.id = -Math.abs(survey.id as unknown as number)
                }
            }

            let surveys = campaign.surveys ? campaign.surveys.filter((e: any) => e.id > 0) : [];
            const updatedSurveys : Survey[] = [];
            for(let i = 0; i < surveys.length;i++){
                let survey = surveys[i];
                survey.rank = i+1;
                updatedSurveys.push(survey)
            }
            campaign.surveys = updatedSurveys;
            this.setState({ campaign })
        }
    }


    upsertCampaign = async (values: any, images: any) => {
        var images = images.map((i: any) => {
            return {
                base64String: i.data_url,
                isArchived: false,
                id: i.id ? i.id : 0
            } as CampaignImage
        })

        const { i18n } = this.props;
        // var campaign = (this.state.campaign as unknown as Campaign);
        // var isNewCampaign = campaign ? false : true;
        let campaign = (this.state.campaign ? this.state.campaign : {} as Campaign) as Campaign
        campaign.campaignTexts = campaign.campaignTexts ? campaign.campaignTexts : [];
        campaign.guid = this.props.match.params.id;
        campaign.characteristic = values.characteristic;
        campaign.status = values.status;
        campaign.isArchived = values.status === StatusType.isArchived;
        campaign.title = values.title;
        campaign.code = values.code;
        campaign.isProfileCampaign = values.isProfileCampaign == "yes";

        campaign.profileFilter = { filters: values.profileFilter };
        campaign.campaignImages = images;
        if(!values.sendAt)
            values.sendAt = new Date();
        campaign.sendAt = values.sendAt;

        var campaignText = campaign?.campaignTexts.find((st: CampaignText) => st.languageId == this.props.selectedLanguageId);

        if (campaignText) {
            campaignText.emailDescriptionText = values.campaignIntroText;
            campaignText.title = values.title;
            campaignText.mailchimpTemplate = values.mailchimpTemplate;
        }
        else {
            campaign.campaignTexts.push({
                id: 0,
                title: values.title,
                emailDescriptionText: values.campaignIntroText,
                languageId: this.props.selectedLanguageId,
                mailchimpTemplate: values.mailchimpTemplate
            })
        }

        await upsertCampaign(campaign).then(() => {
            toast.success(campaign.id === 0 ? i18n.campaignCreatedMessage : i18n.campaignEditedMessage);
            this.props.history.push({ pathname: `/CMS/Campagnes` });
        }).catch((() => {
            toast.error(campaign.id === 0 ? i18n.campaignNotCreatedMessage : i18n.campaignNotEditedMessage);
        }))
    }

    upsertSurveys = (values: any) => {
        const { surveys, campaign } = this.state;

        var newCampaign = (campaign ? campaign : {} as Campaign) as Campaign
        newCampaign.surveys = newCampaign.surveys ? newCampaign.surveys : [];
        var selectedSurvey = surveys.find(e => e.id === values.id) as Survey;
        selectedSurvey.isManualAdded = true;
        selectedSurvey.rank = newCampaign.surveys.length +1;

        newCampaign.surveys.push(selectedSurvey);
        newCampaign.surveys = newCampaign.surveys?.sort((a, b) => a.rank > b.rank ? 1 : -1);
        this.setState({ campaign: newCampaign })
    }
    
    exportCampaign = async () =>  {
        this.setState({ exportinProgress: true });
        var campaign = (this.state.campaign ? this.state.campaign : {} as Campaign) as Campaign;
        await exportCampaign(this.props.match.params.id, `${campaign.title} ${new Date().toDateString()}`, () => {this.setState({ exportinProgress: false })});
    }

    sortSurveys = (row: any, index: any) => {
        let campaign = ((this.state.campaign ? this.state.campaign : {} as Campaign) as Campaign);
        let originalSurvey = this.state.surveys?.find(x=> x.id == row.original.id);
        const originalSurveyList = campaign.surveys?.filter(x=> x.id != row.original.id);;
        let result : Survey[] = [];
        if(originalSurveyList && originalSurvey) {
            
            
            if(index >= originalSurveyList.length)
            {
                // special case where we move it to the end of the list
                result = originalSurveyList;
                result.push(originalSurvey);
            }
            else{

                for(let i = 0; i < originalSurveyList.length;i++){
                    var survey = originalSurveyList[i];

                    if(i != index){
                        result.push(survey);
                    }
                    else {
                        result.push(originalSurvey);
                        result.push(survey);
                    }
                }
            }

            for(let i = 0; i < result.length;i++){
                result[i].rank = i+1;
            }

            var newCampaign = (campaign ? campaign : {} as Campaign) as Campaign
            newCampaign.surveys = result;

            campaign.surveys = result;
            this.setState({ campaign: newCampaign });
        }
    }


    render() {
        const { campaign, surveys, exportinProgress, campaigns } = this.state;
        const claims = this.props.profile ? this.props.profile.roles[0].claims : [];
        var isValid = claims.some((c: any) => [Policies.CampaignView, Policies.CampaignEdit, Policies.CampaignDelete].includes(c))

        return (
            isValid ?
                <CampaignForm
                    campaign={campaign}
                    campaigns={campaigns}
                    surveys={surveys}
                    onSubmitCampaign={this.upsertCampaign}
                    onSubmitSurveys={this.upsertSurveys}
                    removeSurvey={this.removeSurvey}
                    exportCampaign={this.exportCampaign}
                    exportInProgress = {exportinProgress}
                    sortSurveys={this.sortSurveys}
                /> : <NoAccessComponent />
        )
    }
}

const mapStateToProps = (state: any): DispatchProps => {
    return ({
        selectedLanguageId: state.selectedLanguageId,
        i18n: state.i18n,
        profile: state.profile
    })
}

export default withRouter(connect(mapStateToProps, null)(CampaignContainer))

