import React from 'react'
import ContentDivider from './ContentDivider';
import classNames from 'classnames';
import { connect } from "react-redux";

interface StateProps {
    i18n: any
}

class NoAccessComponent extends React.Component<StateProps> {
    render() {
        const { i18n } = this.props

        return (
            <div className="columns" style={{textAlign: 'center'}}>
                <div className="column">
                    {i18n.noAccess}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: StateProps): StateProps => {
    return ({
        i18n: state.i18n,
    })
}

export default connect(mapStateToProps, {})(NoAccessComponent)
