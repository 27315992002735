import { Action } from "redux";
import { Country, Language } from "../model";

export const GET_LANGUAGES = "GET_LANGUAGES";
export const SET_LANGUAGE_ID = "SET_LANGUAGE_ID";
export const SET_LOCAL_LANGUAGE_ID = "SET_LOCAL_LANGUAGE_ID";

export const GET_COUNTRIES = "GET_COUNTRIES";

export interface GetLanguageAction extends Action<string> {
    languages: Language[];
}

export interface GetCountyAction extends Action<string> {
    countries: Country[];
}

export interface SetSelectedLanguageId extends Action<string> {
    languageId: number;
}

export interface SetLocalLanguageId extends Action<string> {
    languageId: number;
}
