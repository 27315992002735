import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { setLanguageId } from '../../actions/CMS/languageActions';
import Select, { components, OptionProps, Styles, ValueContainerProps } from 'react-select';
import { DropdownOption, Language } from '../../types/model';

interface OwnProps {
    basePathImages?: string
    options: DropdownOption[];
    className?: string;
    hideImage?: boolean;
    customStyles?: Partial<Styles<any, any, any>>;
    customOnChange?: (e: any) => void;
}

interface DispatchProps {
    setLanguageId: (id: number) => void;
}

interface StateProps {
    selectedLanguageId: number;
    languages: Language[];
}

type Props = OwnProps & StateProps & DispatchProps;

const { Option, ValueContainer } = components;
const _iconWidth = 36 as number;


class LanguageSwitcher extends React.Component<Props>{
    IconOption = (options: OptionProps<any, any, any>) => {
        var src = null;
        if (!this.props.hideImage) {
            var src = require('../../images/icons/flags/' + options.data.icon + '.png').default;
        }

        return (
            <Option  {...options}>
                <img
                    src={src}
                    style={{ width: _iconWidth, paddingRight: '10px' }}
                />
                {options.data.label}
            </Option>
        )
    };

    DropdownValue = (options: ValueContainerProps<any, any, any>) => {
        const { getValue } = options;

        var selectedLanguage = this.props.languages && this.props.languages.length > 0 ? this.props.languages.find((e) => e.id === this.props.selectedLanguageId) : null;
        var src = null;
        if (!this.props.hideImage) {
            var src = selectedLanguage ? require('../../images/icons/flags/' + selectedLanguage.locale + '.png').default : null;
        }

        return (
            <ValueContainer {...options}>
                {src && <img
                    src={src}
                    style={{ width: _iconWidth, paddingRight: '10px' }}
                />}
                {selectedLanguage ? selectedLanguage.languageText : ""}
            </ValueContainer>
        );
    };

    onChange = (value: any) => {
        this.props.setLanguageId(parseInt(value.value))
    }

    render() {
        const { className, options, customStyles } = this.props;
        var defaultValue = options.find(e => e.value.toString() === this.props.selectedLanguageId.toString());

        if (options) {
            options.sort((e: any, b: any) => {
                if (e.label < b.label) { return -1; }
                if (e.label > b.label) { return 1; }
                return 0;
            })
        }

        return (
            this.props.hideImage ?
                <Select
                    onChange={(e: any) => this.props.customOnChange ? this.props.customOnChange(e) : () => { }}
                    styles={customStyles}
                    // defaultValue={defaultValue}
                    // value={"1"}
                    options={options}
                // components={{ Option: this.IconOption, ValueContainer: this.DropdownValue }}
                /> :
                <Select
                    onChange={(e: any) => this.onChange(e)}
                    styles={customStyles}
                    defaultValue={defaultValue}
                    options={options}
                    components={{ Option: this.IconOption, ValueContainer: this.DropdownValue }}
                />
        )
    }
}

const mapStateToProps = (state: any): StateProps => {
    return ({
        selectedLanguageId: state.selectedLanguageId,
        languages: state.languages
    })
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    setLanguageId: (id: number) => dispatch(setLanguageId(id))
});


export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);

